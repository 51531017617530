import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import Rules from '../Screens/Rules';
import { strings } from '../locales/i18n';

const Stack = createStackNavigator();

export default function RulesStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.Rules")} component={Rules} />
    </Stack.Navigator>
  );
};
