import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { AntDesign, Feather } from '@expo/vector-icons';
import { TextInput } from 'react-native-web';

import { globalStyles } from '../Styles/globals';
import BtxButton from './BtxButton';
import { Paragraph } from './Texts';
import { alphaColor, grey, lightGrey, primaryColor, red, transparent } from '../Styles/colors';
import { sizeL, sizeS, sizeXL } from '../Styles/text';
import { MarketAPI } from '../API/Market';
import { marketActions } from '../Store/Reducers/marketReducer';
import { strings } from '../locales/i18n';

export function BidForm({ playerId, minBid, previousBid }) {
    const dispatch = useDispatch();

    const seasonId = useSelector(state => state.league.season.seasonId);
    const [confirmationMode, setConfirmationMode] = useState(false);
    const [confirmationWithdrawMode, setConfirmationWithdrawMode] = useState(false);
    const [bid, setBid] = useState(minBid);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [_previousBid, setPreviousBid] = useState(previousBid);

    const isBidValid = bid >= minBid;

    const containerStyle = [
        globalStyles.flexRow,
        globalStyles.alignCenter,
        globalStyles.justifyCenter,
        globalStyles.flexWrap,
        styles.container,
    ];

    const doBid = () => {
        MarketAPI.bid({ seasonId, playerId, cost: bid }).then(data => {
            if (!data.error) {
                setConfirmationMode(false);
                setSuccessMessage(strings('bidForm.validatedBid'));
                dispatch({type: marketActions.NEW_MARKET_ACTION, value: Date.now()});
                setPreviousBid(bid);
            } else {
                setConfirmationMode(false);
                console.error(data.error);
            }
        }).catch(err => {
            setConfirmationMode(false);
            console.error(err);
        })
    };

    const doWithdraw = () => {
        MarketAPI.withdraw({ seasonId, playerId }).then(data => {
            if (!data.error) {
                setConfirmationWithdrawMode(false);
                setSuccessMessage(strings('bidForm.completeWithdraw'));
                dispatch({type: marketActions.NEW_MARKET_ACTION, value: Date.now()});
                setPreviousBid(undefined);
            } else {
                setConfirmationWithdrawMode(false);
                console.error(data.error);
            }
        }).catch(err => {
            setConfirmationWithdrawMode(false);
            console.error(err);
        })
    };

    const onPressCancel = () => {
        setBid(minBid);
        setConfirmationMode(false);
    };

    const withdrawButton = () => {
        if (_previousBid) {
            return (
                <BtxButton
                    title={strings('bidForm.confirmWithdraw', {previousBid: _previousBid})}
                    icon={<Feather name='trash-2' size={sizeS} color={ primaryColor } />}
                    onPress={() => setConfirmationWithdrawMode(true)}
                    backgroundColor={ transparent }
                    color={ primaryColor }
                    fontSize={ sizeS }
                    borderColor={ primaryColor }
                    borderWidth={ 1 }
                    marginLeft={ 8 }
                />
            );
        }

        return (<></>);
    };

    if (successMessage) {
        return (
            <View style={containerStyle}>
                <AntDesign name='checkcircleo' size={sizeXL} color={primaryColor} />
                <Paragraph style={[styles.successText]}>{ successMessage }</Paragraph>
            </View>
        );
    }

    if (confirmationMode) {
        return (
            <View style={containerStyle}>
                <View style={[globalStyles.flexRow]}>
                    <Paragraph style={[styles.text]}>{strings('bidForm.confirmBidQuestion', {amount: bid})}</Paragraph>
                </View>
                <View style={[globalStyles.flexRow, styles.buttonsContainer]}>
                    <BtxButton
                        title={strings('globals.yes')}
                        onPress={doBid}
                        minWidth={80}
                        backgroundColor={primaryColor}
                    />
                    <BtxButton
                        title={strings('globals.cancel')}
                        onPress={onPressCancel}
                        backgroundColor={transparent}
                    />
                </View>
            </View>
        );
    }

    if (confirmationWithdrawMode) {
        return (
            <View style={containerStyle}>
                <View style={[globalStyles.flexRow]}>
                    <Paragraph style={[styles.text]}>{strings('bidForm.confirmWithdrawQuestion')}</Paragraph>
                </View>
                <View style={[globalStyles.flexRow, styles.buttonsContainer]}>
                    <BtxButton
                        title={strings('globals.yes')}
                        onPress={doWithdraw}
                        minWidth={80}
                        backgroundColor={primaryColor}
                    />
                    <BtxButton
                        title={strings('globals.cancel')}
                        onPress={() => setConfirmationWithdrawMode(false)}
                        backgroundColor={transparent}
                    />
                </View>
            </View>
        );
    }

    return (
        <View style={containerStyle}>
            <View style={[globalStyles.flexRow, globalStyles.alignCenter]}>
                <Paragraph style={[styles.text]}>{strings('bidForm.bidSentence')}</Paragraph>
                <TextInput
                    value={bid}
                    onChangeText={(text) => setBid(parseInt(text) || '')}
                    keyboardType='numeric'
                    style={[styles.input, !isBidValid ? styles.inputInvalid : undefined]}
                    text={'M$'}
                />
                <Paragraph style={[styles.text]}>M$</Paragraph>
            </View>
            <View style={[globalStyles.flexRow, styles.buttonsContainer]}>
                <BtxButton
                    title={strings('bidForm.bid')}
                    onPress={() => setConfirmationMode(true)}
                    backgroundColor={isBidValid ? primaryColor : lightGrey}
                    disabled={!isBidValid}
                />
                { withdrawButton() }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
    },
    text: {
        fontWeight: 'bold',
    },
    buttonsContainer: {
        marginLeft: 24,
        paddingVertical: 8,
    },
    successContainer: {
        alignItems: 'center'
    },
    successText: {
        color: primaryColor,
        fontSize: sizeL,
        fontWeight: '500',
        marginHorizontal: 4,
    },
    input: {
        width: 34,
        backgroundColor: lightGrey,
        borderColor: grey,
        borderRadius: 4,
        borderWidth: 1,
        marginLeft: 8,
        marginRight: 4,
        paddingRight: 2,
        paddingLeft: 8,
    },
    inputInvalid: {
        borderColor: red,
        backgroundColor: alphaColor(red, 25),
    },
});
