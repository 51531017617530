import 'react-native-gesture-handler';
import React from 'react';
import { Provider } from 'react-redux';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Store from './Store/configureStore';
import Navigation from './Navigation/Navigation';

export default function App() {
  return (
    <Provider store={Store}>
      <SafeAreaProvider>
          <Navigation/>
      </SafeAreaProvider>
    </Provider>
  );
};
