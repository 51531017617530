import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import { strings } from '../locales/i18n';
import { LeagueFreeAgencyHome } from '../Screens/League/FreeAgencyHome';
import { LeagueFreeAgentsSearch } from '../Screens/League/FreeAgentsSearch';

const Stack = createStackNavigator();

export function LeagueFreeAgencyStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.LeagueFreeAgencyHome")} component={LeagueFreeAgencyHome} />
      <Stack.Screen name={strings("navigation.LeagueFreeAgentsSearch")} component={LeagueFreeAgentsSearch} />
    </Stack.Navigator>
  );
};
