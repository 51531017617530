import React from 'react';
import { StyleSheet, View } from 'react-native';
import { silver } from '../Styles/colors';

export function Columns({ children }) {
    return (
        <View style={[styles.columns]}>
            {children}
        </View>
    );
}

const styles = StyleSheet.create({
    columns: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 4,
        borderBottomWidth: 2,
        borderBottomColor: silver,
        // backgroundColor: 'red', //tmp
    },
});
