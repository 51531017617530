import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Picker } from '@react-native-picker/picker';
import { MaterialIcons  } from '@expo/vector-icons';
import { PlayerCardExtended } from '../Components/PlayerCardExtended';
import { Kpi } from '../Components/Kpi';
import { MainKpi } from '../Components/MainKpi';
import { MainKpiBar } from '../Components/MainKpiBar';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph, Title1, Title3, Title4 } from '../Components/Texts';
import { sizeXXL } from '../Styles/text';
import { globalStyles } from '../Styles/globals';
import { strings } from '../locales/i18n';
import { SeasonAPI } from '../API/Season';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { Gap } from '../Components/Gap';
import { PlayerDetails } from '../Components/PlayerDetails';
import BtxButton from '../Components/BtxButton';
import { black, grey, lightGrey, silver, transparent } from '../Styles/colors';

function PositionsKpi({ positions, positionsCounter, selectedPositionIds, onPress, isLoading }) {
    if (isLoading) {
        return (<BtxActivityIndicator />);
    }

    return (
        positions.map(pos => (
            <Kpi
                key={pos.id}
                title={pos.name}
                value={positionsCounter[pos.id]}
                selected={selectedPositionIds.includes(pos.id)}
                onPress={() => {onPress(pos.id)}}
            />
        ))
    );
}

function Roster({ data, isLoading , canCut }) {
    if (isLoading) {
        return (<BtxActivityIndicator />);
    }

    if (data && data.length === 0) {
        return (<Paragraph>{strings('LeagueOffice.noPlayerToDisplay')}</Paragraph>);
    }


    return (
        data.map(player => {
            return (
                <PlayerCardExtended
                    key={player.playerId}
                    name={player.fullName}
                    subtitle={player.team.fullName}
                    positions={player.positions}
                    meta={`${player.cost} M$`}
                    injury={player.injury}
                    details={
                        <PlayerDetails
                            playerId={player.playerId}

                            displaySeason={true}
                            lastEvaluations={player.stats?.lastEvaluations}
                            injury={player.injury}
                            nGames={player.stats?.n}
                            mean={player.stats?.evaluationMean}

                            displayBid={false}
                            displayCut={canCut}
                        />
                    }
                />
            )}
        )
    );
}

function shouldBeDisplayed(playerPositions, selectedPositions) {
    return selectedPositions.length === 0 || playerPositions.filter(x => selectedPositions.includes(x)).length > 0;
}

export function LeagueOffice({navigation}) {
    const basketixId = useSelector(state => state.authentication.basketixId);
    const league = useSelector(state => state.league);
    const lastMarketActionTime = useSelector(state => state.market.lastMarketActionTime);

    const [selectedBasketixId, setSelectedBasketixId] = useState(basketixId);
    const [selectedPositionIds, setSelectedPositionIds] = useState([]);
    const [roster, setRoster] = useState({});
    const [isRosterLoading, setIsRosterLoading] = useState(false);
    const [capSpace, setCapSpace] = useState(undefined);
    const [isCapSpaceLoading, setIsCapSpaceLoading] = useState(false);

    const basketixs = Object.values(league.members).map(btx => ({
        ...btx,
        ...{ pickerLabel: `${btx.teamName} (${btx.name})`}
    }));
    const selectedBasketix = league.members[selectedBasketixId];
    const seasonId = league.season.seasonId;
    const displayedPlayers = roster.players ? roster.players.filter(p => shouldBeDisplayed(p.positions, selectedPositionIds)) : [];

    const positions = [
        { id: 'PG', name: strings('globals.PG') },
        { id: 'SG', name: strings('globals.SG') },
        { id: 'SF', name: strings('globals.SF') },
        { id: 'PF', name: strings('globals.PF') },
        { id: 'C',  name: strings('globals.C')  },
    ];

    const goToLeagueCourt = () => {
        navigation.navigate(strings('navigation.LeagueCourt'));
    };

    useEffect(() => {
        setIsRosterLoading(true);
        SeasonAPI.getRosters({seasonId, basketixIds: [selectedBasketixId]}).then(data => {
            if (!data.error) {
                setRoster(data[selectedBasketixId]);
                setIsRosterLoading(false);
            } else {
                console.error(data.error);
                setIsRosterLoading(false);
            }
        }).catch(err => {
            console.error(err);
            setIsRosterLoading(false);
        })

        setIsCapSpaceLoading(true);
        const getCapSpacesBody = {
            seasonId,
            basketixIds: [selectedBasketixId],
            withSecrets: selectedBasketixId === basketixId
        };
        SeasonAPI.capSpaces(getCapSpacesBody).then(data => {
            if (! data.error) {
                setCapSpace(data[selectedBasketixId].capSpace);
                setIsCapSpaceLoading(false);
            } else {
                console.log(data.error);
                setIsCapSpaceLoading(false);
            }
        }).catch(error => {
            console.error(error);
            setIsCapSpaceLoading(false);
        })
    }, [seasonId, selectedBasketixId, lastMarketActionTime])

    const canCut = league.season.seasonStatusId === 'REGULARSEASON' && basketixId === selectedBasketixId;
    const canEditCourt = basketixId === selectedBasketixId;

    const displayEditCourtButton = () => {
        if (canEditCourt) {
            return (
                <View key='courtPersonalizationButton'>
                    <Gap />
                    <BtxButton
                        title={strings("globals.courtPersonalization")}
                        onPress={goToLeagueCourt}
                        icon={<MaterialIcons name="colorize" size={16} color={ black } />}
                    />
                </View>
            )
        }
    }

    const togglePosition = (positionId) => {
        let newSelectedPositionsIds = [ ...selectedPositionIds ];
        const isSelected = selectedPositionIds.indexOf(positionId);
        if (isSelected === -1) {
            newSelectedPositionsIds.push(positionId);
        } else {
            newSelectedPositionsIds = newSelectedPositionsIds.filter(pos => pos != positionId);
        }
        setSelectedPositionIds(newSelectedPositionsIds);
    };

    return(
        <ScrollViewMainContainer>
            <View style={styles.headerContainer}>
                <Title4>{strings('LeagueOffice.showOtherTeam')}</Title4>
                <Picker
                    selectedValue={selectedBasketixId}
                    onValueChange={(value, index) => setSelectedBasketixId(value)}
                >
                    {
                        basketixs.map(basketix => (<Picker.Item key={basketix.basketixId} label={basketix.pickerLabel} value={basketix.basketixId} />))
                    }
                </Picker>
            </View>
            <View style={styles.nameContainer}>
                <Title1 style={styles.teamNameText}>{selectedBasketix.teamName}</Title1>
                <Title3>{selectedBasketix.name}</Title3>
            </View>

            <View key='KPI'>
                <MainKpiBar style={[styles.mainKpiBar, globalStyles.shadow]}>
                    <MainKpi title={strings('LeagueOffice.capSpace')} value={isCapSpaceLoading ? '-' : `${capSpace} M$`} />
                    <MainKpi title={strings('LeagueOffice.nPlayers')} value={isRosterLoading ? '-' : roster.nPlayers} isLast={true}/>
                </MainKpiBar>
            </View>

            {displayEditCourtButton()}

            <Gap />

            <View style={styles.title}>
                <Title1>{strings('LeagueOffice.positions')}</Title1>
            </View>
            <View style={styles.positionsContainer}>
                <PositionsKpi
                    positions={positions}
                    positionsCounter={roster.positionsCounter || {}}
                    selectedPositionIds={selectedPositionIds}
                    onPress={togglePosition}
                    isLoading={isRosterLoading}
                />
            </View>
            <Gap />

            <View style={styles.title}>
                <Title1>{strings('LeagueOffice.roster')}</Title1>
            </View>
            <View style={styles.rosterContainer}>
                <Roster data={displayedPlayers} isLoading={isRosterLoading} canCut={canCut} />
            </View>
        </ScrollViewMainContainer>
    )
}

const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        paddingVertical: 4,
        marginBottom: 4,
    },
    nameContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 4,
        marginBottom: 4,
    },
    positionsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        paddingVertical: 4,
        marginBottom: 4,
    },
    rosterContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        maxWidth: 600,
        paddingVertical: 4,
        marginBottom: 4,
    },
    title: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: 4,
    },
    teamNameText: {
        fontSize: sizeXXL,
    },
    mainKpiBar: {
        marginVertical: 10,
    },
});
