import basketixApiCall from './BasketixApi';

const getFreeAgentPlayers = async(data) => {
    const endpoint = '/basketix/season/market/freeagent/players';
    const body = { seasonId: data.seasonId };

    const freeAgents = await basketixApiCall(endpoint, body, 'POST');

    return freeAgents;
};

const getFreeAgency = async(data) => {
    const endpoint = '/basketix/season/market/freeagents';
    const body = { seasonId: data.seasonId };

    const freeAgents = await basketixApiCall(endpoint, body, 'POST');

    return freeAgents;
};

export const FreeAgencyApi = {
    getFreeAgentPlayers,
    getFreeAgency,
}
