import React from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { globalStyles } from '../Styles/globals';
import { strings } from '../locales/i18n';
import { Paragraph, TextError } from './Texts';
import { black, primaryColor, thirdColor } from '../Styles/colors';
import { sizeM } from '../Styles/text';
import BtxButton from '../Components/BtxButton';
import { useSelector } from 'react-redux';
import { RequiredTextInput } from './RequiredTextInput';
import { InputGroup } from './InputGroup';

export function SetPasswordForm({
    onPasswordChange,
    onConfirmedPasswordChange,
    onPasswordValue,
    onConfirmedPassWordValue,
    onBlurPassword,
    onBlurConfirmedPassword,
    btnBackgroundColor,
    btnHeight,
    btnMaxHeight,
    formikprops
    }) {
        const isLogin = useSelector(state => state.authentication.isLogin);
        const styleTextInput = isLogin ? styles.loginTextInput : styles.noLoginTextInput;
        const styleText = isLogin ? styles.loginText : styles.noLoginText;

        return (
            <View>
                <InputGroup key='new'>
                    <Paragraph style={ [globalStyles.textInputLabel, styleText] }>{strings('resetPassword.newPassword')}</Paragraph>
                    <RequiredTextInput
                        style={[globalStyles.textInput, styleTextInput]}
                        onChangeText={onPasswordChange}
                        value={onPasswordValue}
                        onBlur={onBlurPassword}
                        autoCompleteType={'password1'}
                        keyboardType={'password1'}
                        textContentType={'password1'}
                        secureTextEntry={true}
                        hasError={formikprops.touched.password && formikprops.errors.password}
                    />
                    <TextError>{formikprops.touched.password && formikprops.errors.password}</TextError>
                </InputGroup>

                <InputGroup key='confirmation'>
                    <Paragraph style={ [globalStyles.textInputLabel, styleText] }>{strings('resetPassword.newPasswordConfirmed')}</Paragraph>
                    <RequiredTextInput
                        style={[globalStyles.textInput, styleTextInput]}
                        onChangeText={onConfirmedPasswordChange}
                        value={onConfirmedPassWordValue}
                        onBlur={onBlurConfirmedPassword}
                        autoCompleteType={'password2'}
                        keyboardType={'password2'}
                        textContentType={'password2'}
                        secureTextEntry={true}
                        hasError={formikprops.touched.verifyPassword && formikprops.errors.verifyPassword}
                    />
                    <TextError>{formikprops.touched.verifyPassword && formikprops.errors.verifyPassword}</TextError>
                </InputGroup>

                <BtxButton
                    onPress={ formikprops.handleSubmit }
                    title={ strings('resetPassword.resetPasswordButton')}
                    backgroundColor={ btnBackgroundColor ?? primaryColor }
                    color={ black }
                    height={btnHeight ?? 48}
                    maxHeight={btnMaxHeight ?? 58}
                />
            </View>
        );
};

const styles = StyleSheet.create({
    marginForm: {
        marginTop:10,
    },
    noLoginTextInput: {
        borderColor: thirdColor,
        color: thirdColor,
        fontWeight: "bold",
    },
    loginTextInput: {
        color: black,
        fontWeight: "bold",
    },
    loginText: {
        color: black,
        fontSize: sizeM
    },
    noLoginText: {
        color: thirdColor,
        fontSize: sizeM
    },
});
