import React, { useState } from 'react';
import { StyleSheet, View} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { AntDesign } from '@expo/vector-icons';
import { MarketAPI } from '../API/Market';
import { strings } from '../locales/i18n';
import { black, grey, primaryColor, transparent, white } from '../Styles/colors';
import BtxButton from './BtxButton';
import { Paragraph } from './Texts';
import { globalStyles } from '../Styles/globals';
import { sizeL, sizeS, sizeXL } from '../Styles/text';
import { marketActions } from '../Store/Reducers/marketReducer';

export function CutForm({ playerId }) {
    const dispatch = useDispatch();

    const seasonId = useSelector(state => state.league.season.seasonId);
    const [confirmationMode, setConfirmationMode] = useState(false);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const containerStyle = [
        globalStyles.flexRow,
        globalStyles.alignCenter,
        globalStyles.justifyCenter,
        globalStyles.flexWrap,
        globalStyles.flexShrink,
    ];


    const onPressCut = () => {
        const body = {
            seasonId,
            playerId,
        };
        MarketAPI.cutPlayer(body).then(data => {
            if (!data.error) {
                dispatch({type: marketActions.NEW_MARKET_ACTION, value: Date.now()});
                setConfirmationMode(false);
                setSuccessMessage(strings('cutForm.playerCut'));
            } else {
                setConfirmationMode(false);
                console.error(data.error);
            }
        }).catch(err => {
            setConfirmationMode(false);
            console.error(err);
        });
    };

    if (successMessage) {
        return (
            <View style={containerStyle}>
                <AntDesign name='checkcircleo' size={sizeXL} color={primaryColor} />
                <Paragraph style={[styles.successText]}>{ successMessage }</Paragraph>
            </View>
        );
    }


    if (confirmationMode) {
        return (
            <View style={[...containerStyle, globalStyles.flexColumn]}>
                <Paragraph style={styles.boldText}>{strings('cutForm.confirmationCut')}</Paragraph>
                <Paragraph style={[styles.infoText]}>{strings('cutForm.cutConsequences')}</Paragraph>
                <View style={globalStyles.yesNoButtonView}>
                    <BtxButton
                        backgroundColor={primaryColor}
                        title= {strings('globals.yes')}
                        color={black}
                        onPress={onPressCut}
                        alignSelf={'center'}
                        marginRight={12}
                    />
                    <BtxButton
                        backgroundColor={transparent}
                        title= {strings('globals.cancel')}
                        color={black}
                        onPress={ () => setConfirmationMode(false) }
                        alignSelf={'center'}
                    />
                </View>
            </View>
        );
    }

    return (
        <View style={containerStyle}>
            <BtxButton
                backgroundColor={primaryColor}
                title= {strings('cutForm.cutButton')}
                color={black}
                onPress={ () => setConfirmationMode(true) }
                alignSelf={'center'}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    successText: {
        color: primaryColor,
        fontSize: sizeL,
        fontWeight: '500',
        marginHorizontal: 4,
    },
    boldText: {
        fontWeight: 'bold',
    },
    infoText: {
        color: grey,
        fontSize: sizeS,
        textAlign: 'center',
        marginHorizontal: 24,
    },
});
