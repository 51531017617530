import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Welcome from '../Screens/Welcome';
import Login from '../Screens/Login';
import Rules from '../Screens/Rules';
import SignOn from '../Screens/SignOn';
import ConfirmedSignOn from '../Screens/ConfirmedSignOn';
import Home from '../Screens/Home';
import PasswordForgotten from '../Screens/PasswordForgotten';
import MyHeader from '../Shared/myHeader';
import { strings } from '../locales/i18n';
import ResetPassword from '../Screens/ResetPassword';

const Stack = createStackNavigator();

export default function LoginStack({ navigation }) {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader />),
        }}
    >
      <Stack.Screen name={strings("navigation.Welcome")} component={Welcome} options={ {headerShown: false} }/>
      <Stack.Screen name={strings("navigation.LoginHome")} component={Login} options={ {headerShown: false} }/>
      <Stack.Screen name={strings("navigation.SignOn")} component={SignOn} options={ {headerShown: false} }/>
      <Stack.Screen name={strings("navigation.LoginConfirmSignOn")} component={ConfirmedSignOn} options={ {headerShown: false} }/>
      <Stack.Screen name={strings("navigation.HomePage")} component={Home} />
      <Stack.Screen name={strings("navigation.Rules")} component={Rules} />
      <Stack.Screen name={strings("navigation.PasswordForgotten")} component={PasswordForgotten} />
      <Stack.Screen name={strings("navigation.ResetPassword")} component={ResetPassword} />
    </Stack.Navigator>
  );
};
