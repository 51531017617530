import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { white, thirdColor, grey } from '../Styles/colors';
import { globalStyles } from '../Styles/globals';
import { Paragraph, Title2 } from './Texts';
import { sizeL } from '../Styles/text';

function ToggleIcon({ onPress, isOpen }) {
    if (!onPress) {
        return (<></>)
    }

    const iconName = isOpen ? 'down' : 'right';

    return (
        <TouchableOpacity
            disabled={!onPress}
            onPress={onPress}
            style={styles.toggleContainer}
        >
            <AntDesign name={iconName} size={sizeL} color={thirdColor} />
        </TouchableOpacity>
    )
};

function Details({ render, isVisible}) {
    if (!isVisible) {
        return (<></>)
    }

    return (
        <View style={styles.details}>
            <Paragraph>{ render }</Paragraph>
        </View>
    )
}

export function RulesElement({ title, children, open}) {
    const [isOpen, setIsOpen] = useState(open ? open : false);

    const onPress = children ? () => {setIsOpen(!isOpen)} : undefined;
    const childrenDisplay = isOpen ?  children : <></> ;

    return (
        <View>
            <View style={[styles.container, globalStyles.shadow]}>
                <View style={[styles.header]}>
                    <View style={styles.headerMain}>
                        <ToggleIcon onPress={onPress} isOpen={isOpen} />
                        <View style={styles.headerMainText}>
                            <TouchableOpacity style={styles.headerMainTextName} onPress={onPress}>
                                <Title2 style={{color:thirdColor}}>{title}</Title2>
                            </TouchableOpacity>
                        </View>  
                    </View>
                </View>
                {childrenDisplay}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignSelf: 'stretch',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        padding: 12,
        marginVertical: 8,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: grey,
        backgroundColor: white,
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 4,
    },
    headerMain: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerMainText: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingRight: 4,
    },
    headerMainTextName: {
        flexDirection: 'row',
    },
    //Details
    details: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        paddingHorizontal: 4,
        paddingBottom: 16,
    },
    //Toggle
    toggleContainer: {
        padding: 8,
    }
});
