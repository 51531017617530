import React from 'react';
import { StyleSheet } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { FontAwesome5 } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { primaryColor, grey, black } from '../Styles/colors';
import LeagueDraftStack from './LeagueDraftStack';
import LeagueCoachingStack from './LeagueCoachingStack';
import LeagueRankingStack from './LeagueRankingStack';
import LeagueSettingsStack from './LeagueSettingsStack';
import LeagueWaitingStack from './LeagueWaitingStack';
import LeagueFinishedStack from './LeagueFinishedStack';
import { strings } from '../locales/i18n';
import { LeagueOfficeStack } from './LeagueOfficeStack';
import { LeagueFreeAgencyStack } from './LeagueFreeAgencyStack';

const Tab = createBottomTabNavigator();

export default function LeagueBottomTabsBar() {
  const league = useSelector(state => state.league);
  const statusId = league?.statusId;

  const waitingScreen = <Tab.Screen key="waiting" name={strings("navigation.LeagueWaitingStack")} component={LeagueWaitingStack} options={{ tabBarLabel: 'Home', tabBarIcon: ({ focused, color, size }) => ( <FontAwesome5 name='basketball-ball' size={size} style={{...styles.icon, ...{ color: color }} } /> ) }} />;
  const finishedScreen = <Tab.Screen key="finished" name={strings("navigation.LeagueFinishedStack")} component={LeagueFinishedStack} options={{ tabBarLabel: 'Home', tabBarIcon: ({ focused, color, size }) => ( <FontAwesome5 name='basketball-ball' size={size} style={{...styles.icon, ...{ color: color }} } /> ) }} />;
  const draftScreen = <Tab.Screen key="draft" name={strings("navigation.LeagueDraftStack")} component={LeagueDraftStack} options={{ tabBarLabel: 'Draft', tabBarIcon: ({ focused, color, size }) => ( <FontAwesome5 name='list-ol' size={size} style={{...styles.icon, ...{ color: color }} } /> ) }} />;
  const rankingScreen = <Tab.Screen key="ranking" name={strings("navigation.LeagueRankingStack")} component={LeagueRankingStack} options={{ tabBarLabel: 'Ranking', tabBarIcon: ({ focused, color, size }) => ( <FontAwesome5 name="trophy" size={size} style={{...styles.icon, ...{ color: color }} } /> ) }} />
  const coachingScreen = <Tab.Screen key="coaching" name={strings("navigation.LeagueCoachingStack")} component={LeagueCoachingStack} options={{ tabBarLabel: 'Coaching', tabBarIcon: ({ focused, color, size }) => ( <FontAwesome5 name="users" size={size} style={{...styles.icon, ...{ color: color }} } /> ) }} />
  const officeScreen = <Tab.Screen key="office" name={strings("navigation.LeagueOfficeStack")} component={LeagueOfficeStack} options={{ tabBarLabel: 'Office', tabBarIcon: ({ focused, color, size }) => ( <FontAwesome5 name='newspaper' size={size} style={{...styles.icon, ...{ color: color }} } /> ) }} />
  const freeAgencyScreen = <Tab.Screen key="freeAgency" name={strings("navigation.LeagueFreeAgencyStack")} component={LeagueFreeAgencyStack} options={{ tabBarLabel: 'Free Agency', tabBarIcon: ({ focused, color, size }) => ( <FontAwesome5 name='shopping-cart' size={size} style={{...styles.icon, ...{ color: color }} } /> ) }} />
  const settingsScreen = <Tab.Screen key="settings" name={strings("navigation.LeagueSettingsStack")} component={LeagueSettingsStack} options={{ tabBarLabel: 'Settings', tabBarIcon: ({ focused, color, size }) => ( <FontAwesome5 name='cog' size={size} style={{...styles.icon, ...{ color: color }} } /> ) }} />

  let screens = {
    //Release 0.1 without settingsScreen
    WAITING: [waitingScreen],
    DRAFT: [draftScreen, officeScreen],
    REGULARSEASON: [ rankingScreen, coachingScreen, draftScreen, officeScreen, freeAgencyScreen],
    FINISHED: [finishedScreen, rankingScreen, officeScreen, freeAgencyScreen]
  };

  const weekNotStarted = league.season?.regularSeason?.weeks.filter(week => !week.isStarted) || [];
  if (weekNotStarted.length === 0) {
    screens.REGULARSEASON.splice(1, 1)
  }

  return (
    <Tab.Navigator
      lazy={true}
      backBehavior='firstRoute'
      screenOptions={{
        tabBarActiveTintColor: black,
        tabBarShowLabel: false,
        tabBarInactiveTintColor: grey,
        headerShown: false,
      }}
    >
      { screens[statusId] }
    </Tab.Navigator>
  );
}

const styles = StyleSheet.create({
    icon: {
        color: grey,
    }
})
