import React from 'react';
import { View , StyleSheet} from 'react-native';
import { thirdColor } from '../Styles/colors';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { RulesElement } from '../Components/RulesElement';
import { ListElement, Paragraph, Title1 } from '../Components/Texts';

export default function Rules() {


    return (
        <ScrollViewMainContainer>
            <View>
                <View>
                    <Title1 style={styles.rulesTitles}>Les règles du jeu</Title1>
                    <View style={styles.introduction}>
                        <Paragraph>{"Les jeux de fantasy connaissent un grand succès ces dernières années. Ils permettent de suivre une compétition de manière plus rapprochée en donnant un plus grand intérêt aux résultats et aux performances de certaines équipes et/ouou joueurs. \n\nBasketix est une fantasy NBA pour les fans et les novices du basket américain. Quel est le principe du jeu ?"}</Paragraph>
                    </View>
                    <RulesElement
                        title={"Création de la ligue"}
                        open={false}
                    >
                        <Paragraph>{"Basketix est un jeu où on joue avec ses amis, collegues, etc.. en créant une ligue. N’importe qui peut en créer une, même toi ! Crée une ligue et invite tes amis à la rejoindre !"}</Paragraph>
                        <Paragraph>{"\nLes ligues peuvent être paramétrées :"}</Paragraph>
                        <ListElement>{"Durée de la saison régulière (en semaine)"}</ListElement>
                        <ListElement>{"Choix de l'ordre de draft (aléatoire, meilleur en premier, meilleur en dernier)"}</ListElement>
                        <ListElement>{"Choix du type de draft (A-B-C-C-B-A ou A-B-C-A-B-C)"}</ListElement>
                        <ListElement>{"Nombre de tour de draft pour sélectionner des joueurs"}</ListElement>
                        <Paragraph>{"Lorsque tout le monde a rejoint la ligue, une draft commence."}</Paragraph>
                    </RulesElement>
                    <RulesElement
                        title={"La draft"}
                        open={false}
                    >
                        <Paragraph>{"Une saison commence par la phase de draft. C'est un moment clé pour la suite du jeu, et très stimulant pour les joueurs. \n"}</Paragraph>
                        <Paragraph>{"Le but ? Les membres de la ligue choisissent tour à tour les joueurs NBA qui composeront leur effectif final. Mais attention vous devrez respecter certaines conditions : \n"}</Paragraph>
                        <ListElement>{"Un salaire fictif est calculé pour chaque joueur. La masse salariale de ton effectif ne doit pas dépasser 200 M$."}</ListElement>
                        <ListElement>{"Veiller à avoir des joueurs à tous les postes"}</ListElement>
                        <Paragraph>{"\nLorsque ce n’est pas encore son tour, tu pourras présélectionner des joueurs pour préparer ton tour et accélérer la draft. C’est la pré-draft. Si tu as sélectionné l’option “Draft automatique”, le jeu sélectionnera automatiquement le premier joueur NBA disponible de cette liste."}</Paragraph>
                    </RulesElement>
                    <RulesElement
                        title={"Saison régulière"}
                        open={false}
                    >
                        <Paragraph>{"Maintenant que tu as constitué ton effectif, j’espère que tu es prêt.. La saison commence ! \n"}</Paragraph>
                        <Paragraph>{"Pour toutes les semaines (du lundi soir au dimanche soir) de la saison régulière, tu devras constituer ton équipe de 5 joueurs en respectant leur position (certains joueurs peuvent jouer à plusieurs positions). Cette équipe peut être modifiée la semaine suivante jusqu'au lundi soir à 00h00 (le mardi matin du coup). "}</Paragraph>
                        <Paragraph>{"Chaque match joué par un joueur NBA donne lieu à une évaluation : somme des actions positives moins la somme des actions négatives. \n"}</Paragraph>
                        <Paragraph style={styles.scoreRules}>{"\nEval = Points + Rebonds + Passes + Interceptions + Contres - Tir ratés - Lancer-franc ratés - Pertes de balle \n\n"}</Paragraph>
                        <Paragraph>{"Attention, toutes les équipes ne jouent pas le même nombre de matchs chaque semaine. Avoir un effectif complet te permettra d’être performant toutes les semaines."}</Paragraph>
                        <Paragraph>{"Chaque semaine la somme des évaluations de ton 5 majeurs sera calculée pour te proposer un classement hebdomadaire, et un classement général."}</Paragraph>
                    </RulesElement>
                    <RulesElement
                        title={"Free Agency"}
                        open={false}
                    >
                        <Paragraph>{"A venir"}</Paragraph>
                    </RulesElement>
                    <RulesElement
                        title={"Fin de la saison"}
                        open={false}
                    >
                        <Paragraph>{"A la fin d'une saison, le premier au classement général sera déclaré vainqueur. Une nouvelle saison pourra être relancée. Les paramétrages de la nouvelle saison seront demandés, puis une nouvelle draft sera proposée"} </Paragraph>
                    </RulesElement>
                </View>
            </View>
        </ScrollViewMainContainer>
    )
};
const styles = StyleSheet.create({
    rulesTitles: {
        color:thirdColor,
        textAlign:'center'
    },
    introduction:{
        marginLeft:8,
        marginRight:8,
        marginTop:8,
        marginBottom:8
    },
    scoreRules:{
        textAlign:'center',
        fontWeight: 'bold'
    }
});
