import React, { useState, useEffect } from 'react';
import { StyleSheet, TouchableOpacity, View, FlatList, Platform } from 'react-native';
import {  useDispatch, useSelector } from 'react-redux';
import { UsersAPI } from '../API/Users';
import { NbaPlayersAPI } from '../API/NbaPlayers';
import { NBAGamesAPI } from '../API/NbaGames';
import { globalStyles } from '../Styles/globals';
import { primaryColor, secondaryColor, black, alphaColor, getPaletteColor } from '../Styles/colors';
import BtxButton from '../Components/BtxButton';
import I18n, { strings, toLocaleDate } from '../locales/i18n';
import { sizeM, sizeS, sizeXS } from '../Styles/text';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { actions } from '../Store/actions';
import { Title1, Title3, Paragraph} from '../Components/Texts';
import { FontAwesome } from '@expo/vector-icons';
import { capitalizeFirstLetter } from '../Service/jsUtils';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GamesResult } from '../Components/GamesResult';
import { POSTPONED } from '../Shared/Constants';
import { SwitchBasketix } from '../Components/SwitchBasketix';

export default function Home({ navigation }) {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        performances: [],
        performancesDate: undefined,
        isNbaPerformancesLoading: false,
        games: [],
        gamesDate: undefined,
        isNbaGamesLoading: false,
    });
    const [hiddenScore, setHiddenScore] = useState(true);
    const [hiddenResult, setHiddenResult] = useState(true);
    const leagues = useSelector(state => state.user.leagues);
    const isLoadingSeason = useSelector(state => state.user.isLoadingBasketixInfo);
    const isWeb = Platform.OS === 'web';

    useEffect (() => {
        dispatch({type : actions.SET_USER_IS_LOADING, value: true})
        UsersAPI.get().then(data => {
            if (! data.error) {
                dispatch({ type: actions.SET_USER_INFOS, value: {
                    leagues: data.leagues,
                    email: data.email,
                    name: data.userName,
                }});
            } else {
                console.log(data.error);
            }
            dispatch({type : actions.SET_USER_IS_LOADING, value: false})
        });
    },[]);

    useEffect (() => {
        setState(prev => ({
            ...prev,
            isNbaPerformancesLoading: true,
            isNbaGamesLoading: true,
        }));

        NbaPlayersAPI.lastStats().then( (value) => {
            setState(prev => ({
                ...prev,
                performances: value.stats,
                performancesDate: value.date,
                isNbaPerformancesLoading: false,
            }));
        })

        // NBAGamesAPI.last().then( (value) => {
        //     setState(prev => ({
        //         ...prev,
        //         games: value.games,
        //         gamesDate: value.date,
        //         isNbaGamesLoading: false,
        //     }));
        // })

    }, []);

    useEffect (() => {
        AsyncStorage.getItem('hiddenScore').then( (value) => {
            setHiddenScore((value !== null && value==='true') || value === null);
        });
        AsyncStorage.getItem('hiddenResult').then( (value) => {
            setHiddenResult((value !== null && value==='true') || value === null);
        });
    }, []);

    const goToLeague = async(league) => {
        const action = { type: 'UPDATE_APP_LOADER', value: { leagueIdToLoad: league.leagueId, loadingId: Date.now() } };
        dispatch(action);
        navigation.navigate('LeagueLoadingStack');
    };

    const createLeague = () => {
        navigation.push(strings('navigation.LeagueCreate'));
    };

    const joinLeague = () => {
        navigation.push(strings('navigation.LeagueJoin'));
    };

    const styleLeagueItem = (item, index) => {
        const favoriteStyle = item.isFavorite ? styles.favoriteLeague : {};
        return [styles.leagueItem, {backgroundColor: getPaletteColor(index)}, favoriteStyle, globalStyles.shadow];
    }

    const displayLegend = () => {
        const shouldShowLegend = state.games.filter(game => game.extendedGameStatus === POSTPONED).length > 0;
        if (shouldShowLegend) {
            return (
            <View style={styles.legend}>
                <FontAwesome name='calendar-times-o' size={14} color={black} />
                <Paragraph>{strings('home.postponedLegend')}</Paragraph>
            </View>);
        }
    }

    const changeHiddenScore = async () => {
        if(hiddenScore)
        {
            await AsyncStorage.setItem('hiddenScore', false);
            setHiddenScore(false)
        }
        else {
            await AsyncStorage.setItem('hiddenScore', true);
            setHiddenScore(true)
        }
    }

    const changeHiddenResult = async () => {
        if(hiddenResult)
        {
            await AsyncStorage.setItem('hiddenResult', false);
            setHiddenResult(false)
        }
        else {
            await AsyncStorage.setItem('hiddenResult', true);
            setHiddenResult(true)
        }
    }


    const displayNbaGamesList = () => {
        if (state.isNbaGamesLoading) {
            return (
                <BtxActivityIndicator size='small' />
            )
        } else if (state.games.length === 0) {
            return (
                <Paragraph>{strings('home.noMatch')}</Paragraph>
            )
        }
        return (
            <View>
                <FlatList
                    style={ styles.list }
                    data={ state.games }
                    keyExtractor={ (item) => item.gameId }
                    extraData={hiddenResult}
                    renderItem={({item, index}) =>  {
                        return (
                            <GamesResult hiddenResult= {hiddenResult} item={item} index={index}></GamesResult>
                        )
                    }}
                    horizontal={ true }
                    showsHorizontalScrollIndicator={isWeb}
                />
                { displayLegend() }
            </View>
        )
    };

    const displayNbaGamesDate = () => {
        if (!state.isNbaGamesLoading) {
            const date = toLocaleDate(state.gamesDate);
            return (
                <View>
                    <Title3>{ capitalizeFirstLetter(date, I18n.locale) }</Title3>
                </View>
                )
        }
    }

    const displayStatPlayerName = (firstname, lastname) => {
        const displayName = hiddenScore ? "???" : `${firstname} ${lastname}`;
        return (
            <View style={ styles.performanceName }>
                <Paragraph style={ styles.performanceNameText }>{displayName}</Paragraph>
            </View>
        )
    }

    const displayNbaGames = () => {
        return (
            <View style={ styles.div }>
                <View style={ styles.divHeader }>
                    <View style={ styles.divTitle}>
                        <Title1 style={ styles.title }>{strings('home.lastMatchs')}</Title1>
                        </View>
                            <View style={styles.divNoSpoile}>
                        </View>
                        <SwitchBasketix
                            label = {strings('home.hiddenResult')}
                            onValueChange={ changeHiddenResult }
                            value = {hiddenResult}
                        />
                        </View>
                        <View style={styles.divDatePerf}>
                            { displayNbaGamesDate() }
                </View>
                <View>
                    { displayNbaGamesList() }
                </View>
            </View>
        )
    };

    const displayNbaPerformancesList = () => {
        if (state.isNbaPerformancesLoading) {
            return <BtxActivityIndicator size='small' />
        }
        else if (state.performances.length === 0) {
            return (
                <Paragraph>{strings('home.noPerformance')}</Paragraph>
            )
        }

        return (
            <FlatList
                style={styles.performanceList}
                data={state.performances}
                extraData={hiddenScore}
                keyExtractor={(item) => item.playerId}
                renderItem={ ({item, index}) => (
                    <View style={ [styles.performanceItem, {backgroundColor: getPaletteColor(index, 1)}] }>
                        <View style={ styles.performanceRanking }>
                            <Paragraph style={ styles.performanceRankingText }>{ item.rank }</Paragraph>
                        </View>
                            {displayStatPlayerName(item.player.firstName, item.player.lastName) }
                        <View style={ styles.performanceScore }>
                            <Paragraph style={ [styles.performanceScoreText, {color: getPaletteColor(index, 1)}] }>
                                { item.evaluation } {strings('home.point')}
                            </Paragraph>
                        </View>
                    </View>
                )}
                horizontal={true}
                showsHorizontalScrollIndicator={isWeb}
                initialNumToRender={40}
            />
        )
    };

    const displayNbaPerformancesDate = () => {
        if (!state.isNbaPerformancesLoading) {
            const date = toLocaleDate(state.performancesDate);
            return <Title3>{ capitalizeFirstLetter(date, I18n.locale) }</Title3>
        }
    }

    const displayNbaPerformances = () => {
        return (
            <View style={ styles.div }>
                <View style={ styles.divHeader }>
                    <View style={ styles.divTitle}>
                        <Title1 style={ styles.title }>{strings('home.lastPerformance')}</Title1>
                    </View>
                        <View style={styles.divNoSpoile}>
                    </View>
                    <SwitchBasketix
                            label = {strings('home.hiddenScore')}
                            onValueChange={ changeHiddenScore }
                            value = { hiddenScore }
                    />
                </View>
                <View style={styles.divDatePerf}>
                    { displayNbaPerformancesDate() }
                </View>
                <View>
                    { displayNbaPerformancesList() }
                </View>
            </View>
        )
    };

    const displayLeaguesList = () => {
        if (isLoadingSeason) {
            return <BtxActivityIndicator size='small' />
        } else if (leagues.length === 0) {
            return <Paragraph>{strings('home.noLeague')}</Paragraph>
        }

        return (
            <FlatList
                style={styles.list}
                data={leagues}
                keyExtractor={(item) => item.leagueId}
                renderItem={ ({item, index}) => (
                    <TouchableOpacity style={ styleLeagueItem(item, index) } onPress={ () => { goToLeague(item)} }>
                        <Paragraph style={ styles.leagueText }>{ item.leagueName }</Paragraph>
                        <Paragraph style={ [styles.leagueStatusText, {color: getPaletteColor(index)}] }>{ item.status }</Paragraph>
                    </TouchableOpacity>
                )}
                horizontal={true}
                showsHorizontalScrollIndicator={isWeb}
            />
        )
    };

    const displayLeagues = () => {
        return (
            <View style={ styles.div }>
                <View style= { styles.divHeader }>
                    <View style={ styles.divTitle}>
                        <Title1>{strings('home.myLeagues')}</Title1>
                    </View>
                    <View style={ styles.divButtons}>
                        <BtxButton
                            title={strings('home.createLeague')}
                            backgroundColor={black}
                            color={primaryColor}
                            onPress={ createLeague}
                        />
                        <BtxButton
                            title={strings('home.joinLeague')}
                            backgroundColor={black}
                            color={secondaryColor}
                            onPress={joinLeague}
                        />
                    </View>
                </View>
                <View>
                    { displayLeaguesList() }
                </View>
            </View>
        )
    };

    return (
        <ScrollViewMainContainer>
            { displayLeagues() }
            { displayNbaPerformances() }
            {/* { displayNbaGames() } */}
        </ScrollViewMainContainer>
    );
}

const styles = StyleSheet.create({
    div: {
        flexDirection: 'column',
        marginTop: 16,
        marginBottom: 28,
    },
    legend: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        marginTop:8
    },
    divHeader: {
        flexDirection: 'row',
        marginBottom: 12,
        alignItems: 'center',
    },
    divTitle: {
        flex: 3,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    divButtons: {
        flex: 1,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    leagueItem: {
        height: 200,
        width: 150,
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 40,
        marginBottom: 16,
        marginRight: 12,
        borderRadius: 8,
        alignItems: 'center',
        backgroundColor: primaryColor,
    },
    favoriteLeague: {
        borderColor: black,
        borderWidth: 2,
    },
    leagueText: {
        fontWeight: 'bold',
        fontSize: sizeM,
        textAlign: 'center',
    },
    leagueStatusText: {
        position: 'absolute',
        bottom: 8,
        fontWeight: 'bold',
        fontSize: sizeXS,
        backgroundColor: black,
        color: primaryColor,
        textTransform: 'uppercase',
        padding: 4,
        borderRadius: 4,
    },
    performanceItem: {
        flexDirection: 'column',
        backgroundColor: primaryColor,
        marginHorizontal: 4,
        marginBottom: 8,
        padding: 8,
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderRadius: 8,
        width: 140,
        height: 80,
    },
    performanceRanking: {
        position: 'absolute',
        left: 0,
    },
    performanceRankingText: {
        fontWeight: 'bold',
        fontSize: 60,
        color: alphaColor(black, 20),
    },
    performanceName: {
        marginBottom: 6,
    },
    performanceNameText: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: sizeM,
    },
    performanceScore: {
        backgroundColor: black,
        padding: 8,
        borderRadius: 8,
        marginTop: 2,
        width: 64,
    },
    performanceScoreText: {
        fontWeight: 'bold',
        color: primaryColor,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: sizeS,
    },
    divNoSpoile: {
        flex: 3,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    divDatePerf : {
        marginBottom:12,
    },
});
