import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { View, StyleSheet } from 'react-native';
import { globalStyles } from '../Styles/globals';
import { strings } from '../locales/i18n';
import RNPickerSelect from 'react-native-picker-select';
import SwitchSelector from "react-native-switch-selector";
import { primaryColor } from '../Styles/colors';
import { Paragraph } from './Texts';
import { quantity, indexObjectValue } from '../Service/seasonSettings';
import { actions } from '../Store/actions';
import { SeasonAPI } from '../API/Season';
import { BtxActivityIndicator } from './BtxActivityIndicator';

export function SeasonSettingsForm({seasonId}) {
        const dispatch = useDispatch();

        const values = useSelector(state => state.seasonSettings.data);
        const isLoadingNextSeasonSettings = useSelector(state => state.seasonSettings.isLoadingNextSeasonSettings);

        useEffect(() => {
            if (seasonId) {
                dispatch({ type: actions.seasonSettings.LOAD_NEXT_SEASON_SETTINGS });
                SeasonAPI.nextSeasonSettings(seasonId).then(data => {
                    if (!data.error) {
                        dispatch({ type: actions.seasonSettings.UPDATE_SEASON_SETTING, value: data });
                        dispatch({ type: actions.seasonSettings.LOAD_NEXT_SEASON_SETTINGS_SUCCESS });
                    } else {
                        console.log(data.error);
                        dispatch({ type: actions.seasonSettings.LOAD_NEXT_SEASON_SETTINGS_ERROR });
                    }
                }).catch(err => {
                    console.log(err);
                    dispatch({ type: actions.seasonSettings.LOAD_NEXT_SEASON_SETTINGS_ERROR });
                })
            }
        }, [seasonId]);


        // Inputs settings
        const roundDraft = {min: 7, max: 12};
        const nWeek = {min: 2, max: 8};

        // Create form data
        roundDraft.values = quantity(roundDraft.min, roundDraft.max);
        nWeek.values = quantity(nWeek.min, nWeek.max);
        const draftTypeInput = [
            {label : strings('leagueCreate.daftTypeLooserFirst'), value: 'looserFirst'},
            {label: strings('leagueCreate.daftTypeRandom'), value: 'random'},
            {label: strings('leagueCreate.daftTypeWinnerFirst'), value: 'winnerFirst'},
        ];
        const draftReverseOptions = [
            {label: strings('leagueCreate.reverseDraft'), value: true},
            {label: strings('leagueCreate.noReverseDraft'), value: false},
        ];

        // Set value
        const draftTypeValue = values.draftType !== undefined ? indexObjectValue(values.draftType, draftTypeInput) : 0;
        const draftReverseValue = values.isDraftReverse !== undefined ? indexObjectValue(values.isDraftReverse, draftReverseOptions) : 0;
        const roundDraftIndexValue = values.nDraftRound >= roundDraft.min && values.nDraftRound <= roundDraft.max ? values.nDraftRound : roundDraft.values[0].key;
        const weekNumberIndexValue = values.nWeek >= nWeek.min && values.nWeek <= nWeek?.max ? values.nWeek : nWeek.values[0].key;

        const set = (formInputId, formValue) => {
            const action = { type: actions.seasonSettings.UPDATE_SEASON_SETTING, value: { [formInputId]: formValue } };
            dispatch(action);
        };

        if (isLoadingNextSeasonSettings) {
            return (
                <View style={[styles.loadContainer]}>
                    <BtxActivityIndicator size='large' />
                </View>
            );
        }

        return (
            <View style={[styles.form]}>
                <View style={[styles.formGroup]}>
                    <Paragraph style={[globalStyles.textInputLabel]}>{ strings('leagueCreate.daftTypeLabel') }</Paragraph>
                    <SwitchSelector
                        options={draftTypeInput}
                        borderRadius={8}
                        initial={draftTypeValue}
                        buttonColor={primaryColor}
                        onPress={(value) => set('draftType', value)}
                    />
                </View>

                <View style={[styles.formGroup]}>
                    <Paragraph style={[globalStyles.textInputLabel]}>{ strings('leagueCreate.daftReverseLabel') }</Paragraph>
                    <SwitchSelector
                        options={draftReverseOptions}
                        borderRadius={8}
                        initial={draftReverseValue}
                        buttonColor={primaryColor}
                        onPress={(value) => set('isDraftReverse', value)}
                    />
                </View>

                <View style={[styles.formGroup]}>
                    <Paragraph style={[globalStyles.textInputLabel]}>{ strings('leagueCreate.nbaDraftTourLabel') }</Paragraph>
                    <RNPickerSelect
                        placeholder= {{}}
                        onValueChange={(value) => set('nDraftRound', parseInt(value))}
                        items={roundDraft.values}
                        itemKey={roundDraftIndexValue}
                    />
                </View>

                <View style={[styles.formGroup]}>
                    <Paragraph style={[globalStyles.textInputLabel]}>{strings('leagueCreate.nbaWeekLabel')}</Paragraph>
                    <RNPickerSelect
                        placeholder= {{}}
                        onValueChange={(value) => set('nWeek', parseInt(value))}
                        items={nWeek.values}
                        itemKey={weekNumberIndexValue}
                    />
                </View>
            </View>
        );
};

const styles = StyleSheet.create({
    loadContainer: {
        margin: 20,
        padding: 4,
    },
    formGroup: {
        marginVertical: 10,
        paddingVertical: 5,
    },
    form: {
        marginVertical: 20,
    },
});
