import basketixApiCall from './BasketixApi';

const getSeason = async(seasonId) => {
    const endpoint = '/basketix/season/get';
    const body = {seasonId};
    const season = await basketixApiCall(endpoint, body, 'POST');

    return season;
};

const nextSeasonSettings = async(seasonId) => {
    const endpoint = '/basketix/season/next/settings';
    const body = {seasonId};
    const settings = await basketixApiCall(endpoint, body, 'POST');

    return settings;
};

const getRosters = async(data) => {
    const endpoint = '/basketix/season/rosters';
    const {seasonId, basketixIds, weekId} = data;
    let body = { seasonId, basketixIds };
    if (weekId) {
        body.weekId = weekId;
    }

    const rosters = await basketixApiCall(endpoint, body, 'POST');
    return rosters;
};

const getTeam = async(data) => {
    const endpoint = '/basketix/season/v2/team/get';
    const {seasonId, basketixId, weekId} = data;
    let body = { seasonId, basketixId, weekId };

    const team = await basketixApiCall(endpoint, body, 'POST');
    return team;
};

const setTeam = async(data) => {
    const endpoint = '/basketix/season/v2/team/set';
    const {seasonId, weekId, players} = data;
    const body = { seasonId, weekId, players };

    const isSaved = await basketixApiCall(endpoint, body, 'POST');

    return isSaved;
};

const getCourt = async(data) => {
    const endpoint = '/basketix/season/team/court';
    const {seasonId, basketixId} = data;
    let body = { seasonId, basketixId };
    const court = await basketixApiCall(endpoint, body, 'POST');

    return court;
};


const setCourt = async(color, seasonId, basketixId) => {
    const endpoint = '/basketix/season/team/court/update';
    const body = {
        seasonId: seasonId,
        basketixId: basketixId,
        settings:{
            backgroundColor: color.backgroundColor,
            courtColor: color.courtColor,
            paintColor: color.paintColor,
            threePointsColor: color.threePointsColor,
            linesColor: color.linesColor,
            sidePaintColor: color.sidePaintColor,
            linesWidth:color.linesWidth,
        }
    };
    const court = await basketixApiCall(endpoint, body, 'POST');

    return court;
};

const create = async(data) => {
    const endpoint = '/basketix/season/create';
    const {
        leagueId,
        draftType,
        isDraftReverse,
        nDraftRound,
        nWeek,
    } = data;

    const body = {
        leagueId,
        draftType,
        isDraftReverse,
        nDraftTour: nDraftRound,
        nWeekSeason: nWeek,
    };

    const season = await basketixApiCall(endpoint, body, 'POST');

    return season;
};

const capSpaces = async(data) => {
    const { seasonId, withSecrets, basketixIds } = data
    const endpoint = '/basketix/season/capspaces';
    let body = { seasonId };

    if (withSecrets) { body.withSecrets = withSecrets }
    if (basketixIds) { body.basketixIds = basketixIds }

    const capSpaces = await basketixApiCall(endpoint, body, 'POST');

    return capSpaces;
};

export const SeasonAPI = {
    getSeason,
    nextSeasonSettings,
    getRosters,
    getTeam,
    setTeam,
    create,
    capSpaces,
    getCourt,
    setCourt,
};
