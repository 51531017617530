import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { strings } from '../locales/i18n';
import { UsersAPI } from '../API/Users';
import { thirdColor, white, black, red } from '../Styles/colors';
import {Formik} from 'formik';
import * as yup from 'yup';
import { sizeM } from '../Styles/text';
import { Paragraph, Title1 } from '../Components/Texts';
import { showMessage } from "react-native-flash-message";
import { checkPassword } from '../Service/authenticationService';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { SetPasswordForm } from '../Components/SetPasswordForm';


export default function ResetPassword({route, navigation}) {
    const [email, setEmail] = useState('user@gmail.com');

    const reviewSchema = yup.object({
        password : yup.string()
            .required(strings('resetPassword.passwordRequired'))
            .test('', strings('resetPassword.passwordType'), function(value) {
            return checkPassword(value)
            }),
        verifyPassword : yup.string()
            .required(strings('resetPassword.verifyPasswordRequired'))
            .test('', strings('resetPassword.verifyPasswordType'), function(value) {
            return this.parent.password === value;
        }),
    });

    const initialFormValues = {
        password: '',
        verifyPassword: '',
    };

    const handleSubmit = (values) => {
        if (values) {
            UsersAPI.resetPassword(values).then(data => {
                if (data) {
                    showMessage({
                        message: strings('resetPassword.resetPasswordSuccess'),
                        type: 'success',
                        icon: 'success',
                    });
                    navigation.navigate(strings('navigation.LoginHome'), {email});
                }
            });
        }
    }


    return (
        <ScrollViewMainContainer backgroundColor={black}>
            <View style={ styles.basketixFooter }>
                <Title1 style={styles.header}>{strings('resetPassword.resetPasswordTitle')}</Title1>
                <Paragraph style={styles.text}>{strings('resetPassword.resetPasswordDescription')}</Paragraph>
                <Formik
                    enableReinitialize
                    initialValues={initialFormValues}
                    validationSchema={reviewSchema}
                    onSubmit={ (values) => { handleSubmit(values) } }
                >
                {(formikprops) => (
                    <View style={ styles.login } >
                        <SetPasswordForm
                            onPasswordChange={formikprops.handleChange('password')}
                            onConfirmedPasswordChange={formikprops.handleChange('verifyPassword')}
                            onPasswordValue={formikprops.values.password}
                            onConfirmedPassWordValue={formikprops.values.verifyPassword}
                            onBlurPassword={formikprops.handleBlur('password')}
                            onBlurConfirmedPassword={formikprops.handleBlur('verifyPassword')}
                            formikprops={formikprops}
                        />
                    </View>
                )}
                </Formik>
            </View>
        </ScrollViewMainContainer>
    )
};

const styles = StyleSheet.create({
    login: {
        display: 'flex',
        marginTop: 42,
        marginBottom: 12,
    },
    header: {
        color:white,
        marginBottom:16,
    },
    text: {
        color:white
    },
    textInput: {
        borderColor: thirdColor,
        color: thirdColor,
        fontWeight: "bold",
    },
    basketixFooter: {
        flex: 2,
        justifyContent: 'flex-start',
    },
    textInputTextStyle: {
        color: thirdColor,
        fontSize: sizeM
    },
    badRequest: {
        color: red,
        fontWeight: 'bold',
    },
});
