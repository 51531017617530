export function getPaddingHorizontal(width) {
    const screenType = getScreenType(width);
    switch(screenType) {
        case 'large':
            return 400;
        case 'medium':
            return 150;
        case 'small':
        default:
            return 0;
    }
}

export function getScreenWidth(width) {
    const screenType = getScreenType(width);
    switch(screenType) {
        case 'large':
            return 800;
        case 'medium':
            return 700;
        case 'small':
        default:
            return '100%';
    }
}

export function getScreenBorderWidth(width) {
    const screenType = getScreenType(width);
    switch(screenType) {
        case 'large':
        case 'medium':
            return 2;
        case 'small':
        default:
            return 0;
    }
}

export function getScreenType(fullScreenWidth) {
    if (fullScreenWidth > 1200) {
        return 'large';
    }

    if (fullScreenWidth < 1200 && fullScreenWidth > 768) {
        return 'medium';
    }

    return 'small';
}
