import React from 'react';
import { StyleSheet, View} from 'react-native';
import { toLocaleDateShort, strings } from '../locales/i18n';
import { globalStyles } from '../Styles/globals';
import { CardDetailsCell } from './CardDetailsCell';
import { CardDetailsRow } from './CardDetailsRow';

export function PlayerGamesDetails({ games }) {
    return (
        <View style={[globalStyles.flex, globalStyles.flexColumn]}>
            {
                games.map((game, index)  => {
                    const formattedDate = toLocaleDateShort(game.gameDate);
                    return (<PlayerGameItem
                        key={game.gameId}
                        teamName={game.team.teamFullName}
                        oppTeamName={game.opponent.teamFullName}
                        date={formattedDate}
                        isHomeGame={game.isHome}
                        isGameFinished={game.isFinished}
                        score={game.evaluation ?? 0}
                        winner={game.team.winner}
                        isLastItem={index === games.length - 1}
                    />)
                })
            }
        </View>
    )
}

function PlayerGameItem({ teamName, oppTeamName, date, isHomeGame, isGameFinished, score, winner, isLastItem }) {
    const itemMarginStyle = isLastItem ? undefined : styles.itemMargin;
    return (
        <CardDetailsRow style={itemMarginStyle}>
            <CardDetailsCell
                upperValue={teamName}
                lowerValue={oppTeamName}
                style={styles.leftCell}
            />
            <CardDetailsCell
                upperValue={date}
                lowerValue={isHomeGame ? strings('playerGameItem.home') : strings('playerGameItem.away')}
                style={styles.middleCell}
            />
            <CardDetailsCell
                upperValue={isGameFinished ? `${score} pts` : ''}
                lowerValue={isGameFinished ? winner ? strings('playerGameItem.win') : strings('playerGameItem.lose') : ''}
                style={styles.rightCell}
            />
        </CardDetailsRow>
    )
}

const styles = StyleSheet.create({
    itemMargin: {
        marginBottom: 8,
    },
    leftCell: {
        flex: 3,
    },
    middleCell: {
        flex: 1,
    },
    rightCell: {
        flex: 1,
        alignItems: 'flex-end',
    },
});
