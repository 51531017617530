import React from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { black, secondaryColor } from '../Styles/colors';
import { sizeS } from '../Styles/text';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { Paragraph } from './Texts';

export default function BtxButton(props) {

    let buttonProps = {...props};
    delete buttonProps.color;
    delete buttonProps.fontSize;
    delete buttonProps.icon;

    let customTextStyle = {};
    if (props.color) { customTextStyle.color = props.color }
    if (props.fontSize) { customTextStyle.fontSize = props.fontSize }

    if (props.loading) {
        return (
            <TouchableOpacity
                disabled={true}
                style={[styles.button, buttonProps]}
                onPress={ props.onPress }
            >
                <BtxActivityIndicator size={'small'} color={props.color ? props.color : black}/>
            </TouchableOpacity>
        )
    }
    const icon = props.icon ? <View style={styles.iconContainer}>{ props.icon }</View> : <></>;

    return (
        <TouchableOpacity disabled={props.disabled || false} style={[styles.button, buttonProps]} onPress={ props.onPress }>
            {icon}
            <Paragraph style={[styles.text, customTextStyle]} >{ props.title }</Paragraph>
        </TouchableOpacity>
    )
};

const styles = StyleSheet.create({
    button: {
        // width: 100,
        // alignSelf: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 6,
        marginHorizontal: 1,
        textAlign: 'center',
        borderRadius: 4,
        backgroundColor: secondaryColor,
    },
    text: {
        textTransform: 'uppercase',
        fontSize: sizeS,
        textAlign: 'center',
        color: 'black',
        fontWeight: 'bold',
    },
    iconContainer: {
        marginRight: 4,
    }
});
