const initialState = {};

function teams(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case 'UPDATE_TEAMS':
            nextState = {
                ...state,
                ...action.value,
            }
            return nextState || state;
        case 'DELETE_TEAMS':
            return initialState;
        default:
            return state;
    }
}

export default teams;
