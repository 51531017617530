import React, {useEffect, useState} from 'react';
import {TouchableOpacity, StyleSheet, View} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import ColorPicker from 'react-native-wheel-color-picker'
import Slider from '@react-native-community/slider';
import { MainContainer, ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { BasketballCourt } from '../Components/BasketballCourt';
import { black, primaryColor, secondaryColor } from '../Styles/colors';
import { strings } from '../locales/i18n';
import { showMessage } from "react-native-flash-message";
import BtxButton from '../Components/BtxButton';
import { Paragraph, Title3, Title4 } from '../Components/Texts';
import { globalStyles } from '../Styles/globals';
import { SeasonAPI } from '../API/Season';
import { useDispatch, useSelector } from 'react-redux'
import { getOppositeColor } from '../Service/jsUtils';
import { actions } from '../Store/actions';
import { selectors } from '../Store/selectors';


export function LeagueCourt() {
    const dispatch = useDispatch();
    const navigation = useNavigation();

    const basketixId = useSelector(state => state.authentication.basketixId);
    const league = useSelector(state => state.league);
    const basketix = league.members[basketixId];
    const teamName = basketix.teamName
    const seasonId = league.season.seasonId;
    const basketBallCourtColor = useSelector(selectors.getBasketballCourt(seasonId, basketixId));
    const [backgroundColor, setBackgroundColor] = useState(basketBallCourtColor.backgroundColor);
    const [paintColor, setPaintColor] = useState(basketBallCourtColor.paintColor);
    const [linesColor, setLinesColor] = useState(basketBallCourtColor.linesColor);
    const [threePointsColor, setThreePointsColor] = useState(basketBallCourtColor.threePointsColor);
    const [courtColor, setCourtColor] = useState(basketBallCourtColor.courtColor);
    const [sidePaintColor, setSidePaintColor] = useState(basketBallCourtColor.sidePaintColor);
    const [linesWidth, setLinesWidth] = useState(basketBallCourtColor.linesWidth);
    const [courtPart, setCourtPart] = useState(undefined);
    const [tmpColor, setTmpColor] = useState(undefined);
    const [basketballCourtSelected, setBasketballCourtSelected] = useState(undefined);

    useEffect(() => {
        SeasonAPI.getCourt({ basketixId, seasonId }).then(data => {
            if (!data.error) {
                dispatch({ type: actions.SET_BASKETBALL_COURT_COLOR, value: {...data, basketixId, seasonId} });
            } else {
                console.error(data.error);
            }
        }).catch(err => {
            console.error(err);
        })
    }, [])

    const onColorChange = (c, terrain, basketballCourt) => {
        setTmpColor(c)
        setCourtPart(terrain)
        setBasketballCourtSelected(basketballCourt)
    };

    const onColorChangeComplete = (c) => {
        setTmpColor(c)
        if (courtPart =="backgroundColor") {
            setBackgroundColor(c);
        }
        if (courtPart =="paintColor") {
            setPaintColor(c)
        }
        if (courtPart =="linesColor") {
            setLinesColor(c)
        }
        if (courtPart =="courtColor") {
            setCourtColor(c)
        }
        if (courtPart =="threePointsColor") {
            setThreePointsColor(c)
        }
        if (courtPart =="sidePaintColor") {
            setSidePaintColor(c)
        }
    };

    const onSlidingComplete = (value) => {
        setLinesWidth(value);
    };

    const onPressSave = () => {
        const color = {
            backgroundColor: backgroundColor,
            courtColor: courtColor,
            paintColor: paintColor,
            threePointsColor: threePointsColor,
            linesColor: linesColor,
            sidePaintColor: sidePaintColor,
            linesWidth: linesWidth,
        };
        dispatch({ type: actions.SET_BASKETBALL_COURT_COLOR, value: {...color, basketixId, seasonId} });
        SeasonAPI.setCourt(color, seasonId, basketixId).then(data => {
            if (! data.error) {
                showMessage({
                    message: strings('editCourt.saved'),
                    type: 'success',
                    icon: 'success',
                });
                navigation.goBack();
            } else {
                console.log(data.error);
                showMessage({
                    message: data.error,
                    type: 'danger',
                    icon: 'danger',
                });
            }
        }).catch(err => {
            console.log(err);
        })
        setTmpColor(undefined);
    };

    const colorPicker = tmpColor ? (
        <View style={[globalStyles.flex, globalStyles.alignCenter]}>
            <Title3 style={[styles.styleText, {margin:4}]}>{strings('editCourt.colorTitle', {basketballCourtSelected:basketballCourtSelected})} </Title3>
            <View style={styles.colorPicker}>
                {<ColorPicker
                    color={tmpColor}
                    swatchesOnly={false}
                    autoResetSlider={true}
                    onColorChangeComplete={onColorChangeComplete}
                    thumbSize={20}
                    sliderSize={40}
                    noSnap={false}
                    row={false}
                    swatchesLast={true}
                    swatches={false}
                    discrete={true}
                    discreteLength={10}
                    gapSize={4}
                />}
                <View style={[styles.colorPickerButtons, globalStyles.flexRow]}>
                    <BtxButton title={strings('editCourt.close')} onPress={() => setTmpColor(undefined)} />
                </View>
                </View>
            </View>) : (<></>)

    return (
        <ScrollViewMainContainer noPadding={true}>
            <BasketballCourt
                teamName={teamName}
                teamNameColor={getOppositeColor(backgroundColor)}
                backgroundColor={backgroundColor}
                courtColor={courtColor}
                paintColor={paintColor}
                threePointsColor={threePointsColor}
                linesColor={linesColor}
                sidePaintColor={sidePaintColor}
                linesWidth= {linesWidth}
            ></BasketballCourt>
            <MainContainer>
                <View style={[globalStyles.flexRow]}>
                    <View style={[globalStyles.flex]}>
                        {colorPicker}
                        <View style={[{flexDirection: "column"}]}>
                            <View style={[{flexDirection: "row", flex:1}]}>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: backgroundColor}, globalStyles.shadow]} onPress={() => onColorChange(backgroundColor, "backgroundColor", strings('editCourt.fromEdgePitch') )}>
                                    <Title4 style={[styles.styleText, {color:getOppositeColor(backgroundColor)}]}>{strings('editCourt.edgePitch')}</Title4>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: courtColor}, globalStyles.shadow]} onPress={() => onColorChange(courtColor,"courtColor", strings('editCourt.fromCourt'))}>
                                    <Title4 style={[styles.styleText, {color:getOppositeColor(courtColor)}]}> {strings('editCourt.court')}</Title4>
                                </TouchableOpacity>
                            </View>
                            <View style={[{flexDirection: "row", flex:1}]}>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: paintColor}, globalStyles.shadow]} onPress={() => onColorChange(paintColor, "paintColor", strings('editCourt.fromRacket'))}>
                                    <Title4 style={[styles.styleText, {color:getOppositeColor(paintColor)}]}>{strings('editCourt.racket')}</Title4>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: linesColor}, globalStyles.shadow]} onPress={() => onColorChange(linesColor, "linesColor", strings('editCourt.fromLine'))}>
                                    <Title4 style={[styles.styleText, {color:getOppositeColor(linesColor)}]}>{strings('editCourt.line')}</Title4>
                                </TouchableOpacity>
                            </View>
                            <View style={[{flexDirection: "row", flex:1}]}>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: threePointsColor}, globalStyles.shadow]} onPress={() => onColorChange(threePointsColor, "threePointsColor", strings('editCourt.fromMidRange'))}>
                                    <Title4 style={[styles.styleText, {color:getOppositeColor(threePointsColor)}]}>{strings('editCourt.midRange')}</Title4>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: sidePaintColor}, globalStyles.shadow]} onPress={() => onColorChange(sidePaintColor, "sidePaintColor", strings('editCourt.fromEdgeRacket'))}>
                                    <Title4 style={[styles.styleText, {color:getOppositeColor(sidePaintColor)}]}>{strings('editCourt.edgeRacket')}</Title4>
                                </TouchableOpacity>
                            </View>
                            <View>
                            <Paragraph style={ globalStyles.textInputLabel }>{strings('editCourt.lineThickness', {linesWidth:linesWidth})}</Paragraph>
                            <Slider
                                minimumValue={1}
                                maximumValue={15}
                                minimumTrackTintColor={primaryColor}
                                maximumTractTintColor={secondaryColor}
                                step={1}
                                value={linesWidth}
                                onSlidingComplete={value => onSlidingComplete(value)}
                                onValueChange={value => onSlidingComplete(value)}
                                thumbTintColor={primaryColor}
                            />
                            </View>
                            <View style={[styles.colorPickerButtons, globalStyles.flexRow]}>
                                <BtxButton title={strings('editCourt.save')} onPress={onPressSave} />
                            </View>
                        </View>
                    </View>
                </View>
            </MainContainer>
        </ScrollViewMainContainer>
    );
}

const styles = StyleSheet.create({
    colorSelector: {
        justifyContent: 'center',
        borderRadius: 4,
        borderColor: black,
        borderWidth: 1,
        padding: 8,
        marginVertical: 8,
        flex:1,
        marginHorizontal: 8
    },
    colorPickerContainer: {
        flex: 1,
    },
    colorPickerButtons: {
        marginVertical: 14,
        justifyContent: 'center',
    },
    colorPicker: {
        width: '66%',
    },
    textColorSelector: {
        textAlign: 'center',
        flex:1,
    },
    styleText: {
        textAlign: 'center',
        flex: 1,
    },
});
