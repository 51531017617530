import React from 'react';
import { StyleSheet, View} from 'react-native';
import { useSelector } from 'react-redux';
import { toLocaleHour, toLocaleDateShort, strings } from '../locales/i18n';
import { globalStyles } from '../Styles/globals';
import { CardDetailsCell } from './CardDetailsCell';
import { CardDetailsRow } from './CardDetailsRow';

export function FreeAgencyHistoryDetails({ bids, minCost }) {
    const basketixId = useSelector(state => state.authentication.basketixId);
    const leagueMembers = useSelector(state => state.league.members);

    return (
        <View style={[globalStyles.flex, globalStyles.flexColumn]}>
            {
                bids.map((bid, index) => {
                    const basketixName = bid.basketix.basketixId === basketixId ? strings('globals.you') : leagueMembers[bid.basketix.basketixId]?.name;
                    const formattedDiffMinCost = bid.cost - minCost >= 0 ? `+${bid.cost - minCost} M$` : `-${Math.abs(bid.cost - minCost)} M$`;
                    const formattedDate = toLocaleDateShort(bid.hourUTC);
                    const formattedHour = toLocaleHour(bid.hourUTC);

                    return (<FreeAgencyHistoryItem
                        key={bid.basketix.basketixId}
                        teamName={bid.basketix.name}
                        basketixName={basketixName}
                        date={formattedDate}
                        hour={formattedHour}
                        cost={`${bid.cost} M$`}
                        diffMinCost={formattedDiffMinCost}
                        isLastItem={index === bids.length - 1}
                    />)
                })
            }
        </View>
    )
}

function FreeAgencyHistoryItem({ teamName, basketixName, date, hour, cost, diffMinCost, isLastItem }) {
    const itemMarginStyle = isLastItem ? undefined : styles.itemMargin;

    return (
        <CardDetailsRow style={itemMarginStyle}>
            <CardDetailsCell
                upperValue={teamName}
                lowerValue={basketixName}
                style={styles.leftCell}
            />
            <CardDetailsCell
                upperValue={date}
                lowerValue={hour}
                style={styles.middleCell}
            />
            <CardDetailsCell
                upperValue={cost}
                lowerValue={diffMinCost}
                style={styles.rightCell}
            />
        </CardDetailsRow>
    )
}

const styles = StyleSheet.create({
    itemMargin: {
        marginBottom: 8,
    },
    leftCell: {
        flex: 3,
    },
    middleCell: {
        flex: 1,
    },
    rightCell: {
        flex: 1,
        alignItems: 'flex-end',
    },
});
