import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { UsersAPI } from '../API/Users';
import { RequiredTextInput } from '../Components/RequiredTextInput';
import BtxButton from '../Components/BtxButton';
import { globalStyles } from '../Styles/globals';
import {Formik} from 'formik';
import * as yup from 'yup';
import { strings } from '../locales/i18n';
import { sizeXL, sizeXXL } from '../Styles/text';
import { black, primaryColor, secondaryColor, thirdColor, transparent, white } from '../Styles/colors';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph, TextError, Title1 } from '../Components/Texts';

export default function ConfirmedSignOn({ route, navigation }) {
    const [email] = useState(route.params.email);
    const [confirmationMsg, setConfirmationMsg] =useState('') ;
    const [code] =useState(route.params.confirmationCode) ;

    const reviewSchema = yup.object({
      Confirmation : yup.string()
        .required(strings('confirmedSignOn.confirmedSignonError'))
    })

    const handleSubmit = (formValues) => {
      if (formValues) {
          const verifyUserArgs = {
            confirmationCode: formValues.Confirmation,
            email: email,
          };
          UsersAPI.verify(verifyUserArgs).then(data => {
            if (! data.error) {
              navigation.navigate(strings("navigation.LoginHome"), {email});
            } else {
              if (typeof error != 'undefined')
              {
                setConfirmationMsg(error.errorMessage)
              }
              else {
                setConfirmationMsg("Error")
              }
            }
          })
      }
    };

    const goToWelcome = () => {
      navigation.navigate(strings('navigation.Welcome'));
    };

    const form = (
      <View>
        <View style={ styles.confirmationCodeContainer }>
          <Paragraph style={ styles.confirmationCodeLabel }>{strings("confirmedSignOn.confirmationCode")}</Paragraph>
          <Paragraph style={ styles.confirmationCode }>{ code }</Paragraph>
        </View>

        <Formik
          initialValues={ {Confirmation:''} }
          validationSchema={ reviewSchema }
          onSubmit={(values)=> { handleSubmit(values) }}
        >
          { (formikprops) => (
            <View>
              <Paragraph style={ [globalStyles.textInputLabel, styles.whiteColor] }>{strings('confirmedSignOn.confirmedLabel')}</Paragraph>
              <RequiredTextInput
                style={ [globalStyles.textInput, {color: white}] }
                placeholder={ strings('confirmedSignOn.confirmedPlaceholder') }
                onChangeText={ formikprops.handleChange('Confirmation') }
                value={ formikprops.values.Confirmation }
                onBlur={ formikprops.handleBlur('Confirmation') }
              />
              <TextError>{ formikprops.touched.Confirmation && formikprops.errors.Confirmation }</TextError>
              <TextError>{ confirmationMsg }</TextError>
              <View style={ styles.buttonContainer }>
                <BtxButton
                  title={ strings('confirmedSignOn.confirmedSignonButton') }
                  onPress={ formikprops.handleSubmit }
                  height={ 40 }
                />
                <BtxButton
                  onPress={ goToWelcome }
                  title={ strings('globals.cancel') }
                  backgroundColor={ transparent }
                  color={ white }
                  maxHeight={58}
                />
              </View>
            </View>
          )}
        </Formik>
      </View>
    )

    return (
      <ScrollViewMainContainer backgroundColor={black}>
        <View style={ styles.basketix }>
            <Title1 style={styles.title}>{ strings('login.basketixLabel') }</Title1>
            <View style={ styles.bottomBanner }>
                <View style={ styles.boxColorOne } />
                <View style={ styles.boxColorTwo } />
                <View style={ styles.boxColorThree } />
            </View>
            <Paragraph style={ styles.text }>{ strings('login.thisIsWhatLabel') }</Paragraph>
        </View>
        <View style={ styles.basketixFooter }>
            { form }
        </View>
      </ScrollViewMainContainer>
    )
}

const styles = StyleSheet.create({
  confirmationCodeContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 24,
    color: white,
  },
  confirmationCode: {
    fontSize: sizeXXL,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: white,
  },
  confirmationCodeLabel: {
    fontSize: sizeXL,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: white,
  },
  textinput: {
    marginLeft: 5,
    marginRight: 5,
    height: 50,
    borderColor: white,
    borderWidth: 1,
    paddingLeft: 5,
  },

  buttonContainer: {
    marginTop: 32,
},
container: {
    justifyContent: 'center',
    backgroundColor: black,
    flex: 1,
},
title: {
    color: white,
    textAlign: 'center',
    fontSize: 48,
},
text: {
    color: white,
    textAlign: 'center',
},
textInput: {
    borderColor: thirdColor,
    color: thirdColor,
    fontWeight: "bold",
},
basketix: {
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 42,
    marginBottom: 16,
},
basketixFooter: {
    flex: 2,
    justifyContent: 'flex-start',
},
bottomBanner: {
    height: 2,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 42,
    marginVertical: 8,
},
boxColorOne: {
    backgroundColor: secondaryColor,
    flex: 1,
},
boxColorTwo: {
    backgroundColor: primaryColor,
    flex: 1,
},
boxColorThree: {
    backgroundColor: thirdColor,
    flex: 1,
},
whiteColor: {
  color: white
},
});
