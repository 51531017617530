import React from 'react';
import { StyleSheet, View, TouchableOpacity} from 'react-native';
import { grey, primaryColor, secondaryColor, white } from '../Styles/colors';
import { sizeS } from '../Styles/text';
import Circle from './Circle';
import { Paragraph } from './Texts';
import { strings } from '../locales/i18n';
import { BtxActivityIndicator } from './BtxActivityIndicator';
import { globalStyles } from '../Styles/globals';

export function PointGuard({ name, games, evaluation, isEmpty, isSelectable, onPress, isLoading, style }) {
    return (
        <Player
            position={strings('positions.pointGuard')}
            name={name}
            games={games}
            evaluation={evaluation}
            isEmpty={isEmpty}
            isSelectable={isSelectable}
            onPress={onPress}
            isLoading={isLoading}
            style={{position: 'absolute', bottom: '20%', right: '6%'}}
        />
    );
}

export function ShootingGuard({ name, games, evaluation, isEmpty, isSelectable, onPress, isLoading, style }) {
    return (
        <Player
            position={strings('positions.shootingGuard')}
            name={name}
            games={games}
            evaluation={evaluation}
            isEmpty={isEmpty}
            isSelectable={isSelectable}
            onPress={onPress}
            isLoading={isLoading}
            style={{position: 'absolute', bottom: '25%', left: '8%'}}
        />
    );
}

export function SmallForward({ name, games, evaluation, isEmpty, isSelectable, onPress, isLoading, style }) {
    return (
        <Player
            position={strings('positions.smallForward')}
            name={name}
            games={games}
            evaluation={evaluation}
            isEmpty={isEmpty}
            isSelectable={isSelectable}
            onPress={onPress}
            isLoading={isLoading}
            style={{position: 'absolute', top: '40%', right: '5%'}}
        />
    );
}

export function PowerForward({ name, games, evaluation, isEmpty, isSelectable, onPress, isLoading, style }) {
    return (
        <Player
            position={strings('positions.powerForward')}
            name={name}
            games={games}
            evaluation={evaluation}
            isEmpty={isEmpty}
            isSelectable={isSelectable}
            onPress={onPress}
            isLoading={isLoading}
            style={{position: 'absolute', top: '35%', left: '3%'}}
        />
    );
}

export function Center({ name, games, evaluation, isEmpty, isSelectable, onPress, isLoading, style }) {
    return (
        <Player
            position={strings('positions.center')}
            name={name}
            games={games}
            evaluation={evaluation}
            isEmpty={isEmpty}
            isSelectable={isSelectable}
            onPress={onPress}
            isLoading={isLoading}
            style={{position: 'absolute', top: '15%'}}
        />
    );
}

export function Sub({ name, games, evaluation, isEmpty, isSelectable, onPress, isLoading, style }) {
    return (
        <Player
            position={"Remplaçant"}
            name={name}
            games={games}
            evaluation={evaluation}
            isEmpty={isEmpty}
            isSelectable={isSelectable}
            onPress={onPress}
            isLoading={isLoading}
        />
    );
}

function Player({ name, games, position, evaluation, isEmpty, isSelectable, onPress, isLoading, style }) {
    if (isLoading) {
        return (
            <TouchableOpacity style={[styles.playerContainer, style]} onPress={onPress} disabled={!onPress}>
                <View style={styles.position}>
                    <Paragraph style={[styles.positionText]}>{position}</Paragraph>
                </View>
                <BtxActivityIndicator size={'small'}/>
            </TouchableOpacity>
        )
    }

    if (isEmpty && isSelectable) {
        return (
            <TouchableOpacity style={[styles.playerContainer, style]} onPress={onPress} disabled={!onPress}>
                <View style={styles.position}>
                    <Paragraph style={[styles.positionText]}>{position}</Paragraph>
                </View>
                <View style={styles.addPlayer}>
                    <Paragraph style={[styles.addPlayerText]}>{strings('playersComponent.add')}</Paragraph>
                </View>
            </TouchableOpacity>
        )
    }

    const nameJSX = (
        <View style={styles.name}>
            <Paragraph style={[styles.playersText]} numberOfLines={1}>{name}</Paragraph>
        </View>
    );
    const emptyNameJSX = (
        <View style={styles.name}>
            <Paragraph style={[styles.playersText]} numberOfLines={1}>---</Paragraph>
        </View>
    );
    const gamesJSX = (
        <View style={styles.games}>
            {
                games?.map((game, index) => {
                    const isLastGame = index + 1 === games.length;
                    const isGameFinished = game.isFinished;
                    const gameStyle = {
                        backgroundColor: isGameFinished ? primaryColor : grey,
                        marginRight: !isLastGame ? 4 : 0
                    };
                    return (<Circle size={5} shapeStyle={gameStyle} />);
                })
            }
        </View>
    );
    const scoreJSX = (
        <View style={styles.eval}>
            <Paragraph style={[styles.evalText]}>{evaluation} {strings('playersComponent.totalEvaluation')}</Paragraph>
        </View>
    );

    const displayedNameJSX = isEmpty ? emptyNameJSX : nameJSX;
    const displayedGamesJSX = isEmpty ? (<></>) : gamesJSX;
    const displayedScoreJSX = isSelectable ? (<></>) : scoreJSX;

    return (
        <TouchableOpacity style={[styles.playerContainer, globalStyles.shadow, style]} onPress={onPress} disabled={!onPress}>
            <View style={styles.position}>
                <Paragraph style={[styles.positionText]}>{position}</Paragraph>
            </View>
            {displayedNameJSX}
            {displayedGamesJSX}
            {displayedScoreJSX}
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    playerContainer: {
        // flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 8,
        paddingTop: 2,
        paddingBottom: 4,
        borderRadius: 4,
        minWidth: 150,
        backgroundColor: white,
    },
    position: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingBottom: 4,
    },
    name: {
        paddingBottom: 2,
    },
    games: {
        flexDirection: 'row',
        paddingBottom: 4,
    },
    eval: {},
    addPlayer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    positionText: {
        color: grey,
        fontSize: sizeS,
        textTransform: 'capitalize',
        fontStyle: 'italic',
    },
    playersText: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: sizeS,
        maxWidth: 125
    },
    evalText: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: sizeS,
    },
    addPlayerText: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: secondaryColor,
    },
    icon: {
        marginRight: 4,
    },
});
