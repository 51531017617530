import React from 'react';
import { StyleSheet, View } from 'react-native';
import { grey } from '../Styles/colors';
import { Small } from './Texts';

export function Legend({ children }) {
    return (
        <View style={styles.legend}>
            <Small style={styles.legendText}>{children}</Small>
        </View>
    );
}

const styles = StyleSheet.create({
    legend: {
        marginVertical: 4,
    },
    legendText: {
        color: grey,
    }
});
