import React from 'react';
import { StyleSheet, View } from 'react-native';
import { globalStyles } from '../Styles/globals';

export function CardDetailsRow({ children, style }) {
    return (
        <View style={[globalStyles.flex, globalStyles.flexRow, styles.item, style]}>
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    item: {
        justifyContent: 'space-between',
        paddingVertical: 4,
    },
});
