import React, { useState } from 'react';
import { View, StyleSheet} from 'react-native';
import { useSelector, useDispatch } from 'react-redux'
import { primaryColor,thirdColor, black } from '../Styles/colors';
import RegisteredTeam from '../Components/RegisteredTeam';
import BtxButton from '../Components/BtxButton';
import { sizeXXL } from '../Styles/text';
import { SeasonAPI } from '../API/Season';
import { strings } from '../locales/i18n';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph, Title1, Title2 } from '../Components/Texts';
import { SeasonSettingsForm } from '../Components/SeasonSettingsForm';
import { actions } from '../Store/actions';

export default function LeagueWaiting({ navigation }) {
    const dispatch = useDispatch();

    const league = useSelector(state => state.league);
    const basketixId = useSelector(state => state.authentication.basketixId);
    const seasonSettings = useSelector(state => state.seasonSettings.data);
    const membersLeague = Object.values(league.members);

    const [isSeasonCreating, setIsSeasonCreating] = useState(false);
    const [displaySeasonFormCreation, setDisplaySeasonFormCreation] = useState(false);

    const seasonCreation = async (values) => {
        const data = {
            leagueId: league.leagueId,
            draftType: seasonSettings.draftType,
            isDraftReverse: seasonSettings.isDraftReverse,
            nDraftRound: seasonSettings.nDraftRound,
            nWeek: seasonSettings.nWeek,
        };
        setIsSeasonCreating(true);
        SeasonAPI.create(data).then(data => {
            if (!data.error) {
                dispatch({ type: 'UPDATE_APP_LOADER', value: { leagueIdToLoad: data.leagueId, loadingId: Date.now() } });
                dispatch({ type: actions.seasonSettings.RESET_SEASON_SETTING });
                navigation.navigate('LeagueLoadingStack');
                setIsSeasonCreating(false);
            } else {
                console.log(data.error);
                setIsSeasonCreating(false);
            }
        }).catch(err => {
            console.log(err);
            setIsSeasonCreating(false);
        })
    };

    const displayCreationSeason = () => {
        if (league.creatorId === basketixId && !displaySeasonFormCreation) {
            return (
                <View style={[styles.actionContainer]}>
                    <BtxButton
                        onPress={ () => { setDisplaySeasonFormCreation(true) } }
                        title={strings('LeagueWaiting.seasonSettings')}
                        backgroundColor={primaryColor}
                        color={black}
                        height={52}
                    />
                </View>
            )
        } else {
            return (
                <View style={[styles.actionContainer]}>
                    <Paragraph style={{textAlign:'center'}}>{strings('LeagueWaiting.wantToBegin')} </Paragraph>
                    <Paragraph style={{textAlign:'center'}}>{strings('LeagueWaiting.contactCreator', {name:league.members[league.creatorId].name} )}</Paragraph>
                </View>
            )
        }
    }

    const displayLeagueInformation = () => {
        if (league.creatorId === basketixId && displaySeasonFormCreation) {
            return (
                <View>
                    <SeasonSettingsForm />
                    <BtxButton
                        onPress={ () => { seasonCreation(league) } }
                        title={ strings('LeagueWaiting.createSeason') }
                        backgroundColor={ primaryColor }
                        color={ black }
                        height={ 52 }
                        loading={ isSeasonCreating }
                    />
                </View>
            );
        }

        return (
            <View>
                <Paragraph style={styles.downList}>{strings('LeagueWaiting.inviteYourFriends')}</Paragraph>
                <Title1 style={styles.codeLeague}>{league.leagueId}</Title1>
                <Title2>{strings('LeagueWaiting.registerTeam')}</Title2>
                {
                    membersLeague.map(member => {
                        return (
                            <View key={member.basketixId} style={[styles.memberItem]} >
                                <RegisteredTeam
                                    team={ member.teamName }
                                    playerName={ member.name }
                                />
                            </View>
                        )
                    })
                }
                {displayCreationSeason()}
            </View>
        )
    };

    return (
        <ScrollViewMainContainer>
            <Title1 style={styles.styleCenter}>{ league.leagueName }</Title1>
            {displayLeagueInformation()}
        </ScrollViewMainContainer>
    )
};
const styles = StyleSheet.create({
    downList: {
        marginTop: 25,
    },
    codeLeague: {
        textAlign: 'center',
        padding: 15,
        marginHorizontal: 65,
        marginVertical: 30,
        fontSize: sizeXXL,
        borderWidth: 1,
        borderColor: thirdColor,
        borderRadius: 8,
        color: thirdColor,
        textTransform: undefined,
    },
    styleCenter:{
        textAlign:"center"
    },
    memberItem: {
        paddingVertical: 5,
        marginTop: 5,
    },
    actionContainer: {
        marginTop: 60,
    },
})

