import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import Swiper from 'react-native-web-swiper';
import { strings } from '../locales/i18n';
import BtxButton from '../Components/BtxButton';
import { primaryColor, secondaryColor, thirdColor, white, black , grey, transparent, backgroundColor } from '../Styles/colors';
import Slide from '../Components/Slide';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph } from '../Components/Texts';


export default function Welcome({route, navigation}) {
    const slidesData = [
        {
            id: 1,
            title: 'Crée une ligue',
            subtitle: 'étape 1',
            description: `N’hésite plus : inscris-toi pour suivre la NBA plus intensément et montre à tes potes que tu ferais un meilleur manager qu'eux ! Créer une ligue selon tes goûts : nombre de tour de draft, type de draft, durée de la saison, etc...`
        },
        {
            id: 2,
            title: 'Invite tes potes',
            subtitle: 'étape 2',
            description: `Une fois que la ligue est créée, tu obtiens un code que tu peux envoyer à tes potes. Ils t'en seront à jamais reconnaissant de leur avoir fait découvrir Basketix ! Pour te rejoindre, ils n'ont besoin que de s’inscrire et du code de la ligue.`
        },
        {
            id: 3,
            title: 'Draft les joueurs de ton choix',
            subtitle: 'étape 3',
            description: `Une fois que tous tes potes inscrits, tu lances une saison et une draft commence ! Choisi les joueurs de ton choix en faisant attention de bien prendre au moins un joueur par poste, et en respectant le salary cap de 150 M$.`
        },
        {
            id: 4,
            title: 'Fais ton 5 majeur',
            subtitle: 'étape 4',
            description: `Chaque semaine, tu dois mettre en place un 5 majeur. Ils seront sur le parquet toute la semaine, du lundi au dimanche soir. A chaque match qu'ils feront, tu remportes des points.`
        },
        {
            id: 5,
            title: 'Comment gagne-t-on des points ? ',
            subtitle: 'étape 5',
            description: `A chaque fois qu'un des joueurs de ton 5 majeur joue un match, une évaluation est calculée et est ajoutée au score de ta semaine. On calcul cette évaluation en additionnant les actions positives (points marqués, passes décisives, rebonds, interceptions et contres) auxquelles on retire les actions négatives (tirs ratés, lancers francs ratés et pertes de balle).`
        },
        {
            id: 6,
            title: 'Félicitation pour ta victoire',
            subtitle: 'étape 6',
            description: `Une fois que tu as remporté la ligue tu peux en relancer une avec tes mêmes potes pour leur permettre de prendre leur revanche. Selon les paramètres choisis pour ta ligue, la prochaine draft prendra en compte ou non le classement de la ligue précédente.`
        }
    ];

    const goToSignOn = () => {
        navigation.navigate(strings('navigation.SignOn'));
    };

    const goToLogin = () => {
        navigation.navigate(strings('navigation.LoginHome'));
    };


    const goToRules = () => {
        navigation.navigate(strings('navigation.Rules'));
    };

    return (
        <ScrollViewMainContainer backgroundColor={black}>
            <View style={{flex: 1}}>
                <View style={styles.headerContainer}>
                    <View style={styles.headerContentContainer}>
                        <Paragraph style={styles.headerText}>Basketix</Paragraph>
                        <View style={ [styles.bottomBanner, {marginHorizontal: 0}] }>
                            <View key='color1' style={ styles.boxColorOne } />
                            <View key='color2' style={ styles.boxColorTwo } />
                            <View key='color3' style={ styles.boxColorThree } />
                        </View>
                    </View>
                </View>
                <View style={styles.swiperContainer}>
                    <Swiper
                        vertical={false}
                        loop={false}
                        controlsProps={{
                            PrevComponent: ({ onPress }) => (
                                <TouchableOpacity onPress={onPress}>
                                    <MaterialIcons name="navigate-before" size={24} color={primaryColor} />
                                </TouchableOpacity>
                            ),
                            NextComponent: ({ onPress }) => (
                                <TouchableOpacity onPress={onPress}>
                                    <MaterialIcons name="navigate-next" size={24} color={primaryColor} />
                                </TouchableOpacity>
                            ),
                            dotsTouchable: true,
                            dotsPos: 'bottom',
                            prevPos: 'left',
                            prevTitleStyle: {color: primaryColor},
                            nextPos: 'right',
                            nextTitleStyle: {color: primaryColor},
                            dotActiveStyle: { backgroundColor: secondaryColor },
                            dotProps: {badgeStyle: {backgroundColor: grey}}
                        }}
                    >
                        {
                            slidesData.map(slide => (
                                <Slide
                                    key={slide.id}
                                    id={slide.id}
                                    title={slide.title}
                                    subtitle={slide.subtitle}
                                    description={slide.description}
                                />
                            ))
                        }
                    </Swiper>
                <View style={{marginTop:10, marginBottom:10}}>
                    <BtxButton
                        onPress={ goToRules }
                        title={ strings('login.rules') }
                        backgroundColor={ transparent }
                        color={ white }
                        maxHeight={65}
                    />
                </View>
                </View>
                <View style={styles.buttonsContainer}>
                    <View style={styles.mainButtonContainer}>
                        <BtxButton
                            onPress={ goToSignOn }
                            title={ strings('login.signonButton') }
                            backgroundColor={ secondaryColor }
                            color={ black }
                            width={'100%'}
                            height={'100%'}
                            maxHeight={58}
                        />
                    </View>
                    <View style={styles.secondaryButtonContainer}>
                        <BtxButton
                            onPress={ goToLogin }
                            title={  strings('login.haveAccount') }
                            backgroundColor={ transparent }
                            color={ white }
                            width={'100%'}
                            height={'100%'}
                            maxHeight={58}
                        />
                    </View>
                </View>
            </View>
        </ScrollViewMainContainer>
    )
};

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        backgroundColor: black,
    },
    bottomBanner: {
        height: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        marginHorizontal: 42,
        marginVertical: 8,
    },
    boxColorOne: {
        backgroundColor: secondaryColor,
        flex: 1,
    },
    boxColorTwo: {
        backgroundColor: primaryColor,
        flex: 1,
    },
    boxColorThree: {
        backgroundColor: thirdColor,
        flex: 1,
    },
    swiperContainer: {
        flex: 9,
        paddingBottom: 25,
    },
    buttonsContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    headerContainer: {
        flex: 1,
        alignItems: 'flex-start',
    },
    headerContentContainer: {
        marginHorizontal: 60,
        width: 200,
    },
    headerText: {
        color: white,
        fontSize: 35,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    secondaryButtonContainer: {
        flex: 1
    },
    mainButtonContainer: {
        flex: 2
    },
});
