import { actions } from '../actions';

const initialState = {
    leagues: [],
    email: undefined,
    name: undefined,
    isLoadingBasketixInfo: false,
};

function user(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case actions.SET_USER_INFOS:
            nextState = { ...state };
            nextState.leagues = action.value.leagues;
            nextState.email = action.value.email;
            nextState.name = action.value.name;
            nextState.isLoadingBasketixInfo = false;
            return nextState || state;

        case actions.SET_USER_IS_LOADING:
                nextState = { ...state };
                nextState.isLoadingBasketixInfo = action.value;
                return nextState || state;

        case actions.UPDATE_USER_LEAGUE:
            nextState = { ...state };
            let leagues = [ ...state.leagues ];
            const leagueIndex = leagues.map(l => l.leagueId).indexOf(action.value.leagueId);
            if (leagueIndex !== -1) {
                leagues[leagueIndex] = {
                    ...leagues[leagueIndex],
                    ...action.value.league,
                }
            } else {
                leagues.push(action.value.league);
            }
            nextState.leagues = leagues;
            return nextState || state;
        default:
            return state;
    }
}

export default user;
