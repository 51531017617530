import React from 'react';
import {TouchableOpacity, View, FlatList, StyleSheet } from 'react-native';
import { primaryColor } from '../Styles/colors';
import { strings } from '../locales/i18n';
import { Title3, Title5 } from './Texts';
import { bold } from '../Styles/text';


export default function WeekPicker(props) {
    const {weeks, selectedWeek, onPress} = props;

    const stylizedWeeks = weeks.map(week => ({
        ...week,
        style: week.weekId === selectedWeek.weekId ? styles.weekPickerActive : undefined,
    }));

    return (
        <View style={styles.container}>
            <FlatList
                data={stylizedWeeks}
                keyExtractor={(item) => item.weekId}
                renderItem={ ({item}) => (
                    <TouchableOpacity onPress={ () => onPress(item) } style={styles.weekPickerItem}>
                        <Title5 style={styles.weekPicker, item.style}>
                            {`${strings('globals.Week')} ${item.weekNumber}` }
                        </Title5>
                    </TouchableOpacity>
                )}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginBottom: 8,
    },
    weekPickerItem: {
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 25,
        marginRight: 10,
    },
    weekPicker: {},
    weekPickerActive: {
        color: primaryColor,
        fontWeight: bold,
    },
});
