import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { strings, toLocaleDate } from '../locales/i18n';
import { rankingSeason, rankingSeasonWeek } from '../API/BasketixRegularSeasons';
import { sizeM } from '../Styles/text';
import WeekPicker from '../Components/WeekPicker';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { Live } from '../Components/Live';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph, Title1, Title3 } from '../Components/Texts';
import { RankingWeekTable } from '../Components/RankingWeekTable';
import { RankingTable } from '../Components/RankingTable';

export default function LeagueRanking({ navigation }) {
    const league = useSelector(state => state.league);
    const [weeks, setWeeks] = useState([]);
    const [selectedWeek, setSelectedWeek] = useState(undefined);
    const [isLoadingRanking, setIsLoadingRanking] = useState(false);
    const [isLoadingRankingWeek, setIsLoadingRankingWeek] = useState(false);
    const [ranking, setRanking] = useState(undefined);
    const [rankingWeek, setRankingWeek] = useState(undefined);

    useEffect(() => {
        const startedWeeks = league.season.regularSeason.weeks.filter(week => week.isStarted);
        setWeeks(startedWeeks);
        if (startedWeeks.length > 0) {
            setSelectedWeek(startedWeeks[startedWeeks.length-1]);
        }
        setIsLoadingRanking(true);
        rankingSeason(league.season.seasonId).then( async (data) => {
            if (! data.error) {
                setRanking(data);
                setIsLoadingRanking(false);
            } else {
                console.log(data.error);
                setIsLoadingRanking(false);
            }
        })
    }, [league.leagueId]);

    //useEffect for Week
    useEffect(() => {
        if (selectedWeek != undefined) {
            setIsLoadingRankingWeek(true);
            rankingSeasonWeek(league.season.seasonId, selectedWeek.weekId).then( async (data) => {
                if (! data.error) {
                    setRankingWeek(data);
                    setIsLoadingRankingWeek(false);
                } else {
                    console.log(data.error);
                    setIsLoadingRankingWeek(false);
                }
            })
        }
    }, [selectedWeek, league.leagueId]);

    const displayGeneralRanking = () => {
        if (isLoadingRanking) {
            return (<BtxActivityIndicator size='small' />)
        }

        if (!ranking) {
            return (<BtxActivityIndicator size='small' />)
        }

        return (
            <RankingTable
                columns={ranking.columns}
                rows={ranking.rows}
            />
        )
    }

    const displayWeekRanking = () => {
        if (isLoadingRankingWeek) {
            return (<BtxActivityIndicator size='small' />)
        }

        if (!rankingWeek) {
            return (<BtxActivityIndicator size='small' />)
        }

        return (
            <RankingWeekTable
                columns={rankingWeek.columns}
                rows={rankingWeek.rows}
                context={rankingWeek.context}
                navigation={navigation}
            />
        )
    }

    const displayWeekDate = () => {
        if (!isLoadingRankingWeek) {
            return(
                <Title3>{
                    strings('leagueRanking.weekDates', {
                        date1: toLocaleDate(selectedWeek.dates[0]),
                        date2: toLocaleDate(selectedWeek.dates[selectedWeek.dates.length-1])
                    })
                }</Title3>
            )
        }
    }

    const displayWeekScreen = () => {
        if (selectedWeek !== undefined) {
            return(
                <View>
                    <View style={styles.alignWeek}>
                        <WeekPicker
                            weeks={weeks}
                            selectedWeek={selectedWeek}
                            onPress={ setSelectedWeek }
                        />
                        <View style={styles.weekContext}>
                            <View style={styles.weekDates}>
                                {displayWeekDate()}
                            </View>
                            <View style={styles.weekLiveContainer}>
                                <Live isActive={ !isLoadingRankingWeek && rankingWeek?.context.isLive }/>
                            </View>
                        </View>
                    </View>
                    { displayWeekRanking() }
                </View>
            )
        }

        const beginSeasonDate = strings('leagueRanking.beginSeasonDate', {
            date: toLocaleDate(league.season.regularSeason.weeks[0].dates[0])
        });

        return (
            <View style={styles.alignWeek}>
                <Title3>{strings('leagueRanking.noWeekRanking')}</Title3>
                <Paragraph style={styles.noWeeks}>{beginSeasonDate}</Paragraph>
            </View>
        )
    }

    return (
        <ScrollViewMainContainer>
            <View style={styles.globalRanking}>
                <Title1>{strings('leagueRanking.generalRanking')}</Title1>
                { displayGeneralRanking() }
            </View>
            <View style={styles.weekRanking}>
                <Title1>{strings('leagueRanking.weekRanking')}</Title1>
                { displayWeekScreen() }
            </View>
        </ScrollViewMainContainer>
    )
};
const styles = StyleSheet.create({
    globalRanking: {
        marginBottom: 40,
    },
    weekRanking: {
    },
    rankingContainer: {
        marginTop: 4,
    },
    headColumnPts: {
        flex: 1,
        textAlign: 'center',
        fontSize: sizeM,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    weekContext: {
        flex: 1,
        flexDirection: 'row',
    },
    weekDates: {
        flex: 3,
    },
    noWeeks: {
        textAlign: 'center',
        marginTop:15,
    },
    weekLiveContainer: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    alignWeek: {
        flex: 1,
        flexDirection: 'column',
    },
    rankTitle:{
        flexDirection: 'row',
    },
})
