import Constants from "expo-constants";
import { defaultConfig } from "./config";

const env = Constants.manifest.releaseChannel;

// For web
let conf = defaultConfig;

// For mobile
if (__DEV__) {
  conf = require('./config.dev.json');
} else if (env === 'dev') {
  conf = require('./config.dev.json');
} else if (env === 'integ') {
  conf = require('./config.integ.json');
} else if (env === 'prod') {
  conf = require('./config.prod.json');
}

export const config = conf;
