import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import { strings } from '../locales/i18n';
import LeagueWaiting from '../Screens/LeagueWaiting';

const Stack = createStackNavigator();

export default function LeagueWaitingStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.LeagueWaiting")} component={LeagueWaiting} />
    </Stack.Navigator>
  );
};
