import React from 'react';
import { StyleSheet, View } from 'react-native';

export function Table({ children, style }) {
    return (
        <View style={[styles.table, style]}>
            {children}
        </View>
    );
}

const styles = StyleSheet.create({
    table: {
        flexDirection: 'columns',
    },
});
