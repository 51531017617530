import React from 'react';
import { StyleSheet, View, TouchableOpacity, Dimensions, Platform } from 'react-native';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { primaryColor, secondaryColor, thirdColor, white, black } from '../Styles/colors';
import BackButton from './backButton';
import { sizeXXL, sizeXS } from '../Styles/text';
import { Paragraph } from '../Components/Texts';
import { ScreenContainer } from '../Components/ScrollViewMainContainer';

const defaultHeaderTitle = 'BASKETIX';
const defaultOnPressTitleRoute = 'HomeStack';

export default function MyHeader({ title, onPressTitleRoute, drawerButton, style }) {
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();
    const navigationState = useNavigationState(state => state);
    const headerTitle = title || defaultHeaderTitle;

    const onPressTitle = () => {
        const route = onPressTitleRoute || defaultOnPressTitleRoute;

        navigation.reset({
            index: 0,
            routes: [{name: route}],
        });
    };

    const canGoBack = () => {
        return navigationState.index > 0;
    }

    const header = () => {
        if (canGoBack()) {
            return (
                <View key='backHeader' style={styles.headerMain}>
                    <BackButton/>
                </View>
            );
        }

        return (
            <View key='mainHeader' style={styles.headerMain}>
                {drawerButton}
                <View key='nameHeader' style={styles.headerName}>
                    <TouchableOpacity onPress={onPressTitle}>
                        <View style={styles.basketixContainer}>
                            <Paragraph style={styles.headerText}>{headerTitle}</Paragraph>
                            <View style={styles.betaContainer}>
                                <Paragraph style={styles.betaText}>Beta</Paragraph>
                            </View>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }

    const insetsStyle = {
        paddingTop: insets.top,
    };

    return (
        <View style={ [styles.header, style, insetsStyle] }>
            <ScreenContainer style={{backgroundColor: black, borderColor: black}}>
                { header() }
            </ScreenContainer>
            <View style={styles.bottomBanner}>
                <View style={styles.boxColorOne} />
                <View style={styles.boxColorTwo} />
                <View style={styles.boxColorThree} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    header: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 12,
        backgroundColor: black,
        minHeight: Platform.OS !== 'web' ? undefined : 60,
    },
    headerMain: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    headerName: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        color: white,
        position: 'absolute',
        right: 16,
    },
    backIcon: {
        color: white,
        position: 'absolute',
        left: 16,
    },
    bottomBanner: {
        width: Platform.OS !== 'web' ? Dimensions.get('screen').width : '100%',
        height: 4,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    boxColorOne: {
        backgroundColor: secondaryColor,
        flex: 1,
    },
    boxColorTwo: {
        backgroundColor: primaryColor,
        flex: 1,
    },
    boxColorThree: {
        backgroundColor: thirdColor,
        flex: 1,
    },
    headerText: {
        fontWeight: 'bold',
        fontSize: sizeXXL,
        color: white,
        letterSpacing: 2,
    },
    basketixContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    betaContainer: {
        backgroundColor: thirdColor,
        borderRadius: 4,
        padding: 2,
        marginLeft: 6,
        justifyContent: 'center',
        alignItems: 'center',
    },
    betaText: {
        color: white,
        fontSize: sizeXS,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: 2,
    },
});
