import I18n from 'ex-react-native-i18n'
import * as Localization from 'expo-localization';

const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const dateOptionsShort = { month: 'long', day: 'numeric' };
const hourOptions = { hour: 'numeric', minute: 'numeric' };

// Import all locales
import fr from './fr.json';
import en from './en.json';

I18n.locale = Localization.locale;

// Should the app fallback to English if user locale doesn't exists
I18n.fallbacks = true;

// Define the supported translations
I18n.translations = {
  fr,
  en,
};

// The method we'll use instead of a regular string
export function strings(name, params = {}) {
  return I18n.t(name, params);
};

export function toLocaleDate(date) {
  const dateObj = new Date(date)
  return dateObj.toLocaleDateString(I18n.currentLocale, dateOptions);
};

export function toLocaleDateShort(date) {
  const dateObj = new Date(date)
  return dateObj.toLocaleDateString(I18n.currentLocale, dateOptionsShort);
};

export function toLocaleHour(date) {
  const dateObj = new Date(date)
  return dateObj.toLocaleTimeString(I18n.currentLocale, hourOptions);
};

export const timezone = () => {
  return Localization.getCalendars()[0].timeZone;
};

export default I18n;
