import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import { strings } from '../locales/i18n';
import { LeagueOffice } from '../Screens/LeagueOffice';
import { LeagueCourt } from '../Screens/LeagueCourt';

const Stack = createStackNavigator();

export function LeagueOfficeStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.LeagueOffice")} component={LeagueOffice} />
      <Stack.Screen name={strings("navigation.LeagueCourt")} component={LeagueCourt} />
    </Stack.Navigator>
  );
};
