import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Paragraph } from './Texts';
import { Switch } from 'react-native-web';
import { alphaColor, grey, primaryColor } from '../Styles/colors';

export function SwitchBasketix({ label, labelHighlighted ,styleParagraph, value, onValueChange, activeColor, disabled }) {

    const color = activeColor ? activeColor : primaryColor 
    const labelStyle = labelHighlighted && value ? [styles.switchText, {color: color}]: {};

    return (
        <View style={styles.switchContainer}>
            <Paragraph style={ [styles.labelStyle, labelStyle] }>{ label }</Paragraph>
            <Switch
                trackColor={{ false: alphaColor(grey, 80), true: alphaColor(color, 80) }}
                ios_backgroundColor={ grey }
                onValueChange={ onValueChange }
                value={ value }
                activeThumbColor={ color }
                disabled={ disabled }
            />
        </View>
    )
};

const styles = StyleSheet.create({
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    }, 
    labelStyle: {
        marginRight:4,
    }, 
    labelHighlighted: {
        fontWeight: 'bold',
    }
})
