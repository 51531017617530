import { StyleSheet } from 'react-native';
import {primaryColor, backgroundColor, black, red, grey, transparent} from './colors';
import { bold, sizeL, sizeM, sizeS, sizeXL } from './text';

const PADDING = 12;

export const globalStyles = StyleSheet.create({
    mainContainer: {
        paddingHorizontal: PADDING,
    },
    screenContainerWithPadding: {
        padding: PADDING,
    },
    screenContainerNoPadding: {
        paddingVertical: PADDING,
        paddingHorizontal: 0,
    },
    mainScrollView: {
        backgroundColor: backgroundColor,
    },
    titleText1: {
        fontSize: sizeXL,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: black,
    },
    titleText2: {
        fontSize: sizeL,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: black,
    },
    titleText3: {
        fontSize: sizeL,
        fontWeight: bold,
        // textTransform: 'uppercase',
        color: grey,
    },
    titleText4: {
        fontSize: sizeM,
        fontWeight: 'bold',
        color: black,
    },
    titleText5: {
        fontSize: sizeM,
        fontWeight: bold,
        color: grey,
    },
    titleText6: {
        fontSize: sizeM,
        color: grey,
    },
    textInput: {
        marginTop: 4,
        marginBottom: 2,
        borderWidth: 1,
        borderColor: primaryColor,
        padding: 4,
        borderRadius: 8,
        color: black,
        height: 40,
    },
    textInputLabel: {
        fontSize: sizeS,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        paddingBottom: 4,
    },
    pickerInput: {
        flex: 1,
        color: black,
    },
    errorText : {
        color: red,
        fontWeight:'bold',
        marginBottom:0,
        marginTop:0,
        fontSize: sizeM,
    },
    header: {
        height: 72,
    },
    flex: {
        flex: 1,
    },
    flexRow: {
        flexDirection: 'row',
    },
    flexColumn: {
        flexDirection: 'column',
    },
    flexWrap: {
        flexWrap: 'wrap',
    },
    flexShrink: {
        flexShrink: 1,
    },
    width100: {
        width: '100%',
    },
    alignCenter: {
        alignItems: 'center',
    },
    alignFlexEnd: {
        alignItems: 'flex-end',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    shadow: {
        shadowColor: black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    bgTransparent: {
        backgroundColor: transparent,
    },
    yesNoButtonView: {
        flexDirection: 'row',
        alignItems:'center',
        justifyContent: 'center',
        margin: 8,
    },
    textAlignCenter:{
        textAlign: 'center',
    }
});
