import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Image} from 'react-native';
import { useSelector, useDispatch } from 'react-redux'
import { SeasonAPI } from '../API/Season';
import { SeasonSettingsForm } from '../Components/SeasonSettingsForm';
import BtxButton from '../Components/BtxButton';
import { rankingSeason } from '../API/BasketixRegularSeasons';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { strings } from '../locales/i18n';;
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Title2, Title1 } from '../Components/Texts';
import { actions } from '../Store/actions';


export default function LeagueFinished({ navigation }) {
    const dispatch = useDispatch();

    const league = useSelector(state => state.league);
    const seasonSettings = useSelector(state => state.seasonSettings.data);
    const isLoadingNextSeasonSettings = useSelector(state => state.seasonSettings.isLoadingNextSeasonSettings);

    const [isLoadingRanking, setIsLoadingRanking] = useState(false);
    const [ranking, setRanking] = useState({});
    const [isNewSeason, setIsNewSeason] = useState(false);
    const [isSeasonCreating, setIsSeasonCreating] = useState(false);

    useEffect(() => {
        setIsLoadingRanking(true);
        rankingSeason(league.season.seasonId).then( async (data) => {
            if (!data.error) {
                setRanking(data);
                setIsLoadingRanking(false);
            } else {
                console.log(data.error);
                setIsLoadingRanking(false);
            }
        })
    }, []);

    const createSeason = () => {
        const data = {
            leagueId: league.leagueId,
            draftType: seasonSettings.draftType,
            isDraftReverse: seasonSettings.isDraftReverse,
            nDraftRound: seasonSettings.nDraftRound,
            nWeek: seasonSettings.nWeek,
        };
        setIsSeasonCreating(true);
        SeasonAPI.create(data).then(data => {
            if (!data.error) {
                dispatch({ type: 'UPDATE_APP_LOADER', value: { leagueIdToLoad: data.leagueId, loadingId: Date.now() } });
                dispatch({ type: actions.seasonSettings.RESET_SEASON_SETTING });
                navigation.navigate('LeagueLoadingStack');
                setIsSeasonCreating(false);
            } else {
                console.log(data.error);
                setIsSeasonCreating(false);
            }
        }).catch(err => {
            console.log(err);
            setIsSeasonCreating(false);
        })
    }

    const displayWinner = () => {
        if (isLoadingRanking) {
            return (<BtxActivityIndicator size='small' />)
        } else {
            const winners = ranking.rows?.filter(user => user.rank === 1) || [];
            const tokens = {
                leagueName: league.leagueName,
                teamName: winners.map(winner => `${winner.userTeamName} (${winner.userName})`).join(', '),
            };
            const stringsKey = winners.length > 1 ? 'LeagueFinished.congratulationMultiWinners' :'LeagueFinished.congratulationOneWinner';
            const congratulationText = strings(stringsKey, tokens);

            return (
                <View style={styles.centerView}>
                    <Image
                        style={{
                            width: 356,
                            height: 400,
                        }}
                        source={require('../assets/img/trophy.png')}
                    />
                    <Title2 style={styles.congratulationTextStyle}>{congratulationText}</Title2>
                </View>
            );
        }
    }
    const displayFinishedSeason = () => {
        if (isNewSeason) {
            return (
                <View>
                    <SeasonSettingsForm seasonId={league.season.seasonId}/>
                    <View style={{marginTop:12}}>
                        <BtxButton
                            title={strings('LeagueFinished.createSeason')}
                            onPress={createSeason}
                            height={50}
                            loading={isSeasonCreating}
                            disabled={ isLoadingNextSeasonSettings }
                        />
                    </View>
                </View>
            )
        } else {
            return (
                <View style={styles.centerView}>
                    {displayWinner()}
                    <BtxButton
                        title={strings('LeagueFinished.beginNewSeason')}
                        onPress={ () => setIsNewSeason(true) }
                        height={50}
                        marginTop={54}
                        width={'33%'}
                    />
                </View>
            )
        }
    }

    return (
        <ScrollViewMainContainer>
            <Title1 style={styles.leagueNameText}>{ league.leagueName }</Title1>
            {displayFinishedSeason()}
        </ScrollViewMainContainer>
    )
};

const styles = StyleSheet.create({
    leagueNameText: {
        textAlign: 'center',
        marginVertical: 12,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 4,
        marginBottom: 12,
        justifyContent: 'center',
        alignItems: 'center'
    },
    centerView: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    congratulationTextStyle: {
        marginVertical: 12,
        textAlign: 'center',
    },
});

