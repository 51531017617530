import React from 'react';
import { StyleSheet } from 'react-native';
import { secondaryColor } from '../Styles/colors';
import { bold } from '../Styles/text';
import { Cell } from './Cell';
import { Column } from './Column';
import { Columns } from './Columns';
import { Legend } from './Legend';
import { NamesCell } from './NamesCell';
import { RankCell } from './RankCell';
import { Row } from './Row';
import { Rows } from './Rows';
import { Table } from './Table';
import { strings } from '../locales/i18n';

export function RankingWeekTable({columns, rows, context, navigation}) {

    const bestGameBonusKey = 'bestGameBonus';
    const bestGameBonusOwnerKey = 'bestGameBonusOwner';
    const bestPlayerBonusKey = 'bestPlayerBonus';
    const bestPlayerBonusOwnerKey = 'bestPlayerBonusOwner';
    const pointsKey = 'points';
    const rankKey = 'rank';
    const totalEvalKey = 'totalEval';
    const userNameKey = 'userName';
    const userTeamNameKey = 'userTeamName';
    const userIdKey = 'userId';

    const columnsById = columns.reduce((a, v) => ({ ...a, [v.id]: v}), {});

    const hasBestGameColumn = columnsById.hasOwnProperty(bestGameBonusKey);
    const hasBestPlayerColumn = columnsById.hasOwnProperty(bestPlayerBonusKey);

    const legendIds = [totalEvalKey];
    const rankColumnSize = hasBestGameColumn || hasBestPlayerColumn ? 3 : 1;
    const nameColumnSize = hasBestGameColumn || hasBestPlayerColumn ? 4 : 8;
    const pointColumnSize = hasBestGameColumn || hasBestPlayerColumn ? 2 : 4;
    const displayedColumns = [
        (<Column size={rankColumnSize}></Column>),
        (<Column size={nameColumnSize}></Column>),
        (<Column size={pointColumnSize}>{columnsById[totalEvalKey].abbreviation}</Column>),
    ];

    if (hasBestGameColumn) {
        legendIds.push(bestGameBonusKey);
        displayedColumns.push((<Column size={2}>{columnsById[bestGameBonusKey].abbreviation}</Column>));
    }

    if (hasBestPlayerColumn) {
        legendIds.push(bestPlayerBonusKey);
        displayedColumns.push((<Column size={2}>{columnsById[bestPlayerBonusKey].abbreviation}</Column>));
    }

    const legendText = legendIds.map(id => (`${columnsById[id].abbreviation} = ${columnsById[id].label}`)).join('\n');

    const goToTeam = (basketixId, weekId) => {
        if (navigation) {
            navigation.push(strings('navigation.LeagueTeam'), {
                basketixId,
                weekId,
            });
        }
    };

    return (
        <Table>
            <Columns>
                {displayedColumns}
            </Columns>
            <Rows>
                {
                    rows.map((row, index) => {
                        const cells = [
                            (<Cell size={rankColumnSize} onPress={() => goToTeam(row[userIdKey], context.weekId)}>
                                <RankCell rank={row[rankKey]} points={row[pointsKey]}/>
                            </Cell>),
                            (<Cell size={nameColumnSize} align={'flex-start'} onPress={() =>goToTeam(row[userIdKey], context.weekId)}>
                                <NamesCell teamName={row[userTeamNameKey]} userName={row[userNameKey]}/>
                            </Cell>),
                            (<Cell size={pointColumnSize} align={'center'}>{row[totalEvalKey]}</Cell>),
                        ];

                        if (hasBestGameColumn) {
                            const color = row[bestGameBonusOwnerKey] ? secondaryColor : undefined;
                            const fontWeight = row[bestGameBonusOwnerKey] ? bold : undefined;
                            cells.push((<Cell size={2} align={'center'} color={color} fontWeight={fontWeight}>{row[bestGameBonusKey]}</Cell>));
                        }

                        if (hasBestPlayerColumn) {
                            const color = row[bestPlayerBonusOwnerKey] ? secondaryColor : undefined;
                            const fontWeight = row[bestPlayerBonusOwnerKey] ? bold : undefined;
                            cells.push((<Cell size={2} align={'center'} color={color} fontWeight={fontWeight}>{row[bestPlayerBonusKey]}</Cell>));
                        }

                        const isLastRow = index === rows.length - 1;

                        return (
                            <Row key={row[userIdKey]} lastRow={isLastRow}>
                                {cells}
                            </Row>
                        )
                    })
                }
            </Rows>
            <Legend>{legendText}</Legend>
        </Table>
    )
}

const styles = StyleSheet.create({
});
