import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { UsersAPI } from '../API/Users'
import {Formik} from 'formik';
import * as yup from 'yup';
import { RequiredTextInput } from '../Components/RequiredTextInput';
import BtxButton from '../Components/BtxButton';
import { strings } from '../locales/i18n';
import { globalStyles } from '../Styles/globals';
import { black, primaryColor, red, secondaryColor, thirdColor, transparent, white } from '../Styles/colors';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph, TextError, Title1 } from '../Components/Texts';
import { checkPassword } from '../Service/authenticationService';
import { SwitchBasketix } from '../Components/SwitchBasketix';

export default function SignOn({ navigation }) {
  const [state, setState] = useState({
    person: {},
    request: '',
    badRequest: '',
  });

  const reviewSchema = yup.object({
    email : yup.string()
      .required(strings('signon.emailRequired'))
      .email(strings('signon.emailType')),
    name : yup.string()
      .required(strings('signon.nameRequired')),
    password : yup.string()
      .required(strings('signon.passwordRequired'))
      .test('', strings('signon.passwordType'), function(value) {
        return checkPassword(value)
      }),
    verifyPassword : yup.string()
      .required(strings('signon.verifyPasswordRequired'))
      .test('', strings('signon.verifyPasswordType'), function(value) {
        return this.parent.password === value;
      }),
    cgu : yup.boolean().oneOf([true], strings('signon.cguType'))
  });

  const initialFormValues = {
    email: '',
    name: '',
    password: '',
    verifyPassword: '',
    //cgu: false,
  };

  const handleSubmit = (values) => {
    if (values) {
      UsersAPI.create(values).then(data => {
        if (data.error) {
          console.log(data.error);
          setState({ badRequest: 'Cette adresse mail existe déja' })
        } else {
          //TODO: use redux
          navigation.navigate(strings('navigation.LoginConfirmSignOn'), data);
        }
      });
    }
  }

  const labelStyle = [globalStyles.textInputLabel, {color: thirdColor}];
  const textInputStyle = [globalStyles.textInput, {borderColor: thirdColor, color: thirdColor}];

  const goToWelcome = () => {
    navigation.navigate(strings('navigation.Welcome'));
};

  const form = (
    <Formik
        initialValues={ initialFormValues }
        validationSchema={ reviewSchema }
        onSubmit={ (values) => { handleSubmit(values) } }
    >
        {(formikprops) => (
            <View>
                <View style={styles.formGroup}>
                    <Paragraph style={ labelStyle }>{ strings('signon.emailLabel')}</Paragraph>
                    <RequiredTextInput
                        style={ textInputStyle }
                        placeholder={ strings('signon.emailPlaceholder') }
                        onChangeText={ formikprops.handleChange('email') }
                        value={ formikprops.values.email }
                        onBlur={ formikprops.handleBlur('email') }
                        autoCompleteType={ 'email' }
                        keyboardType={ 'email-address' }
                        textContentType={ 'emailAddress' }
                        />
                    <TextError>{ formikprops.touched.email && formikprops.errors.email }</TextError>
                </View>
                <View style={styles.formGroup}>
                    <Paragraph style={ labelStyle }>{ strings('signon.pseudoLabel') }</Paragraph>
                    <RequiredTextInput
                        style={ textInputStyle }
                        placeholder={ strings('signon.pseudoPlaceholder') }
                        onChangeText={ formikprops.handleChange('name') }
                        value={ formikprops.values.name }
                        onBlur={ formikprops.handleBlur('name') }
                        />
                    <TextError>{ formikprops.touched.name && formikprops.errors.name }</TextError>
                </View>
                <View style={styles.formGroup}>
                    <Paragraph style={ labelStyle }>{ strings('signon.passwordLabel') }</Paragraph>
                    <RequiredTextInput
                        style={ textInputStyle }
                        placeholder={ strings('signon.passwordPlaceholder') }
                        onChangeText={ formikprops.handleChange('password') }
                        value={ formikprops.values.password }
                        onBlur={ formikprops.handleBlur('password') }
                        password={ true }
                        secureTextEntry={ true }
                        />
                    <TextError>{ formikprops.touched.password && formikprops.errors.password }</TextError>
                </View>
                <View style={styles.formGroup}>
                    <Paragraph style={ labelStyle }>{ strings('signon.verifypasswordLabel')}</Paragraph>
                    <RequiredTextInput
                        style={ textInputStyle }
                        placeholder={ strings('signon.verifypasswordPlaceholder') }
                        onChangeText={ formikprops.handleChange('verifyPassword') }
                        value={ formikprops.values.verifyPassword }
                        onBlur={ formikprops.handleBlur('verifyPassword') }
                        password={ true }
                        secureTextEntry={ true }
                        />
                    <TextError>{ formikprops.touched.verifyPassword && formikprops.errors.verifyPassword }</TextError>
                </View>

                {/*
                <SwitchBasketix
                  label = { strings('signon.cguLabel') }
                  styleParagraph = { labelStyle }
                  onValueChange={ (value)  => { formikprops.setFieldValue('cgu', value) } }
                  value = {formikprops.values.cgu}
                />
                <TextError>{ formikprops.touched.cgu && formikprops.errors.cgu }</TextError>
                */}

                <Paragraph style={ styles.badRequest }>{ state.badRequest }</Paragraph>
                <View style={ styles.buttonContainer }>
                    <BtxButton
                        title={  strings('signon.signonButton') }
                        onPress={ formikprops.handleSubmit }
                        height={40}
                        maxHeight={58}
                        backgroundColor={primaryColor}
                    />
                    <BtxButton
                        onPress={ goToWelcome }
                        title={ strings('globals.cancel') }
                        backgroundColor={ transparent }
                        color={ white }
                        maxHeight={58}
                    />
                </View>
            </View>
          )}
        </Formik>
  );

  return (
    <ScrollViewMainContainer backgroundColor={black}>
        <View style={ styles.basketix }>
            <Title1 style={  styles.title }>{ strings('login.basketixLabel') }</Title1>
            <View style={ styles.bottomBanner }>
                <View style={ styles.boxColorOne } />
                <View style={ styles.boxColorTwo } />
                <View style={ styles.boxColorThree } />
            </View>
            <Paragraph style={ styles.text }>{ strings('login.thisIsWhatLabel') }</Paragraph>
        </View>
        <View style={ styles.basketixFooter }>
            { form }
        </View>
    </ScrollViewMainContainer>
  )
}

const styles = StyleSheet.create({
    badRequest: {
        color: red,
        fontWeight: 'bold',
    },
    formGroup: {
        marginBottom: 12,
    },
    buttonContainer: {
        marginTop: 32,
    },
    container: {
        justifyContent: 'center',
        backgroundColor: black,
        flex: 1,
    },
    title: {
        color: white,
        textAlign: 'center',
        fontSize: 48,
    },
    text: {
        color: white,
        textAlign: 'center',
    },
    textInput: {
        borderColor: thirdColor,
        color: thirdColor,
    },
    basketix: {
        flex: 1,
        justifyContent: 'center',
        marginHorizontal: 42,
        marginBottom: 16,
    },
    basketixFooter: {
        flex: 2,
        justifyContent: 'flex-start',
    },
    bottomBanner: {
        height: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        marginHorizontal: 42,
        marginVertical: 8,
    },
    boxColorOne: {
        backgroundColor: secondaryColor,
        flex: 1,
    },
    boxColorTwo: {
        backgroundColor: primaryColor,
        flex: 1,
    },
    boxColorThree: {
        backgroundColor: thirdColor,
        flex: 1,
    },
});
