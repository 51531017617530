import React from 'react';
import { View,StyleSheet } from 'react-native';
import { lightGrey} from '../Styles/colors';

export function Separator({ isVisible }) {
    if (isVisible !== undefined && !isVisible) {
        return (<></>)
    }

    return (
        <View style={styles.container}>
            <View style={styles.line}/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    line: {
        height: 1,
        backgroundColor: lightGrey,
        marginVertical: 12,
        width: '50%'
    }
});
