import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { black, thirdColor, white } from '../Styles/colors';
import { globalStyles } from '../Styles/globals';
import { sizeL } from '../Styles/text';
import { strings } from '../locales/i18n';
import { Paragraph } from './Texts';

export function DraftRoundHeader(props) {
    const {
        roundNumber,
        onPress,
        isOpen,
        isLive,
    } = props;

    const roundNumberFinal = roundNumber.toString();
    const iconName = isOpen ? 'minuscircle' : 'pluscircle';
    const liveColor = isLive && !isOpen ? thirdColor : black;
    const iconColor = isLive && !isOpen ? white : white;
    const textContainerStyle = [styles.textContainer, {backgroundColor: liveColor}]

    const textStyle = [
        globalStyles.titleText3,
        {color: white},
    ];

    return (
        <View style={styles.container}>
            <TouchableOpacity style={globalStyles.flexRow} onPress={ () => { onPress(roundNumberFinal) } }>
                <View style={textContainerStyle}>
                    <Paragraph style={textStyle}>{strings('draftRoundHeader.round')}{roundNumberFinal}</Paragraph>
                    <View style={styles.iconContainer} >
                        <AntDesign name={iconName} size={sizeL} color={iconColor} />
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 6,
        marginTop: 6,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 4,
        backgroundColor: black,
        minWidth: 70,
        padding: 5,
        borderRadius: 6,
    },
    lineContainer: {
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 16,
    },
    liveContainer: {
        justifyContent: 'center'
    },
    line: {
        flex: 1,
        height: 0,
        backgroundColor: black,
    },
    iconContainer: {
        display: 'flex',
        marginLeft: 6,
        flexDirection: 'row',
        alignItems: 'center',
    }
});
