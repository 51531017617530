import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Paragraph } from './Texts';
import { Pill } from './Pill';

export function RankCell({rank, points}) {
    let pointsJSX;
    if (points !== undefined) {
        pointsJSX = (<Pill>{points}</Pill>);
    }

    return (
        <View style={styles.rankCell}>
            <Paragraph>{rank}</Paragraph>
            {pointsJSX}
        </View>
    )
}

const styles = StyleSheet.create({
    rankCell: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
    },
});
