import React from 'react';
import { useSelector } from 'react-redux';
import FreeAgentChoicesModal from '../Components/FreeAgentChoicesModal';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';

export default function LeagueDraftFreeAgents({ navigation }) {
    const freeAgents = useSelector(state => state.draft.freeAgents);
    const isLoadingFreeAgents = useSelector(state => state.draft.isLoadingFreeAgents);

    const goBack = () => {
        navigation.goBack();
    };

    return (
        <ScrollViewMainContainer>
            <FreeAgentChoicesModal
                isLoadingFreeAgents={isLoadingFreeAgents}
                freeAgents={freeAgents}
                onPressClose={goBack}
                onPressChoose={goBack}
            />
        </ScrollViewMainContainer>
    )
};
