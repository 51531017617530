import React from 'react';
import { StyleSheet, View } from 'react-native';
import { lightGrey } from '../Styles/colors';

export function MainKpiBar({ style, children}) {
    if (!children) {
        return (<></>);
    }

    return (
        <View style={[styles.container, style]}>
            {children}
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        marginVertical: 6,
        paddingVertical: 6,
        minHeight: 62,
        backgroundColor: lightGrey,
    }
});
