import React from 'react';
import { StyleSheet, View } from 'react-native';
import { black, primaryColor, white } from '../Styles/colors';
import { Paragraph } from './Texts';
import BtxButton from './BtxButton';
import { InjuryIcon } from './InjuryIcon';
import { Card } from './Card';
import { strings } from '../locales/i18n';
import { TouchableOpacity } from 'react-native-web';

/*
Player card that display main information about a player all over the app

@name: String for the name of the player.
@team: String for the third title of the card
@positions: optional. List of positions of player.
@meta: optional. Meta string.
@injury: optional. Is the player injured ?
@actionButtonIcon : optional. A a Icon on PlayerCard
@onPressActionButton : optional. To add a action on the PlayerCard

+ all the Card component props
*/
export function PlayerCardExtended(props) {

    const { name, team, injury, positions, meta, onPressActionButton, actionButtonIcon } = props;

    const _positions = positions || [];
    const separatorTitle = '-';

    const _team = team ? ` ${separatorTitle} ${team}` : '';

    let actionIconJSX = (<></>);
    if (onPressActionButton) {
        actionIconJSX = (
            <View style={styles.actionIconStyle}>
                <TouchableOpacity onPress={ onPressActionButton }>
                    {actionButtonIcon}
                </TouchableOpacity>
            </View>
        )
    }

    const rightJSX = (
        <View style={styles.headerMeta}>
            <View style={styles.metaPosition}>{
                _positions.map((pos, index) => (
                    <BtxButton
                        title={pos}
                        key={index}
                        disabled={true}
                        backgroundColor={black}
                        color={white}
                        marginBottom={1}
                    />
                ))
            }
            {actionIconJSX}
            </View>
            
            <View style={styles.metaOther}>
                <Paragraph>{meta}</Paragraph>
            </View>
        </View>
    );

    return (
        <Card
            title={name}
            thirdTitle={_team}
            extraTitleComponent={<InjuryIcon visible={injury} />}
            renderRight={rightJSX}

            {...props}
        />
    )
}

const styles = StyleSheet.create({
    headerMeta: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        alignSelf: 'baseline',
        height: '100%',
        paddingLeft: 4,
    },
    metaPosition: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    metaOther: {
    },
    actionIconStyle: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginLeft:8,
    },
});
