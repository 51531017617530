import React from 'react';
import { StyleSheet, View } from 'react-native';
import { grey } from '../Styles/colors';
import { Bold, Paragraph } from './Texts';

export function NamesCell({teamName, userName}) {
    return (
        <View style={styles.namesCell}>
            <Bold numberOfLines={1}>{teamName}</Bold>
            <Paragraph numberOfLines={1} style={styles.userNameText}>{userName}</Paragraph>
        </View>
    )
}

const styles = StyleSheet.create({
    namesCell: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxWidth: '100%',
    },
    userNameText: {
        color: grey,
    },
});
