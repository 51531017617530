import React, { useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useSelector, useDispatch } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwt_decode from 'jwt-decode';
import LoginStack from './LoginStack';
import DrawerApp from './DrawerApp';
import AppLoader from '../Screens/AppLoader';
import FlashMessage from "react-native-flash-message";

import Tmp from './Tmp';

export default function Navigation() {
    const dispatch = useDispatch();

    const isLogin = useSelector(state => state.authentication.isLogin);
    const isLoad = useSelector(state => state.appLoader.isLoad);

    useEffect(() => {
        if (!isLogin) {
            AsyncStorage.getItem('userToken').then(token => {
                if (token !== null) {
                    const decoded = jwt_decode(token);
                    if (decoded.expirationDate >= new Date().toISOString()) {
                        dispatch({ type: 'AUTH_LOGIN' });
                    } else {
                        AsyncStorage.removeItem('userToken');
                    }
                }
            })
        }
    }, [isLogin])

    const displayedNavigation = !isLogin ? <LoginStack /> : isLoad ? <DrawerApp /> : <AppLoader />;
    //const displayedNavigation = <Tmp />; //Useful when developing a feature

    return (
        <SafeAreaProvider>
            <NavigationContainer>
                { displayedNavigation }
                <FlashMessage
                    position={'top'}
                    floating={true}
                    duration={5000}
                    titleStyle={{ fontWeight: 'bold' }}
                />
            </NavigationContainer>
        </SafeAreaProvider>
    );
}
