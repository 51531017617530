import { strings } from "../locales/i18n";

export function getBidsSubtitle(hasUserBid, totalBids) {
    if (hasUserBid) {
        const otherTotalBids = totalBids - 1;

        if (otherTotalBids > 1) {
            return strings('getBidsSubtitle.youAndOthersBids', {otherTotalBids: otherTotalBids})
        }

        if (otherTotalBids === 1) {
            return strings('getBidsSubtitle.youAndOtherBids', {otherTotalBids: otherTotalBids})
        }

        return strings('getBidsSubtitle.yourAreOnlyBids')
    }
    else{
        if (totalBids > 1){
            return strings('getBidsSubtitle.onlyOthersBids', {totalBids: totalBids})
        }
        return strings('getBidsSubtitle.onlyOtherBids')
    }
};
