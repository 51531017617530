import React, { useState } from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { useDispatch } from 'react-redux';
import { strings } from '../locales/i18n';
import BtxButton from '../Components/BtxButton';
import { globalStyles } from '../Styles/globals';
import { UsersAPI } from '../API/Users';
import { primaryColor, thirdColor, white, black, red } from '../Styles/colors';
import {Formik} from 'formik';
import * as yup from 'yup';
import { sizeM } from '../Styles/text';
import { Paragraph, TextError, Title1 } from '../Components/Texts';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';


export default function PasswordForgotten({route}) {
    const [emailSent, setEmailSent] = useState('');


    const reviewSchema = yup.object({
        email : yup.string()
          .required(strings('passwordForgotten.emailRequired'))
          .email(strings('passwordForgotten.emailType')),
    });


    
    const handleSubmit = (values) => {
        if (values) {
        UsersAPI.sendMaiForgotPassword(values).then(data => {
            //if (data.error) {
            if (data) {
                //TODO: use redux
                setEmailSent(strings('passwordForgotten.emailSent'))

            } else {
                setEmailSent('')
            }
        });
        }
    }


    return (
        <ScrollViewMainContainer backgroundColor={black}>
            <View style={ styles.basketixFooter }>
                <Title1 style={ {color:white, marginBottom:15} }>{strings('passwordForgotten.passwordForgottenTitle')}</Title1>
                <Paragraph style={ {color:white} }>
                    {strings('passwordForgotten.emailForgotDescription')}
                </Paragraph>
                <Formik
                    enableReinitialize
                    initialValues={{email: route.params?.email ? route.params.email : ''}}
                    validationSchema={reviewSchema}
                    onSubmit={ (values) => { handleSubmit(values) } }
                >
                {(formikprops) => (
                    <View style={ styles.login } >
                        <Paragraph style={ [globalStyles.textInputLabel, styles.textInputTextStyle] }>
                            {strings('passwordForgotten.email')} 
                        </Paragraph>
                        <TextInput
                            style={ [globalStyles.textInput, styles.textInput] }
                            onChangeText={formikprops.handleChange('email')}
                            value={formikprops.values.email}
                            onBlur={formikprops.handleBlur('email')}
                            autoCompleteType={ 'email' }
                            keyboardType={ 'email-address' }
                            textContentType={ 'emailAddress' }
                        />
                        <TextError>{ formikprops.touched.email && formikprops.errors.email }</TextError>
                        <Paragraph style={ {color:white, textAlign: 'center'}}>{ emailSent }</Paragraph>
                        <BtxButton
                            onPress={ formikprops.handleSubmit }
                            title={ (strings('passwordForgotten.reinitPasswordButton'))}
                            backgroundColor={ primaryColor }
                            color={ black }
                            height={48}
                            maxHeight={58}
                            marginTop={15}
                        />
                    </View>
                )}
                </Formik>
            </View>
        </ScrollViewMainContainer>
    )
};

const styles = StyleSheet.create({
    login: {
        display: 'flex',
        marginTop: 42,
        marginBottom: 12,
    },
    textInput: {
        borderColor: thirdColor,
        color: thirdColor,
        fontWeight: "bold",
    },
    basketixFooter: {
        flex: 2,
        justifyContent: 'flex-start',
    },
    textInputTextStyle: {
        color: thirdColor,
        fontSize: sizeM
    },
    badRequest: {
        color: red,
        fontWeight: 'bold',
    },
});
