import React, { useState } from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { useDispatch } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { strings } from '../locales/i18n';
import BtxButton from '../Components/BtxButton';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { globalStyles } from '../Styles/globals';
import { UsersAPI } from '../API/Users';
import { primaryColor, secondaryColor, thirdColor, white, black, transparent } from '../Styles/colors';
import {Formik} from 'formik';
import * as yup from 'yup';
import { sizeM } from '../Styles/text';
import { Paragraph, TextError, Title1 } from '../Components/Texts';


export default function Login({route, navigation}) {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');

    const signInAsync = async (values) => {
        UsersAPI.login(values).then( async (data) => {
            if (! data.error) {
                await AsyncStorage.setItem('userToken', data);
                dispatch({ type: 'AUTH_LOGIN' });
            } else {
                setErrorMessage(data.error.message);
            }
        })
    };

    const reviewSchema = yup.object({
        email : yup.string()
            .required(strings('login.emailRequired'))
            .email(strings('login.emailType')),
        password: yup.string()
            .required(strings('login.passwordRequired'))
    });

    const goToWelcome = () => {
        navigation.navigate(strings('navigation.Welcome'));
    };

    const goToPasswordForgotten = () => {
        navigation.navigate(strings('navigation.PasswordForgotten'));
    };


    return (
        <ScrollViewMainContainer backgroundColor={black}>
            <View style={ styles.basketix }>
                <Title1 style={ styles.title }>{ strings('login.basketixLabel') }</Title1>
                <View style={ styles.bottomBanner }>
                    <View style={ styles.boxColorOne } />
                    <View style={ styles.boxColorTwo } />
                    <View style={ styles.boxColorThree } />
                </View>
                <Paragraph style={ styles.text }>{ strings('login.thisIsWhatLabel') }</Paragraph>
            </View>
            <View style={ styles.basketixFooter }>
                <Formik
                    enableReinitialize
                    initialValues={{
                        email: route.params?.email ?? '',
                        password: ''
                    }}
                    validationSchema={reviewSchema}
                    onSubmit={(values) => { handleSubmit(values) } }
                >
                {(formikprops) => (
                    <View style={ styles.login } >
                        <Paragraph style={ [globalStyles.textInputLabel, styles.textInputTextStyle] }>
                            { strings('login.loginPlaceholder')}
                        </Paragraph>
                        <TextInput
                            style={ [globalStyles.textInput, styles.textInput] }
                            onChangeText={formikprops.handleChange('email')}
                            value={formikprops.values.email}
                            onBlur={formikprops.handleBlur('email')}
                            autoCompleteType={ 'email' }
                            keyboardType={ 'email-address' }
                            textContentType={ 'emailAddress' }
                        />
                        <TextError >  { formikprops.touched.email && formikprops.errors.email} </TextError>
                        <Paragraph style={ [globalStyles.textInputLabel, styles.textInputTextStyle] }>
                            { strings('login.passwordPlaceholder') }
                        </Paragraph>
                        <TextInput
                            style={ [globalStyles.textInput, styles.textInput] }
                            onChangeText={formikprops.handleChange('password')}
                            value={formikprops.values.password}
                            onBlur={formikprops.handleBlur('password')}
                            password={true}
                            secureTextEntry={true}
                        />
                        {/* <BtxButton
                            onPress={goToPasswordForgotten}
                            title={strings('login.forgotPassword')}
                            backgroundColor={transparent}
                            color={white}
                            maxHeight={65}
                            textAlign='right'
                            justifyContent= 'right'
                        /> */}
                        <TextError>  { formikprops.touched.password && formikprops.errors.password} </TextError>
                        <Paragraph style={ styles.badRequest } > { errorMessage } </Paragraph>
                        <BtxButton
                            onPress={ () => { signInAsync(formikprops.values) } }
                            title={ strings('login.loginButton') }
                            backgroundColor={ primaryColor }
                            color={ black }
                            height={52}
                        />
                        <BtxButton
                            onPress={ goToWelcome }
                            title={ strings('globals.cancel') }
                            backgroundColor={ transparent }
                            color={ white }
                            maxHeight={58}
                        />
                    </View>
                )}
                </Formik>
            </View>
        </ScrollViewMainContainer>
    )
};

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        backgroundColor: black,
        flex: 1,
    },
    title: {
        color: white,
        textAlign: 'center',
        fontSize: 48,
    },
    text: {
        color: white,
        textAlign: 'center',
    },
    login: {
        display: 'flex',
        marginTop: 42,
        marginBottom: 12,
    },
    textInput: {
        borderColor: thirdColor,
        color: thirdColor,
        fontWeight: 'bold',
    },
    basketix: {
        flex: 1,
        justifyContent: 'center',
        marginHorizontal: 42,
        marginBottom: 16,
    },
    basketixFooter: {
        flex: 2,
        justifyContent: 'flex-start',
    },
    bottomBanner: {
        height: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        marginHorizontal: 42,
        marginVertical: 8,
    },
    boxColorOne: {
        backgroundColor: secondaryColor,
        flex: 1,
    },
    boxColorTwo: {
        backgroundColor: primaryColor,
        flex: 1,
    },
    boxColorThree: {
        backgroundColor: thirdColor,
        flex: 1,
    },
    textInputTextStyle: {
        color: thirdColor,
        fontSize: sizeM
    },
});
