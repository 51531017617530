import { seasonSettingsActions } from "./actions";

const initialState = {
    data: {
        draftType: 'looserFirst',
        isDraftReverse: true,
        nDraftRound: 7,
        nWeek: 2,
    },
    isLoadingNextSeasonSettings: false,
};

export function seasonSettingsReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case seasonSettingsActions.UPDATE_SEASON_SETTING:
            nextState = {
                ...state,
                data: {
                    ...state.data,
                    ...action.value,
                }
            }
            return nextState || state;
        case seasonSettingsActions.RESET_SEASON_SETTING:
            nextState = {
                ...state,
                data: {
                    ...initialState.data,
                }
            }
            return nextState || state;
        case seasonSettingsActions.LOAD_NEXT_SEASON_SETTINGS:
            nextState = {
                ...state,
                isLoadingNextSeasonSettings: true,
            }
            return nextState || state;
        case seasonSettingsActions.LOAD_NEXT_SEASON_SETTINGS_SUCCESS:
        case seasonSettingsActions.LOAD_NEXT_SEASON_SETTINGS_ERROR:
            nextState = {
                ...state,
                isLoadingNextSeasonSettings: false,
            }
            return nextState || state;
        default:
            return state;
    }
};
