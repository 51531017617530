import React from 'react';
import { View, StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { grey, thirdColor } from '../Styles/colors';
import { sizeM } from '../Styles/text';
import { strings } from '../locales/i18n';
import { Title2 } from './Texts';

export function Live({ color, size, isActive, isVisible }) {
    if (isVisible !== undefined && !isVisible) { return (<></>) }

    let finalColor = color ? color : thirdColor;
    if (isActive !== undefined) {
        finalColor = isActive ? finalColor : grey;
    }
    const finalSize = size ? size : sizeM;
    const textStyle = {color: finalColor}

    return (
        <View style={styles.container}>
            <MaterialIcons name='brightness-1' size={finalSize} color={finalColor} />
            <Title2 style={[styles.textLive, textStyle]}>{ strings('globals.live') }</Title2>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 4,
    },
    textLive: {
        color: thirdColor,
        marginLeft: 3,
    },
});
