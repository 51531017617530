import React, { useState } from 'react';
import { FlatList, View, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux'
import { Ionicons, Feather } from '@expo/vector-icons';
import { strings } from '../locales/i18n';
import { alphaColor, secondaryColor, white, thirdColor, grey, primaryColor, black, backgroundColor, lightGrey, transparent } from '../Styles/colors';
import { DraftAPI } from '../API/Draft';
import DraftChoice from '../Components/DraftChoice';
import BtxButton from '../Components/BtxButton';
import { sizeM, sizeXL } from '../Styles/text';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph, Title1 } from '../Components/Texts';
import { globalStyles } from '../Styles/globals';
import { SwitchBasketix } from '../Components/SwitchBasketix';

const arrayMove = require('array-move');

export default function LeagueDraftChoice({ route, navigation }) {
    const {
        pickNumber,
        round,
        roundPick,
        isCurrentChoice,
    } = route.params;

    const isAutoProps = route.params.isAuto;

    const dispatch = useDispatch();

    const leagueId = useSelector(state => state.league.leagueId);
    const seasonId = useSelector(state => state.league.season.seasonId);
    const choices = useSelector(state => state.draft.choices);
    const capSpace = useSelector(state => state.draft.capSpace);
    const isLoadingCapSpace = useSelector(state => state.draft.isLoadingCapSpace);
    const isLoadingFreeAgents = useSelector(state => state.draft.isLoadingFreeAgents);

    const [defaultChoices, setDefaultChoices] = useState(choices);
    const [isAuto, setIsAuto] = useState(isAutoProps);
    const [defaultIsAuto, setDefaultIsAuto] = useState(isAutoProps);
    const [isSaving, setIsSaving] = useState(false);
    const [isPicking, setIsPicking] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [displaySkip, setDisplaySkip] = useState(false);

    const setChoices = (choices) => {
        dispatch({type: 'UPDATE_DRAFT_CHOICES', value: choices});
    };

    const onPressSave = () => {
        setIsSaving(true);
        const body = {
            seasonId,
            pickNumber,
            playerIds: [...choices.map(choice => choice.playerId)],
            isAuto: isAuto,
        };
        DraftAPI.setPreDraft(body).then(data => {
            if (!data.error) {
                dispatch({ type: 'UPDATE_LEAGUE', value: {shouldLoadDraft: true}});
                setDefaultChoices(choices);
                setDefaultIsAuto(isAuto);
                setIsSaving(false);
                setIsEditMode(false)
                navigation.goBack();
            } else {
                console.log(data.error);
                setIsSaving(false);
            }
        }).catch(err => {
            console.log(err);
            setIsSaving(false);
        });
    };

    const onPressDraftAction = (isDraftFinished) => {
        if (isDraftFinished) {
            return { type: 'UPDATE_APP_LOADER', value: { leagueIdToLoad: leagueId, loadingId: Date.now() } };
        }
        return { type: 'UPDATE_LEAGUE', value: {shouldLoadDraft: true} };
    };

    const onPressDraftNavigate = (isDraftFinished) => {
        if (isDraftFinished) {
            navigation.goBack();
            navigation.navigate('LeagueLoadingStack');
        } else {
            navigation.goBack();
        }
    };

    const onPressDraft = () => {
        setIsPicking(true);
        const body = {
            seasonId,
            pickNumber,
            playerId: choices[0].playerId,
        };
        DraftAPI.pick(body).then(data => {
            if (!data.error) {
                dispatch(onPressDraftAction(data.isFinished));
                setDefaultChoices(choices[0]);
                setDefaultIsAuto(false);
                setIsPicking(false);
                setIsEditMode(false)
                onPressDraftNavigate(data.isFinished);
            } else {
                console.log(data.error);
                setIsPicking(false);
            }
        }).catch(err => {
            console.log(err);
            setIsPicking(false);
        });
    };

    const onPressSkip = () => {
        setIsPicking(true);
        const body = {
            seasonId,
            pickNumber,
        };
        DraftAPI.skip(body).then(data => {
            if (!data.error) {
                dispatch(onPressDraftAction(data.isFinished));
                setDefaultChoices(choices[0]);
                setDefaultIsAuto(false);
                setIsPicking(false);
                setIsEditMode(false)
                setDisplaySkip(false);
                onPressDraftNavigate(data.isFinished);
            } else {
                console.log(data.error);
                setIsPicking(false);
                setDisplaySkip(false);
            }
        }).catch(err => {
            console.log(err);
            setIsPicking(false);
            setDisplaySkip(false);
        });
    };

    const haveChoicesChanged = !(JSON.stringify(defaultChoices) === JSON.stringify(choices) && defaultIsAuto === isAuto);

    const onPressDelete = (deletedChoice) => {
        setChoices(choices.filter(choice => choice !== deletedChoice));
    };

    const onPressUp = (index) => {
        if (index > 0) {
            setChoices(arrayMove(choices, index, index-1));
        }
    };

    const onPressDown = (index) => {
        if (index + 1 < choices.length) {
            setChoices(arrayMove(choices, index, index+1));
        }
    };

    const displaySwitch = () => {
        if (!isCurrentChoice) {
            return (
                <SwitchBasketix
                    label={strings('leagueDraftChoice.automaticDraft')}
                    labelHighlighted={ true }
                    value={ isAuto }
                    onValueChange={() => { setIsAuto(!isAuto) }}
                    activeColor={ thirdColor }
                    disabled= { isEditMode }
                />
            );
        }
    };

    const displayHelperText = () => {
        let text = strings('leagueDraftChoice.preDraft') ;
        if (isCurrentChoice) {
            text = strings('leagueDraftChoice.preDraftFirstPlayerWillBeDraft');
        }

        return (
            <View style={ styles.infoTextContainer }>
                <Paragraph style={ styles.infoText }>{ text }</Paragraph>
            </View>
        );
    };

    const displayChoices = () => {
        if (isLoadingFreeAgents) {
            return (<BtxActivityIndicator size='small' />)
        } else {
            const addPlayerColor = !isEditMode ? secondaryColor : grey;
            return (
                <View style={styles.choicesContainer}>
                    <FlatList
                        data={ choices }
                        keyExtractor={ (item, index) => item.playerId }
                        renderItem={ ({ item, index, drag, isActive }) => (
                            <DraftChoice
                                index={index}
                                choice={item}
                                onPressDelete={() => { onPressDelete(item) }}
                                onLongPress={isEditMode ? drag : undefined}
                                isActive={isActive}
                                isEditMode={isEditMode}
                                isFirst={index === 0}
                                isLast={index + 1 === choices.length}
                                onPressUp={() => { onPressUp(index) }}
                                onPressDown={() => { onPressDown(index) }}
                            />
                        )}
                        showsVerticalScrollIndicator={false}
                        style={{ flexGrow: 0 }}
                    />
                    <TouchableOpacity style={ styles.addPlayers } onPress={ goToFreeAgents } disabled={isEditMode}>
                        <Ionicons name='md-add-circle-outline' size={24} color={ addPlayerColor } />
                        <Paragraph style={ [styles.addPlayersText, {color: addPlayerColor}] }>{strings('leagueDraftChoice.addPlayers')}</Paragraph>
                    </TouchableOpacity>
                </View>
            )
        }
    };

    const displayAction = () => {
        if (isCurrentChoice) {
            if (choices.length === 0) {
                return (
                    <BtxButton
                        title={'Drafter aucun joueur'}
                        onPress={() => setDisplaySkip(true)}
                        backgroundColor={isEditMode ? lightGrey : thirdColor}
                        color={black}
                        height={40}
                        width={'33%'}
                        disabled={isEditMode}
                    />
                );
            }

            return (
                <BtxButton
                    title={`Drafter ${choices[0].fullName}`}
                    onPress={ onPressDraft }
                    backgroundColor={ !choices.length > 0 || isEditMode ? lightGrey : thirdColor }
                    color={ choices.length > 0 ? black : grey }
                    height={ 40 }
                    width={'33%'}
                    disabled={ !choices.length > 0 || isEditMode}
                    loading={ isPicking }
                />
            );
        }

        const canClick = haveChoicesChanged && !isEditMode;
        return (
            <BtxButton
                title={strings('leagueDraftChoice.save')}
                onPress={onPressSave}
                backgroundColor={ canClick ? primaryColor : alphaColor(grey, 70) }
                color={ canClick ? black : grey }
                height={ 40 }
                width={'33%'}
                disabled={ !canClick }
                loading={isSaving}
            />
        );
    };

    const displayEdit = () => {
        if (choices.length == 0 && !isEditMode) {
            return (<></>)
        }

        const text = !isEditMode ? strings('leagueDraftChoice.editOrder') : strings('leagueDraftChoice.validate');
        const color = !isEditMode ? black: primaryColor;
        const iconName = !isEditMode ? 'edit' : 'check-circle';

        return (
            <View style={styles.editButtonContainer}>
                <BtxButton
                    title={text}
                    onPress={ () => setIsEditMode(!isEditMode) }
                    alignSelf={'flex-start'}
                    backgroundColor={backgroundColor}
                    color={color}
                    margin={0}
                    padding={0}
                    borderColor={color}
                    icon={<Feather name={iconName} size={12} color={color} />}
                />
            </View>
        );
    };

    const goToFreeAgents = () => {
        navigation.navigate(strings('navigation.LeagueDraftFreeAgents'));
    };

    if (displaySkip) {
        return (
            <ScrollViewMainContainer>
                <View style={[globalStyles.justifyCenter, globalStyles.alignCenter]}>
                    <Paragraph>Etes-vous sûr de ne pas vouloir drafter de joueur pour ce tour ?</Paragraph>
                    <View style={globalStyles.yesNoButtonView}>
                        <BtxButton
                            backgroundColor={primaryColor}
                            title= {strings('globals.yes')}
                            color={black}
                            onPress={onPressSkip}
                            alignSelf={'center'}
                            marginRight={12}
                            loading={isPicking}
                        />
                        <BtxButton
                            backgroundColor={transparent}
                            title= {strings('globals.cancel')}
                            color={black}
                            onPress={ () => setDisplaySkip(false) }
                            alignSelf={'center'}
                        />
                    </View>
                </View>
            </ScrollViewMainContainer>
        );
    };

    const capSpaceJSX = !isLoadingCapSpace && capSpace ? (
        <Title1 style={globalStyles.titleText2}>{ strings('leagueDraftChoice.capSpaceEnable', {capSpace}) }</Title1>
    ) : <></>;

    return (
        <ScrollViewMainContainer>
            <View style={ styles.header }>
                <View style={ styles.headerText }>
                    <Title1>{ strings('leagueDraftChoice.roundPick', {round :round,roundPick:roundPick })}</Title1>
                    {capSpaceJSX}
                </View>
                <View style={ styles.pickInfo }>
                    { displaySwitch() }
                </View>
            </View>
            { displayHelperText() }
            { displayEdit() }
            <View style={ styles.choicesList }>
                { displayChoices() }
            </View>
            <View style={ styles.saveButtonContainer }>
                { displayAction() }
            </View>
        </ScrollViewMainContainer>
    )
};

const styles = StyleSheet.create({
    infoTextContainer: {
        marginBottom: 16,
    },
    infoText: {
        color: grey,
        fontSize: sizeM,
    },
    choicesList: {
        flex: 1,
        minHeight: 10,
    },
    saveButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 24,
        marginBottom: 8,
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    switch: {},
    choicesContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    addPlayers: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
    },
    addPlayersCircleShape: {
        borderWidth: 3,
        borderColor: secondaryColor,
        backgroundColor: white,
    },
    addPlayersText: {
        color: secondaryColor,
        fontSize: sizeXL,
        fontWeight: 'bold',
        marginHorizontal: 4,
        textTransform: 'uppercase',
    },
    header: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    headerText: {
        flex: 1,
        flexDirection: 'column',
    },
    pickInfo: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    editButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: 4
    }
});
