import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { black, grey, silver, white } from '../Styles/colors';
import { globalStyles } from '../Styles/globals';
import { sizeS, sizeXXL } from '../Styles/text';
import { Paragraph } from './Texts';

export function Kpi({ title, value, selected, onPress, size, titleStyle, valueStyle }) {
    const [height, setHeight] = useState(0);

    const backgroundColor = selected ? black : white;
    const borderColor = selected ? black : silver;
    const color = selected ? white : black;
    const sizeStyle = size ? { height: size, maxHeight: size, width: size, maxWidth: size } : undefined;

    return (
        <TouchableOpacity
            style={[styles.kpiContainer, globalStyles.shadow, { backgroundColor }, { borderColor }, { height }, sizeStyle]}
            disabled={!onPress}
            onPress={onPress}
            onLayout={(event) => { setHeight(event.nativeEvent.layout.width) }}
        >
            <View style={styles.titleContainer}>
                <Paragraph style={[styles.titleTextStyle, titleStyle, { color }]}>{title}</Paragraph>
            </View>
            <View style={styles.valueContainer}>
                <Paragraph style={[styles.valueTextStyle, valueStyle, { color }]}>{value}</Paragraph>
            </View>
        </TouchableOpacity>
    )
}
const styles = StyleSheet.create({
    kpiContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
        borderWidth: 1,
        maxHeight: 75,
        maxWidth: 75,
        flex: 1,
        margin: 4,
    },
    titleContainer: {
        position: 'absolute',
        top: 4,
    },
    valueContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleTextStyle: {
        textTransform: 'uppercase',
        fontSize: sizeS,
    },
    valueTextStyle: {
        fontSize: sizeXXL,
        fontWeight: 'bold',
    }
});
