import React, { useState, useEffect } from 'react';
import { View, FlatList, TouchableOpacity, StyleSheet } from 'react-native';
import { AntDesign, Feather, MaterialIcons  } from '@expo/vector-icons';
import { useSelector, useDispatch} from 'react-redux';
import CountDown from 'react-native-countdown-fixed';
import  { PlayerCardExtended } from '../Components/PlayerCardExtended';
import  { PlayerDetails }  from '../Components/PlayerDetails';
import { SeasonAPI } from '../API/Season';
import { black, primaryColor, thirdColor, white, grey } from '../Styles/colors';
import { strings, toLocaleDate } from '../locales/i18n';
import WeekPicker from '../Components/WeekPicker';
import { sizeM } from '../Styles/text';
import { BasketballCourt } from '../Components/BasketballCourt';
import { Center, PointGuard, PowerForward, ShootingGuard, SmallForward, Sub } from '../Components/Player';
import {MainContainer, ScrollViewMainContainer} from '../Components/ScrollViewMainContainer';
import { Paragraph, Title1, Title2, Title5 } from '../Components/Texts';
import { isEmptyObject, getOppositeColor } from '../Service/jsUtils';
import { globalStyles } from '../Styles/globals';
import BtxButton from '../Components/BtxButton';
import { actions } from '../Store/actions';
import { selectors } from '../Store/selectors';


const EMPTY_PLAYER = {};
const HIGHLIGHT_CAUSE = {
    CURRENT_PICK: 'CURRENT_PICK',
    STARTER_PICK__ALREADY_SELECTED_AS_STARTER: 'STARTER_PICK__ALREADY_SELECTED_AS_STARTER',
    SUBSTITUTE_PICK__ALREADY_SELECTED: 'SUBSTITUTE_PICK__ALREADY_SELECTED',
};

export default function LeagueCoachingHome({navigation}) {
    const dispatch = useDispatch();
    const basketixId = useSelector(state => state.authentication.basketixId);

    const league = useSelector(state => state.league);
    const seasonId = league.season.seasonId
    const basketBallCourtColor = useSelector(selectors.getBasketballCourt(seasonId, basketixId));
    const basketix = league.members[basketixId];
    const weeks = league.season.regularSeason.weeks.filter(week => week.isStarted === false);
    const teamName = basketix.teamName

    const [refresh, setRefresh] = useState(1);
    const [selectedWeek, setSelectedWeek] = useState(weeks[0]);
    const [teamPlayers, setTeamPlayers] = useState({
        PG:  [{ posId: 'PG', id: 'PG_0', name: strings('globals.PG'), player: EMPTY_PLAYER }],
        SG:  [{ posId: 'SG', id: 'SG_0', name: strings('globals.SG'), player: EMPTY_PLAYER }],
        SF:  [{ posId: 'SF', id: 'SF_0', name: strings('globals.SF'), player: EMPTY_PLAYER }],
        PF:  [{ posId: 'PF', id: 'PF_0', name: strings('globals.PF'), player: EMPTY_PLAYER }],
        C:   [{ posId: 'C' , id: 'C_0' , name: strings('globals.C') , player: EMPTY_PLAYER }],
        SUB: [ ],
    });
    const [roster, setRoster] = useState([]);
    const [isRosterLoading, setIsRosterLoading] = useState(false);
    const [isTeamLoading, setIsTeamLoading] = useState(false);
    const [pickerPosition, setPickerPosition] = useState(undefined);
    const backgroundColor = basketBallCourtColor.backgroundColor
    const paintColor = basketBallCourtColor.paintColor
    const linesColor = basketBallCourtColor.linesColor
    const threePointsColor = basketBallCourtColor.threePointsColor
    const courtColor = basketBallCourtColor.courtColor
    const sidePaintColor = basketBallCourtColor.sidePaintColor
    const linesWidth = basketBallCourtColor.linesWidth

    const selectedPlayersIds = Object.values(teamPlayers).flat().map(p => p.player.playerId).filter(p => !!p);
    const selectedSubstitutesIds = Object.values(teamPlayers.SUB).map(p => p.player.playerId).filter(p => !!p);
    const isPlayerPickerMode = pickerPosition !== undefined;

    if (selectedWeek === undefined) {
        return (<></>);
    }

    useEffect(() => {
        setIsRosterLoading(true);
        const getRosterCallData = {
            seasonId: seasonId,
            basketixIds: [basketixId],
            weekId: selectedWeek.weekId,
        };
        SeasonAPI.getRosters(getRosterCallData).then(data => {
            if(!data.error) {
                const roster = data[basketixId];
                setRoster(roster.players);
                setIsRosterLoading(false);
            } else {
                console.log(data.error);
                setIsRosterLoading(false);
            }
        }).catch((error) => {
            console.log(error);
            setIsRosterLoading(false);
        });

        setIsTeamLoading(true);
        const getTeamCallData = {
            seasonId: seasonId,
            basketixId: basketixId,
            weekId: selectedWeek.weekId,
        };
        SeasonAPI.getTeam(getTeamCallData).then(data => {
            if(!data.error) {
                const players = data.players;
                const starters = data.starters;

                let newTeamPlayers = {...teamPlayers};
                newTeamPlayers.PG[0].player = starters.PG ? players[starters.PG] : EMPTY_PLAYER;
                newTeamPlayers.SG[0].player = starters.SG ? players[starters.SG] : EMPTY_PLAYER;
                newTeamPlayers.SF[0].player = starters.SF ? players[starters.SF] : EMPTY_PLAYER;
                newTeamPlayers.PF[0].player = starters.PF ? players[starters.PF] : EMPTY_PLAYER;
                newTeamPlayers.C[0].player = starters.C ? players[starters.C] : EMPTY_PLAYER;

                const emptySubstitutes = new Array(data.nSubstitute).fill();
                const substitutes = data.substitutes.concat(emptySubstitutes).slice(0, data.nSubstitute);
                newTeamPlayers.SUB = substitutes.map((sub, index) => {
                    const player = sub ? players[sub] : EMPTY_PLAYER;
                    return {posId: 'SUB', id: `SUB_${index}`, name: strings('globals.SUB'), player }
                });

                setTeamPlayers(newTeamPlayers);
                setIsTeamLoading(false);
            } else {
                console.log(data.error);
                setIsTeamLoading(false);
            }
        }).catch((error) => {
            console.log(error);
            setIsTeamLoading(false);
        });
        SeasonAPI.getCourt({ basketixId, seasonId }).then(data => {
            if (!data.error) {
                dispatch({ type: actions.SET_BASKETBALL_COURT_COLOR, value: {...data, basketixId, seasonId} });
            } else {
                console.error(data.error);
            }
        }).catch(err => {
            console.error(err);
        });

    }, [selectedWeek, basketixId, league.season.seasonId, refresh]);

    const pickPlayer = (player) => {
        let newTeamPlayers = {...teamPlayers};

        // Add player into the position
        const index = newTeamPlayers[pickerPosition.posId].map(p => p.id).indexOf(pickerPosition.id);
        if (index !== -1) {
            newTeamPlayers[pickerPosition.posId][index].player = player;
        }

        // Remove player from other positions
        Object.keys(newTeamPlayers).forEach(position => {
            const posPlayers = newTeamPlayers[position];
            posPlayers.forEach((playerModel, i) => {
                if (playerModel.player.playerId === player.playerId && playerModel.id !== pickerPosition.id) {
                    newTeamPlayers[position][i].player = EMPTY_PLAYER;
                }
            });
        });

        // Finally
        setTeamPlayers(newTeamPlayers);
        saveTeam();
        setPickerPosition(undefined);
    };

    const goToLeagueCourt = () => {
        navigation.navigate(strings('navigation.LeagueCourt'));
    };

    const saveTeam = () => {
        const players = Object.keys(teamPlayers).map(position => {
            const isStarter = position !== 'SUB';
            const isSubstitute = position === 'SUB';
            return teamPlayers[position]
                .filter(playerModel => playerModel.player.playerId)
                .map(playerModel => ({
                    playerId: playerModel.player.playerId,
                    positions: isStarter ? [position] : playerModel.player.positions,
                    isStarter,
                    isSubstitute,
                }))
        })
        .flat()
        .reduce((o, player) => ({...o, [player.playerId]: player}), {});

        const data = {
            seasonId: seasonId,
            weekId: selectedWeek.weekId,
            players,
        };
        SeasonAPI.setTeam(data).then(data => {
            if (data.error) {
                setRefresh(refresh + 1);
            }
        }).catch(err => {
            setRefresh(refresh + 1);
        });
    };

    const availablePlayers = roster.filter((player) => {
        if (pickerPosition?.posId === 'SUB') {
            return true;
        }
        return pickerPosition && player.positions?.includes(pickerPosition.posId);
    });


    const trashJSX = (
        <Feather name="trash-2" size={22} color={ grey } />
    )

    const highlightCause = (player) => {
        const isPlayer = pickerPosition?.player?.playerId === player.playerId;
        const isAlreadySelected = selectedPlayersIds.includes(player.playerId);
        const isSubstitutePick = pickerPosition?.posId === 'SUB';
        const isAlreadySelectedAsSubstitute = selectedSubstitutesIds.includes(player.playerId);

        if (isPlayer) {
            return HIGHLIGHT_CAUSE.CURRENT_PICK;
        }

        if (isSubstitutePick && isAlreadySelected) {
            return HIGHLIGHT_CAUSE.SUBSTITUTE_PICK__ALREADY_SELECTED;
        }

        if (!isSubstitutePick && isAlreadySelected && !isAlreadySelectedAsSubstitute) {
            return HIGHLIGHT_CAUSE.STARTER_PICK__ALREADY_SELECTED_AS_STARTER;
        }
    }

    const highlightColor = (player) => {
        const cause = highlightCause(player);
        switch (cause) {
            case HIGHLIGHT_CAUSE.CURRENT_PICK:
                return primaryColor;
            case HIGHLIGHT_CAUSE.SUBSTITUTE_PICK__ALREADY_SELECTED:
            case HIGHLIGHT_CAUSE.STARTER_PICK__ALREADY_SELECTED_AS_STARTER:
                return grey;
            default:
                return grey;
        }
    }

    const isHighlight = (player) => {
        return highlightCause(player) !== undefined;
    }

    const displayPlayerChoice = () => {
        return (
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View style={styles.pickerHeader}>
                        <Title2 style={globalStyles.textAlignCenter}>{ strings('leagueCoaching.selectPlayerPosition', {playerPosition: pickerPosition.name}) }</Title2>
                        <View style={styles.pickerClose}>
                            <TouchableOpacity onPress={() => {setPickerPosition(undefined)}}>
                                <AntDesign name='closecircleo' size={16} color={black} />
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.pickerBody}>
                        <FlatList
                            data={availablePlayers}
                            keyExtractor={(item) => item.playerId}
                            renderItem={ ({item}) => (
                                <View style={styles.pickerItem} key={item.playerId}>
                                    <TouchableOpacity onPress={ () => {pickPlayer(item)} }>
                                        <PlayerCardExtended
                                            name={item.fullName}
                                            subtitle= {item.team.fullName + " | " + item.team.games.length + strings('leagueCoaching.games')}
                                            positions={item.positions}
                                            injury={item.injury}
                                            onPressActionButton={pickerPosition.player.playerId === item.playerId ? () => pickPlayer(EMPTY_PLAYER) : undefined}
                                            actionButtonIcon={trashJSX}
                                            isHighlight={isHighlight(item)}
                                            highlightColor={highlightColor(item)}
                                            details={
                                                <PlayerDetails
                                                    playerId={item.playerId}
                                                    displaySeason={true}
                                                    nGames={item.stats?.n}
                                                    mean={item.stats?.evaluationMean}
                                                    lastEvaluations={item.stats?.lastEvaluations}
                                                    injury={item.injury}
                                                    displayBid={false}
                                                    displayCut={false}
                                                    displayWeekDetails={true}
                                                    team={item.team}
                                                />
                                            }
                                        />
                                    </TouchableOpacity>
                                </View>
                            )}
                            horizontal={false}
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                    <View style={styles.pickerFooter}></View>
                </View>
            </View>
        )
    }

    const displayBasketballCourt = () => {
        return (
            <View>
                <BasketballCourt
                    teamName={teamName}
                    teamNameColor={getOppositeColor(backgroundColor)}
                    backgroundColor={backgroundColor}
                    courtColor={courtColor}
                    paintColor={paintColor}
                    threePointsColor={threePointsColor}
                    linesColor={linesColor}
                    sidePaintColor={sidePaintColor}
                    linesWidth={linesWidth}
                    FooterComponent={SubstitutesComponent()}
                >
                    <PointGuard
                        name={teamPlayers.PG[0].player?.fullName}
                        games={teamPlayers.PG[0].player?.games || teamPlayers.PG[0].player?.team?.games}
                        evaluation={teamPlayers.PG[0].player?.score}
                        onPress={() => {setPickerPosition(teamPlayers.PG[0])}}
                        isEmpty={isEmptyObject(teamPlayers.PG[0].player)}
                        isSelectable={true}
                        isLoading={isTeamLoading || isRosterLoading}
                    />
                    <ShootingGuard
                        name={teamPlayers.SG[0].player?.fullName}
                        games={teamPlayers.SG[0].player?.games || teamPlayers.SG[0].player?.team?.games}
                        evaluation={teamPlayers.SG[0].player?.score}
                        onPress={() => {setPickerPosition(teamPlayers.SG[0])}}
                        isEmpty={isEmptyObject(teamPlayers.SG[0].player)}
                        isSelectable={true}
                        isLoading={isTeamLoading || isRosterLoading}
                    />
                    <SmallForward
                        name={teamPlayers.SF[0].player?.fullName}
                        games={teamPlayers.SF[0].player?.games || teamPlayers.SF[0].player?.team?.games}
                        evaluation={teamPlayers.SF[0].player?.score}
                        onPress={() => {setPickerPosition(teamPlayers.SF[0])}}
                        isEmpty={isEmptyObject(teamPlayers.SF[0].player)}
                        isSelectable={true}
                        isLoading={isTeamLoading || isRosterLoading}
                    />
                    <PowerForward
                        name={teamPlayers.PF[0].player?.fullName}
                        games={teamPlayers.PF[0].player?.games || teamPlayers.PF[0].player?.team?.games}
                        evaluation={teamPlayers.PF[0].player?.score}
                        onPress={() => {setPickerPosition(teamPlayers.PF[0])}}
                        isEmpty={isEmptyObject(teamPlayers.PF[0].player)}
                        isSelectable={true}
                        isLoading={isTeamLoading || isRosterLoading}
                    />
                    <Center
                        name={teamPlayers.C[0].player?.fullName}
                        games={teamPlayers.C[0].player?.games || teamPlayers.C[0].player?.team?.games}
                        evaluation={teamPlayers.C[0].player?.score}
                        onPress={() => {setPickerPosition(teamPlayers.C[0])}}
                        isEmpty={isEmptyObject(teamPlayers.C[0].player)}
                        isSelectable={true}
                        isLoading={isTeamLoading || isRosterLoading}
                    />
                </BasketballCourt>
                <View style={styles.courtPersonalizationContainer}>
                    <BtxButton
                        title={strings("globals.courtPersonalization")}
                        onPress={goToLeagueCourt}
                        backgroundColor={backgroundColor}
                        color={getOppositeColor(backgroundColor)}
                        icon={<MaterialIcons name="colorize" size={16} color={ getOppositeColor(backgroundColor) } />}
                    />
                </View>
            </View>

        )
    }

    const SubstitutesComponent = () => {
        if (teamPlayers.SUB.length > 0) {
            const colorStyle = {color: getOppositeColor(backgroundColor)};
            return (
                <View style={styles.substitutesContainer}>
                    <View style={styles.substitutesTextContainer}>
                        <Title1 style={ [globalStyles.textAlignCenter, styles.substitutesTitle, colorStyle] }>{ strings('globals.SUBS') }</Title1>
                        <Paragraph style={ [colorStyle] }>{ strings('leagueCoaching.substitutesExplanation') }</Paragraph>
                    </View>
                    <FlatList
                            data={ teamPlayers.SUB }
                            keyExtractor={ item => item.id }
                            renderItem={ ({ item, index }) => (
                                <View key={index}>
                                    <Sub
                                        name={item.player?.fullName}
                                        games={item.player?.games || item.player?.team?.games}
                                        evaluation={item.player?.score}
                                        onPress={() => {setPickerPosition(item)}}
                                        isEmpty={isEmptyObject(item.player)}
                                        isSelectable={true}
                                        isLoading={isTeamLoading || isRosterLoading}
                                    />
                                </View>
                            )}
                            ItemSeparatorComponent={(<View style={{marginBottom: 10}}></View>)}
                            showsVerticalScrollIndicator={false}
                            style={ styles.substitutesCardsContainer }
                        />
                </View>
            )
        }
    }

    const displayCountdown = () => {
        if (pickerPosition === undefined && selectedWeek.startTimeUTC) {
            const date1 = new Date(selectedWeek.startTimeUTC);
            const date2 = Date.now();
            const numberSecondBeforeWeekStart = (date1 - date2) / 1000;
            return (
                <View key='countdown'>
                    <CountDown
                        until={numberSecondBeforeWeekStart}
                        onFinish={() => setRefresh(refresh+1)}
                        size={16}
                        timeToShow={['D','H','M', 'S']}
                        timeLabels={{
                            d: strings('leagueCoaching.days'),
                            h:strings('leagueCoaching.hours'),
                            m: strings('leagueCoaching.minutes'),
                            s: strings('leagueCoaching.seconds')
                        }}
                        digitStyle={styles.digitStyle}
                        digitTxtStyle={styles.digitTxtStyle}
                        timeLabelStyle={styles.digitTxtStyle}
                    />
                </View>
            );
        }
    }

    const weekText = strings('leagueCoaching.weekDates', {
        date1: toLocaleDate(selectedWeek.dates[0]),
        date2: toLocaleDate(selectedWeek.dates[selectedWeek.dates.length-1])
    });

    return (
        <ScrollViewMainContainer noPadding={true}>
            <MainContainer>
                <View style={[styles.contextContainer]}>
                    <View style={styles.contextTitle}>
                        <Title1>{strings('leagueCoaching.title')}</Title1>
                    </View>
                    <WeekPicker
                        weeks={weeks}
                        selectedWeek={selectedWeek}
                        onPress={ setSelectedWeek }
                    />
                    <View style={styles.weekTextContainer}>
                        <Title5>{ weekText }</Title5>
                    </View>
                    {displayCountdown()}
                </View>
            </MainContainer>
            {isPlayerPickerMode ? displayPlayerChoice() : displayBasketballCourt()}
        </ScrollViewMainContainer>
    )
};

const styles = StyleSheet.create({
    contextContainer: {
        marginBottom: 10,
    },
    contextTitle: {
        paddingBottom: 3,
    },
    contextWeeksPicker: {
        paddingBottom: 6,
    },
    contextWeekText: {
        paddingVertical: 6,
    },
    positionContainer: {
        marginBottom: 25,
    },
    saveContainer: {
        flexDirection: 'column',
        paddingVertical: 12,
    },
    pickerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingVertical: 8,
        marginBottom: 16,
    },
    pickerClose: {
        alignItems: 'center',
        marginLeft: 12,
    },
    pickerBody: {
        flex: 1,
        flexDirection: 'row',
    },
    pickerFooter: {
        flexDirection: 'row',
    },
    pickerItem: {
        justifyContent: 'flex-start',
        minHeight: 25,
        marginBottom: 3,
        flex : 1,
    },
    centeredView: {
        flex: 1,
        marginVertical: 12,
    },
    modalView: {
        flex: 1,
        padding: 16,
    },
    digitStyle: {
        borderWidth: 1,
        borderColor: thirdColor,
        marginTop: 8,
        marginLeft: 4,
        backgroundColor: white,
    },
    digitTxtStyle: {
        color: thirdColor
    },
    timeLabelStyle: {
        color: black,
        fontWeight: 'bold',
        fontSize: sizeM
    },
    weekTextContainer: {
        minHeight: 20,
        marginBottom: 4,
        justifyContent: 'flex-end',
    },
    substitutesContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
    },
    substitutesTextContainer : {
        width: '90%',
        marginVertical: 6,
    },
    substitutesCardsContainer : {
        width: '50%',
    },
    courtPersonalizationContainer: {
        flex: 1,
        paddingVertical: 10,
        alignItems: 'center'
    },
});
