import React from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { primaryColor, white } from '../Styles/colors';
import { sizeL, sizeXL } from '../Styles/text';
import { Paragraph } from './Texts';

export default function Slide({id, title, subtitle, description}) {
    const { width }  = useWindowDimensions();
    const isMobile = width < 768;

    const titleStyleSize = { fontSize: isMobile ? 28 : 62 };
    const subtitleStyleSize = { fontSize: isMobile ? 12 : 18 };
    const descriptionStyleSize = { fontSize: isMobile ? sizeL : sizeXL };

    return (
        <View style={styles.container} key={id}>
            <View style={styles.titleContainer}>
                <Paragraph style={[styles.subtitle, subtitleStyleSize]}>{subtitle}</Paragraph>
                <Paragraph style={[styles.title, titleStyleSize]}>{title}</Paragraph>
            </View>
            <View style={styles.contentContainer}>
                <Paragraph style={[styles.description, descriptionStyleSize]}>{description}</Paragraph>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        marginHorizontal: 60,
    },
    titleContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        paddingVertical: 4,
        width: '80%',
    },
    contentContainer: {
        flex: 1,
        justifyContent: 'flex-start',
        paddingVertical: 16,
        marginTop: 16,
    },
    title: {
        fontSize: 48,
        color: primaryColor,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: primaryColor,
    },
    subtitle: {
        fontSize: 18,
        color: primaryColor,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: white,
    },
    description: {
        color: white,
        fontSize: sizeXL,
    },
});
