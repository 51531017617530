import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Home from '../Screens/Home';
import LeagueCreate from '../Screens/LeagueCreate';
import LeagueJoin from '../Screens/LeagueJoin';
import LeagueLoading from '../Screens/LeagueLoading';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import Rules from '../Screens/Rules';
import { strings } from '../locales/i18n';

const Stack = createStackNavigator();

export default function HomeStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.HomePage")} component={Home} />
      <Stack.Screen name={strings("navigation.LeagueCreate")} component={LeagueCreate} />
      <Stack.Screen name={strings("navigation.LeagueJoin")} component={LeagueJoin} />
      <Stack.Screen name={strings("navigation.LeagueLoading")} component={LeagueLoading}/>
      <Stack.Screen name={strings("navigation.Rules")} component={Rules} />
    </Stack.Navigator>
  );
};
