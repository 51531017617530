const initialState = {
    isLogin: false,
    basketixId: undefined,
};

function authentication(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case 'AUTH_LOGIN':
            nextState = {
                ...state,
                isLogin: true,
            }
            return nextState || state;
        case 'SET_BASKETIX_ID':
                nextState = {
                    ...state,
                    basketixId: action.value,
                }
                return nextState || state;            
        case 'AUTH_LOGOUT':
            nextState = {...initialState};
            return nextState;
        default:
            return state;
    }
}

export default authentication;
