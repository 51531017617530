import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import {GamesScreen} from '../Screens/Games';
import { strings } from '../locales/i18n';

const Stack = createStackNavigator();

export function NbaGamesStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.NBAGames")} component={GamesScreen} />
    </Stack.Navigator>
  );
};
