import React from 'react';
import { useSelector } from 'react-redux'
import { white, thirdColor, secondaryColor, alphaColor } from '../Styles/colors';
import BtxButton from './BtxButton';
import { Live } from '../Components/Live';
import { strings } from '../locales/i18n';
import { PlayerCardExtended } from './PlayerCardExtended';
import { PlayerDetails } from './PlayerDetails';
import { Card } from './Card';

export default function DraftPick({ pickNumber, player, basketix, positions, cost, choices, onPress, isCurrentPick, isPastPick, isFinished }) {
    const basketixId = useSelector(state => state.authentication.basketixId);
    choices = choices || [];

    const isBasketixPick = basketix.basketixId === basketixId;
    const isFutureUserPick = isBasketixPick && !isCurrentPick && !isPastPick && !isFinished;

    const basketixNameText = !isBasketixPick ? basketix.name : strings('draftPick.you');
    const subtitle = 'Pick ' + pickNumber + ' | ' + basketix.teamName + ' | ' + basketixNameText;

    const emptyChoiceTitle = '- - - - - - - - -';

    if (isPastPick || isFinished) {
        if (player) {
            //Classic player card
            return (
                <PlayerCardExtended
                    name={player.fullName}
                    subtitle={subtitle}
                    positions={positions}
                    meta={`${cost} M$`}
                    injury={player.injury}
                    isHighlight={isBasketixPick}
                    highlightColor={secondaryColor}
                    details={
                        <PlayerDetails
                            playerId={player.playerId}
                            displaySeason={true}
                            nGames={player.stats?.n}
                            mean={player.stats?.evaluationMean}
                            lastEvaluations={player.stats?.lastEvaluations}
                            injury={player.injury}
                            displayBid={false}
                            displayCut={false}
                        />
                    }
                />
            );
        }

        //Empty card
        return (
            <PlayerCardExtended
                name={emptyChoiceTitle}
                subtitle={subtitle}
                positions={[]}
                meta={''}
            />
        );
    }

    if (isCurrentPick) {
        if (isBasketixPick) {
            return (
                <Card
                    title={''}
                    subtitle={subtitle}
                    extraTitleComponent={
                        <BtxButton
                            backgroundColor={thirdColor}
                            title= {strings('draftPick.draftPlayer')}
                            color={white}
                            onPress={onPress}
                            alignSelf={'flex-start'}
                        />
                    }
                    renderRight={<Live isVisible={true} />}
                    isHighlight={true}
                    highlightColor={thirdColor}
                    borderColor={thirdColor}
                    style={{backgroundColor: alphaColor(thirdColor, 10)}}
                />
            );
        }

        return (
            <Card
                title={emptyChoiceTitle}
                subtitle={subtitle}
                renderRight={<Live isVisible={true} />}
                isHighlight={true}
                highlightColor={thirdColor}
                borderColor={thirdColor}
                style={{backgroundColor: alphaColor(thirdColor, 10)}}
            />
        );
    }

    if (isFutureUserPick) {
        const hasPreDraftChoices = choices.length > 0;
        if (!hasPreDraftChoices) {
            return (
                <Card
                    title={''}
                    subtitle={subtitle}
                    extraTitleComponent={
                        <BtxButton
                            backgroundColor={secondaryColor}
                            title= {strings('draftPick.choosePlayer')}
                            color={white}
                            onPress={onPress}
                            alignSelf={'flex-start'}
                        />
                    }
                    isHighlight={isBasketixPick}
                    highlightColor={secondaryColor}
                    borderColor={isBasketixPick ? secondaryColor : undefined}
                    style={{backgroundColor: alphaColor(secondaryColor, 10)}}
                />
            );
        }

        return (
            <Card
                title={choices.map(choice => choice.fullName).join(', ')}
                subtitle={subtitle}
                renderRight={
                    <BtxButton
                        backgroundColor={secondaryColor}
                        title= {strings('draftPick.edit')}
                        color={white}
                        onPress={onPress}
                        alignSelf={'flex-start'}
                    />
                }
                isHighlight={isBasketixPick}
                highlightColor={secondaryColor}
                borderColor={isBasketixPick ? secondaryColor : undefined}
                style={{backgroundColor: alphaColor(secondaryColor, 10)}}

            />
        );
    }

    // Future other user pick
    return (
        <PlayerCardExtended
            name={emptyChoiceTitle}
            subtitle={subtitle}
            positions={[]}
            meta={''}
        />
    );
};
