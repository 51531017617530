import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import LeagueDraftHome from '../Screens/LeagueDraftHome';
import LeagueDraftChoice from '../Screens/LeagueDraftChoice';
import LeagueDraftPlayer from '../Screens/LeagueDraftPlayer';
import LeagueDraftFreeAgents from '../Screens/LeagueDraftFreeAgents';
import { strings } from '../locales/i18n';

const Stack = createStackNavigator();

export default function LeagueDraftStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.LeagueDraftHome")} component={LeagueDraftHome} />
      <Stack.Screen name={strings("navigation.LeagueDraftChoice")} component={LeagueDraftChoice} />
      <Stack.Screen name={strings("navigation.LeagueDraftPlayer")} component={LeagueDraftPlayer} />
      <Stack.Screen name={strings("navigation.LeagueDraftFreeAgents")} component={LeagueDraftFreeAgents} />
    </Stack.Navigator>
  );
};
