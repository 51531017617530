import React from 'react';
import { StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { white } from '../Styles/colors';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { sizeL } from '../Styles/text';

export default function BackButton() {
    const navigation = useNavigation();

    return (
        <TouchableOpacity style={styles.button} onPress={() => { navigation.goBack() }}>
            <AntDesign name="arrowleft" size={24} color={white} />
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    button: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        color: white,
        fontWeight: '100'
    },
    text: {
        fontWeight: 'bold',
        fontSize: sizeL,
        letterSpacing: 1,
        color: white,
    }
});
