import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Paragraph } from './Texts';

export function Cell({children, size, align, color, fontWeight, onPress}) {
    size = size ?? 1;
    align = align ?? 'center';
    const colorStyle = color ? {color} : {};
    const fontWeightStyle = fontWeight ? {fontWeight} : {};
    return (
        <TouchableOpacity style={[styles.cell, {flex: size, justifyContent: align}]} onPress={onPress} disabled={!onPress}>
            <Paragraph style={[styles.cellText, colorStyle, fontWeightStyle]}>{children}</Paragraph>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    cell: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 2,
        // backgroundColor: 'yellow', //tmp
    },
    cellText: {
        maxWidth: '100%',
        // backgroundColor: 'blue', //tmp
    },
});
