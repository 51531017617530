import React, {useState} from 'react';
import { View, ImageBackground, StyleSheet } from 'react-native';
import { black, primaryColor, transparent } from '../Styles/colors';
import { sizeL } from '../Styles/text';
import Circle from './Circle';
import { Title1 } from './Texts';

function HalfCircle({ size, style, direction }) {
    let rotate;
    switch(direction) {
        case 'left':
            rotate = '180deg';
            break;
        case 'right':
            rotate = '0deg';
            break;
        case 'top':
            rotate = '270deg';
            break;
        case 'bottom':
            rotate = '90deg';
            break;
        default:
            rotate = '0deg';
            break;
    }
    const halfCircle = {
        width: size,
        height: size * 2,
        borderRadius: size,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: black,
        transform: [ { rotate } ],
    }
    return (
        <View style={[halfCircle, style]} />
    )
}

export function BasketballCourt ({ teamName, teamNameColor, backgroundColor, courtColor, paintColor, sidePaintColor, paintBorderColor, threePointsColor, linesColor, linesWidth, FooterComponent, children }) {
    const [totalWidth, setTotalWidth] = useState(0);

    const onLayout = (event) => {
        const { width } = event.nativeEvent.layout;
        setTotalWidth(Math.min(width, 500));
    };

    const courtColorFinal = courtColorFinal ?? backgroundColor;
    const teamNameColorFinal = teamNameColor ?? black;
    const paintBorderColorFinal = paintBorderColor ?? paintColor;
    const threePointsColorFinal = threePointsColor ?? transparent;
    const linesColorFinal = linesColor ?? black;
    const linesWidthFinal = linesWidth ?? 3;

    const borderPaintColor = sidePaintColor ?? primaryColor;

    const courtWidth = totalWidth * 0.8;
    const courtHeight = courtWidth * 1.25;

    const styleCourtWithBorders = {
        backgroundColor: backgroundColor,
        width: totalWidth,
        minHeight: totalWidth * 1.1,
    }

    const styleCourt = {
        flex: 15,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        borderColor: linesColorFinal,
        borderWidth: linesWidthFinal,
        backgroundColor: courtColor,
    };

    const stylePaintContainer = {
        position: 'absolute',
        flexDirection: 'row',
        top: -linesWidthFinal,
    };
    const paintWidth = courtWidth * 0.4;
    const paintHeight = courtHeight / 2.5;
    const stylePaint = {
        width: paintWidth,
        height: paintHeight,
        borderWidth: linesWidthFinal,
        borderRightWidth: paintBorderColorFinal === paintColor ? 0 : undefined,
        borderLeftWidth: paintBorderColorFinal === paintColor ? 0 : undefined,
        borderColor: linesColorFinal,
        backgroundColor: paintColor,
    };
    const borderPaintWidth = courtWidth * 0.04;
    const stylePaintBorder = {
        width: borderPaintWidth,
        height: paintHeight,
        borderWidth: linesWidthFinal,
        borderColor: linesColor,
        backgroundColor: borderPaintColor,//borderPaintColor
    };

    const paintRoundSize = paintWidth / 2.5;
    const stylePaintRound = {
        position: 'absolute',
        borderWidth: linesWidthFinal,
        borderColor: linesColorFinal,
        backgroundColor: transparent,
    };
    const stylePaintRoundTop = {
        ...stylePaintRound,
        borderStyle: 'solid',
        top: (paintHeight) - (paintRoundSize / 2) - paintRoundSize - linesWidthFinal,
    };
    const stylePaintRoundBottom = {
        ...stylePaintRound,
        borderStyle: 'solid',
        top: paintHeight - (paintRoundSize / 2) - 2 * linesWidthFinal,
    };

    const styleGlass = {
        position: 'absolute',
        top: paintHeight / 10,
        width: paintWidth / 2,
        height: linesWidthFinal,
        backgroundColor: linesColorFinal,
    };

    const basketSize = styleGlass.width / 3;
    const styleBasket = {
        position: 'absolute',
        top: paintHeight / 10 + (linesWidthFinal * 0.8),
        borderWidth: linesWidthFinal,
        borderColor: linesColorFinal,
        backgroundColor: transparent,
    };

    const widthThreePointLinesRound = courtWidth * 0.85;
    const styleThreePointLinesRound = {
        position: 'absolute',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        top: -linesWidthFinal,
        borderBottomRightRadius: widthThreePointLinesRound / 2,
        borderBottomLeftRadius: widthThreePointLinesRound / 2,
        borderWidth: linesWidthFinal,
        borderColor: linesColorFinal,
        backgroundColor: threePointsColorFinal,
    };

    const centerCourtSize = courtWidth * (1/6);
    const styleCenterCourt = {
        position: 'absolute',
        backgroundColor: transparent,
        borderWidth: linesWidthFinal,
        borderColor: linesColorFinal,
        bottom: -(centerCourtSize/2) - linesWidthFinal,
    };

    return (
        <View onLayout={(event) => {onLayout(event)}}>
            <View style={{alignItems: 'center'}}>
                <View key="globalCourt"  style={[styles.styleCourtWithBorders, styleCourtWithBorders]}>
                    <View key='topCourtBorder' style={styles.topCourtBorder}>
                        <Title1 style={[styles.courtTextUpper, {color: teamNameColorFinal}]}>{teamName}</Title1>
                    </View>
                    <View key='middleCourt' style={styles.middleCourt}>
                        <View key="leftCourtBorder" style={styles.horizontalCourtBorder}/>
                        <View key="court" style={[styleCourt]}>
                                <Circle key='threePointLine' size={widthThreePointLinesRound} shapeStyle={styleThreePointLinesRound} />

                                <View key='paint' style={stylePaintContainer}>
                                    <View style={[stylePaintBorder, {borderRightWidth: 0}]} />
                                    <View style={[stylePaint]} />
                                    <View style={[stylePaintBorder, {borderLeftWidth: 0}]} />
                                </View>

                                <View key='glass' style={[styleGlass]} />
                                <Circle key='basket' size={basketSize} shapeStyle={styleBasket} />

                                <HalfCircle key='paintCircleBottom' size={paintRoundSize} style={stylePaintRoundBottom} direction={'bottom'} />
                                <HalfCircle key='paintCircleTop' size={paintRoundSize} style={stylePaintRoundTop} direction={'top'} />

                                <HalfCircle key='halfCourtHalfCircle' size={centerCourtSize} style={styleCenterCourt} direction={'top'} />
                                <ImageBackground source={require('../assets/img/parquet.png')} style={styles.parquetBackground} imageStyle={styles.parquetImage} />
                        </View>
                        <View key="rightCourtBorder" style={styles.horizontalCourtBorder}/>
                    </View>
                    <View key='bottomCourtBorder' style={styles.bottomCourtBorder}>
                    </View>
                    {children}
                </View>
                <View onLayout={(event) => {onLayout(event)}} style={[styles.styleCourtWithBorders, styleCourtWithBorders, styles.footerContainer]}>
                    {FooterComponent}
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    styleCourtWithBorders: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 0,
    },
    topCourtBorder: {
        flex: 1,
        justifyContent: 'center'
    },
    bottomCourtBorder: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    middleCourt: {
        flex: 10,
        flexDirection: 'row',
        width: '100%'
    },
    horizontalCourtBorder: {
        flex: 1,
        flexDirection: 'column',
    },
    courtTextUpper: {
        fontSize: sizeL,
    },
    parquetBackground: {
        position: 'absolute',
        width:'100%',
        height: '100%',
    },
    parquetImage: {
        resizeMode: 'repeat',
        opacity: 0.1,
    },
    footerContainer: {
        minHeight: 0,
        paddingBottom: 10,
    },

});
