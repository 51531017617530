import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { black, primaryColor, silver, transparent, white } from '../Styles/colors';
import { globalStyles } from '../Styles/globals';
import { sizeL } from '../Styles/text';
import { Separator } from './Separator';
import { Checkbox } from './Checkbox';
import { Title2, Title4, Title5 } from './Texts';
import BtxButton from './BtxButton';

function ToggleIcon({ onPress, isOpen, isVisible }) {
    if (!onPress) {
        return (<></>)
    }

    const iconName = isOpen ? 'down' : 'right';

    const color = isVisible === false ? transparent : black;

    return (
        <TouchableOpacity
            disabled={!onPress || isVisible === false}
            onPress={onPress}
            style={styles.toggleContainer}
        >
            <AntDesign name={iconName} size={sizeL} color={color} />
        </TouchableOpacity>
    )
};

function Details({ render, isVisible, onPress}) {
    if (!isVisible) {
        return (<></>)
    }

    return (
        <View style={styles.details}>
            <ToggleIcon onPress={onPress} isOpen={false} isVisible={false} />
            { render }
        </View>
    )
}


/*
Card that display information all over the app

@renderTitle: React Native component for the title part of the card.
@renderSubtitle: React Native component for the subtitle part of the card.
@renderRight: React Native component for the right part of the card.
@details: optional. React Native component for the detail panel.
@open: optional. Is the detail panel is open by default ?
@hiddenDetails: optional. Is the detail panel is hidden ?
@selectable: optional. Can the card be selected i.e does the card have a checkbox ?
@isSelected: optional. Is the player selected ?
@onSelect: optional. Callback method when select/unselect the player.
@selectDisable: optional. Is the selection of player is disable ?
@isHighlight: optional: Should the component & highlight the player name ith color and weight ?
@highlightColor: optional. Specific color for highlight items.
@borderColor: optional. Specific border color.
@style: optional. Custom style.
*/
export function Card({
    title, extraTitleComponent, subtitle, thirdTitle, renderRight, details, open,
    hiddenDetails, shadow, selectable, isSelected, onSelect, selectDisable,
    isHighlight, highlightColor, borderColor, style}) {

    const [isOpen, setIsOpen] = useState(open && details ? open : false);

    const onPress = details && !hiddenDetails ? () => {setIsOpen(!isOpen)} : undefined;

    const containerStyle = [styles.container];
    if (borderColor) {
        containerStyle.push({borderColor});
    }
    if (shadow || shadow === undefined) {
        containerStyle.push(globalStyles.shadow);
    }
    containerStyle.push(style);

    const _highlightColor = highlightColor || primaryColor;
    const titleStyle = isHighlight || isSelected ? [styles.highlightedStyle, {color: _highlightColor}] : undefined;

    const thirdTitleJSX = thirdTitle ? (<View><Title4 style={styles.thirdTitleStyle} numberOfLines={1}>{thirdTitle}</Title4></View>) : (<></>);


    const checkboxJSX = selectable ? (
        <View style={styles.checkboxContainer}>
            <Checkbox value={isSelected} onPress={onSelect}></Checkbox>
        </View>
    ) : (<></>);

    return (
        <View style={containerStyle}>
            <View style={[styles.header]}>
                <View style={[styles.headerMain]}>
                    <ToggleIcon onPress={onPress} isOpen={isOpen} />
                    <View style={[styles.headerMainText]}>
                        <View style={[globalStyles.flexRow, globalStyles.alignCenter, globalStyles.width100]}>
                            <View style={[globalStyles.flexRow, globalStyles.alignFlexEnd, globalStyles.width100, globalStyles.flex]}>
                                <View style={[globalStyles.flexRow, globalStyles.alignCenter, styles.titleContainer]}>
                                    <Title2 style={[titleStyle]} numberOfLines={1}>{title}</Title2>
                                    {extraTitleComponent}
                                    {thirdTitleJSX}
                                </View>
                            </View>
                        </View>
                        <View style={[globalStyles.flexRow, globalStyles.alignCenter]}>
                            <Title5>{subtitle}</Title5>
                        </View>
                    </View>
                </View>
                <View style={[styles.headerRight]}>
                    {renderRight}
                    {checkboxJSX}
                </View>
            </View>
            <Separator isVisible={isOpen} />
            <Details
                render={details}
                isVisible={isOpen}
                onPress={onPress}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignSelf: 'stretch',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        padding: 12,
        marginVertical: 8,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: silver,
        backgroundColor: white,
        minWidth: 200,
        maxWidth: 650,
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginVertical: 4,
        minHeight: 52,
    },
    headerMain: {
        flex: 5,
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    headerRight: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
        paddingLeft: 4,
    },
    headerMainText: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        paddingRight: 4,
    },
    checkboxContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        paddingLeft: 8,
    },
    highlightedStyle: {
        fontWeight: 'bold',
    },
    titleContainer: {
        flex: 8,
        width: '100%',
    },
    thirdTitleStyle: {
        flex: 1,
    },

    //Details
    details: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        paddingHorizontal: 4,
        paddingBottom: 8,
    },

    //Toggle
    toggleContainer: {
        padding: 8,
        marginRight: 4,
    },
});
