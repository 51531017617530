const initialState = {
    isLoad: false,
    isNbaPlayersLoad: false,
    isNbaTeamsLoad: false,
    leagueIdToLoad: undefined,
    loadingId: undefined,
};

function appLoader(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case 'UPDATE_APP_LOADER':
            nextState = {
                ...state,
                ...action.value,
            }
            return nextState || state;
        default:
            return state;
    }
}

export default appLoader;
