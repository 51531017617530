import React from 'react';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph } from '../Components/Texts';

export default function LeagueDraftPlayer() {
    return (
        <ScrollViewMainContainer>
            <Paragraph>LeBron James</Paragraph>
        </ScrollViewMainContainer>
    )
};
