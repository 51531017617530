import basketixApiCall from './BasketixApi';

export const rankingSeason = async(seasonId) => {
    const endpoint = '/basketix/season/ranking/v2';
    const body = {
        seasonId: seasonId,
    };
    const ranking = await basketixApiCall(endpoint, body, 'POST');
    return ranking;
};

export const rankingSeasonWeek = async(seasonId, weekId) => {
    const endpoint = '/basketix/season/ranking/v2/week';
    const body = {
        seasonId: seasonId,
        weekId: weekId,
    };
    const rankingWeek = await basketixApiCall(endpoint, body, 'POST');
    return rankingWeek;
};

