import basketixApiCall from './BasketixApi';

export const last = async() => {
    const endpoint = '/nba/games/last';
    const lastGames = await basketixApiCall(endpoint, undefined, 'GET');

    return lastGames;
};

export const from = async(date) => {
  const endpoint = `/nba/games/last?date=${date}`;
  const games = await basketixApiCall(endpoint, undefined, 'GET');

  return games;
};

export const NBAGamesAPI = {
  last,
  from,
};
