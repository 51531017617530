import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { globalStyles } from '../Styles/globals';
import { MainKpi } from '../Components/MainKpi';
import { MainKpiBar } from '../Components/MainKpiBar';
import { strings } from '../locales/i18n';
import { Evaluations } from './Evaluation';
import { sizeL } from '../Styles/text';

export function PlayerSeasonDetails({ playerId, mean, count, lastEvaluations, injury }) {
    const [meanFinal, setMeanFinal] = useState(mean);
    const [countFinal, setCountFinal] = useState(count);
    const [lastEvaluationsFinal, setLastEvaluationsFinal] = useState(lastEvaluations || []);
    const [injuryFinal, setInjuryFinal] = useState(injury);

    useEffect(() => {
        if(!meanFinal) {
            setMeanFinal('-');
        }
        if(!countFinal) {
            setCountFinal('-');
        }
        if(!lastEvaluationsFinal) {
            setLastEvaluationsFinal([]);
        }
        if(injuryFinal === undefined) {
            setInjuryFinal({});
        }
    }, [])

    const lastEvaluationsValue = lastEvaluationsFinal.length > 0 ? <Evaluations playerId={playerId} evaluations={lastEvaluationsFinal}/> : '-';
    const injuryValue = injuryFinal?.return || '-';

    return (
        <View style={[globalStyles.flex]}>
            <MainKpiBar style={globalStyles.bgTransparent}>
                <MainKpi title={strings('playerSeasonDetails.lastEvals')} renderValue={lastEvaluationsValue} hasSeparator={false} />
                <MainKpi title={strings('playerSeasonDetails.injury')} value={injuryValue} hasSeparator={false} valueStyle={styles.injuryText}/>
            </MainKpiBar>
            <MainKpiBar style={globalStyles.bgTransparent}>
                <MainKpi title={strings('playerSeasonDetails.evalMean')} value={meanFinal} hasSeparator={false} />
                <MainKpi title={strings('playerSeasonDetails.gamesPlayed')} value={countFinal} hasSeparator={false} />
            </MainKpiBar>
        </View>
    );
}

const styles = StyleSheet.create({
    injuryText: {
        fontSize: sizeL,
    },
});
