import { seasonSettingsActions } from './season-settings/actions';

const UPDATE_USER_LEAGUE = 'UPDATE_USER_LEAGUE';
const SET_USER_INFOS = 'SET_USER_INFOS';
const SET_USER_IS_LOADING = 'SET_USER_IS_LOADING';

const LOAD_DRAFT_FREE_AGENTS = 'LOAD_DRAFT_FREE_AGENTS';
const LOAD_DRAFT_FREE_AGENTS_SUCCESS = 'LOAD_DRAFT_FREE_AGENTS_SUCCESS';
const LOAD_DRAFT_FREE_AGENTS_FAILURE = 'LOAD_DRAFT_FREE_AGENTS_FAILURE';

const LOAD_CAP_SPACE = 'LOAD_CAP_SPACE';
const LOAD_CAP_SPACE_SUCCESS = 'LOAD_CAP_SPACE_SUCCESS';
const LOAD_CAP_SPACE_FAILURE = 'LOAD_CAP_SPACE_FAILURE';

const SET_BASKETBALL_COURT_COLOR = 'SET_BASKETBALL_COURT_COLOR';

export const actions = {
    UPDATE_USER_LEAGUE,
    SET_USER_INFOS,
    SET_USER_IS_LOADING,
    LOAD_DRAFT_FREE_AGENTS,
    LOAD_DRAFT_FREE_AGENTS_SUCCESS,
    LOAD_DRAFT_FREE_AGENTS_FAILURE,
    LOAD_CAP_SPACE,
    LOAD_CAP_SPACE_SUCCESS,
    LOAD_CAP_SPACE_FAILURE,
    SET_BASKETBALL_COURT_COLOR,
    seasonSettings: seasonSettingsActions,
};
