import basketixApiCall from './BasketixApi';

const getNbaTeamsGlobalInfos = async() => {
    const endpoint = '/nba/team/list';
    const nbaTeams = await basketixApiCall(endpoint, undefined, 'GET');
    return nbaTeams;
};

export const NBATeamsAPI = {
    getNbaTeamsGlobalInfos,
};
