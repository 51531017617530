import React, { useState, useEffect,  } from 'react';
import { View, SectionList, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesome, Entypo, AntDesign } from '@expo/vector-icons';
import { strings } from '../locales/i18n';
import { getDraft } from '../API/BasketixSeasonsDraft';
import { DraftAPI } from '../API/Draft';
import DraftPick from '../Components/DraftPick';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { DraftRoundHeader } from '../Components/DraftRoundHeader';
import { black, transparent, primaryColor, secondaryColor, alphaColor } from '../Styles/colors';
import BtxButton from '../Components/BtxButton';
import { sizeM, sizeXXXL } from '../Styles/text';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { globalStyles } from '../Styles/globals';
import { SeasonAPI } from '../API/Season';
import { Paragraph, Title2 } from '../Components/Texts';
import { TouchableOpacity } from 'react-native-web';
import { actions } from '../Store/actions';

function HelpContent() {
    const [hidden, setHidden] = useState(false);

    if (hidden) {
        return (<></>);
    }

    return (
        <View style={ styles.helpStyle }>
            <View style={ styles.helpContent }>
                <View style={styles.helpIcon}>
                    <Entypo name="light-bulb" size={sizeXXXL} color={primaryColor} />
                </View>
                <View style={styles.helpText}>
                    <Title2 style={styles.helpStyleTitle}>{strings('draftHome.advices')}</Title2>
                    <Paragraph>{strings('draftHome.positionAdvice')}</Paragraph>
                    <Paragraph>{strings('draftHome.salaryCapAdvice')}</Paragraph>
                    <Paragraph>{strings('draftHome.preDraft')}</Paragraph>
                </View>
                <View style={styles.closeHelpContainer}>
                    <TouchableOpacity style={styles.closeHelpButton} onPress={() => setHidden(true)}>
                        <AntDesign name='close' size={sizeM} color={primaryColor} />
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}


export default function LeagueDraftHome({ navigation }) {
    const dispatch = useDispatch();

    const basketixId = useSelector(state => state.authentication.basketixId);
    const league = useSelector(state => state.league);
    const freeAgents = useSelector(state => state.draft.freeAgents);
    const capSpace = useSelector(state => state.draft.capSpace);
    const isLoadingCapSpace = useSelector(state => state.draft.isLoadingCapSpace);
    const players = useSelector(state => state.players);
    const playerIds = Object.keys(players);

    const defaultSelectedRounds = ['1', '2'];

    const [draftStatus, setDraftStatus] = useState({});
    const [selectedRounds, setSelectedRounds] = useState(undefined);
    const [draftByRound, setDraftByRound] = useState([]);
    const [isLoadingDraft, setIsLoadingDraft] = useState(false);
    const [refresh, setRefresh] = useState(1);

    useEffect (() => {
        if (league.shouldLoadDraft) {
            setIsLoadingDraft(true);
            setSelectedRounds(defaultSelectedRounds);
            const getDraftBody = { seasonId: league.season.seasonId };
            getDraft(getDraftBody).then(data => {
                if (! data.error) {
                    setDraftStatus(data.status);
                    if (data.status.round) {
                        setSelectedRounds([data.status.round.toString()]);
                    }
                    const draftOrder = data.order.map(pick => {
                        pick.player = undefined;
                        if (pick.playerId) {
                            const hasPlayerId = playerIds.indexOf(pick.playerId) !== -1;
                            if (hasPlayerId) {
                                pick.player =  players[pick.playerId];
                            } else {
                                pick.player = {fullName: 'Player ' + pick.playerId};
                            }
                        }

                        pick.basketix = league.members[pick.basketixId];
                        pick.basketix.basketixId = pick.basketixId;

                        if (pick.predraft) {
                            const choices = pick.predraft.choices.map(playerId => {
                                const hasPlayerId = playerIds.indexOf(playerId) !== -1;
                                if (hasPlayerId) {
                                    return players[playerId];
                                } else {
                                    return {fullName: 'Player ' + playerId};
                                }
                            });
                            pick.predraft.choices = choices;
                        }

                        return pick;
                    });

                    dispatch({ type: 'UPDATE_LEAGUE', value: {shouldLoadDraft: false}});

                    // Shape for the section list
                    let draftByRound = [];
                    draftOrder.map((pick) => {
                        const roundIndex = draftByRound.map(item => item.title).indexOf(pick.round.toString());
                        if (roundIndex === -1) {
                            draftByRound.push({
                                title: pick.round.toString(),
                                data: [pick],
                            })
                        } else {
                            draftByRound[roundIndex].data.push(pick);
                        }
                    });
                    setDraftByRound(draftByRound);
                    setIsLoadingDraft(false);
                } else {
                    console.log(data.error);
                }
            });

            if (league.statusId == 'DRAFT') {
                const getFreeAgentBody = { seasonId: league.season.seasonId };
                dispatch({ type: actions.LOAD_DRAFT_FREE_AGENTS });
                DraftAPI.getFreeAgents(getFreeAgentBody).then(data => {
                    if (!data.error) {
                        dispatch({ type: actions.LOAD_DRAFT_FREE_AGENTS_SUCCESS, payload: data.freeagents });
                    } else {
                        console.log(data.error);
                        dispatch({ type: actions.LOAD_DRAFT_FREE_AGENTS_FAILURE });
                    }
                }).catch(error => {
                    console.error(error);
                    dispatch({ type: actions.LOAD_DRAFT_FREE_AGENTS_FAILURE });
                })

                dispatch({ type: actions.LOAD_CAP_SPACE });
                const getCapSpacesBody = { seasonId: league.season.seasonId };
                SeasonAPI.capSpaces(getCapSpacesBody).then(data => {
                    if (! data.error) {
                        dispatch({ type: actions.LOAD_CAP_SPACE_SUCCESS, payload: data[basketixId].capSpace });
                    } else {
                        console.log(data.error);
                        dispatch({ type: actions.LOAD_CAP_SPACE_FAILURE });
                    }
                }).catch(error => {
                    console.error(error);
                    dispatch({ type: actions.LOAD_CAP_SPACE_FAILURE });
                })
            }
        }
    }, [refresh, league]);

    const toggleRound = (round) => {
        let newSelectedRounds = [...selectedRounds];
        const index = newSelectedRounds.indexOf(round);
        if (index === -1) {
            newSelectedRounds.push(round);
        } else {
            newSelectedRounds = newSelectedRounds.filter(selectedRound => selectedRound !== round);
        }
        newSelectedRounds.sort((a, b) => parseInt(a) - parseInt(b));
        setSelectedRounds(newSelectedRounds);
    };

    const goToDraftChoice = (pick) => {
        const freeAgentIds = freeAgents.map(fa => fa.playerId);
        const choices = pick.predraft?.choices
                                      .filter(choice => freeAgentIds.indexOf(choice.playerId) !== -1)
                                      .map(choice => ({ ...choice, ...freeAgents[freeAgentIds.indexOf(choice.playerId)]}));
        dispatch({ type: 'INIT_DRAFT_CHOICES', value: choices });
        navigation.push(strings('navigation.LeagueDraftChoice'), {
            pickNumber: pick.pickNumber,
            round: pick.round,
            roundPick: pick.roundPick,
            isCurrentChoice: pick.pickNumber === draftStatus.pickNumber,
            isAuto: pick.predraft.auto,
        });
    };

    const filterFreeAgents = (predraft) => {
        const freeAgentIds = freeAgents.map(choice => choice.playerId);
        return predraft?.choices
                        .filter(choice => freeAgentIds.indexOf(choice.playerId) !== -1)
                        .map(choice => ({ ...choice, ...freeAgents[freeAgentIds.indexOf(choice.playerId)]}));
    };

    const selectedDraftByRound = [...draftByRound].map(section => {
        return {
            title: section.title,
            data: selectedRounds.indexOf(section.title) === -1 ? [] : section.data,
        }
    });

    const displayLiveDraft = () => {
        if(isLoadingDraft || draftStatus.isFinished) {
            return(<></>)
        }

        const capSpaceJSX = !isLoadingCapSpace && capSpace ? (
            <Title2 style={globalStyles.titleText2}>{ strings('draftHome.capSpaceEnable', {capSpace}) }</Title2>
        ) : <></>;

        return(
            <View>
                <HelpContent />
                <View style={ styles.controlContainer }>
                    <View style={ styles.capSpaceContainer }>
                        { capSpaceJSX }
                    </View>
                    <View style={ styles.refreshContainer }>
                        <BtxButton
                            title= {strings('draftHome.refresh')}
                            backgroundColor={ transparent }
                            color={ black }
                            onPress={() => {
                                league.shouldLoadDraft=true
                                setRefresh(refresh+1)
                            }}
                            height={ 30 }
                            icon={<FontAwesome name="refresh" size={sizeM} color={black} />}
                            />
                    </View>
                </View>
            </View>

        );
    };

    const displayDraft = () => {
        if (isLoadingDraft || draftByRound.length === 0) {
            return (<BtxActivityIndicator size='small' />);
        } else {
            return (
                <SectionList
                    sections={ selectedDraftByRound }
                    keyExtractor={(item, index) => item + index}
                    renderItem={({ item }) =>
                        <DraftPick
                            pickNumber={ item.roundPick }
                            player={ item.player }
                            basketix={ item.basketix }
                            positions={ item.positions }
                            cost={ item.cost }
                            choices={ filterFreeAgents(item.predraft) }
                            onPress={ () => {goToDraftChoice(item)} }
                            isCurrentPick={ item.pickNumber === draftStatus.pickNumber }
                            isPastPick={ item.pickNumber < draftStatus.pickNumber }
                            isFinished={draftStatus.isFinished}
                        />
                    }
                    renderSectionHeader={({ section: { title } }) => (
                        <DraftRoundHeader
                            roundNumber={ title }
                            onPress={ toggleRound }
                            isOpen={ selectedRounds.indexOf(title) !== -1 }
                            isLive={ draftStatus.round === parseInt(title) }
                        />
                    )}
                    showsVerticalScrollIndicator={false}
                    initialNumToRender={10}
                />
            )
        }
    };

    return (
        <ScrollViewMainContainer>
            { displayLiveDraft() }
            { displayDraft() }
        </ScrollViewMainContainer>
    )
};

const styles = StyleSheet.create({
    controlContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 4,
    },
    capSpaceContainer: {
        flex: 2,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    refreshContainer: {
        flex: 1,
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    helpStyle : {
        borderColor: alphaColor(primaryColor, 45),
        borderWidth: 2,
        flexDirection: 'column',
        backgroundColor: alphaColor(primaryColor, 25),
        borderRadius:8,
    },
    helpTitleZone: {
        flex: 1,
    },
    helpContent: {
        flex: 1,
        flexDirection: 'row',
    },
    helpStyleTitle : {
        marginVertical:4,
        color: black,
        textAlign: 'left'
    },
    helpIcon: {
        flex:1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    helpText: {
        flex: 8,
        textAlign: 'left',
        marginBottom:4,
    },
    closeHelpContainer: {
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    closeHelpButton: {
        padding: 10,
    }
});
