import AsyncStorage from '@react-native-async-storage/async-storage';
import { config } from '../Config/factory';
import { showMessage } from "react-native-flash-message";
import I18n from '../locales/i18n';

export default function basketixApiCall (endpoint, body, method, customHeaders) {
    const url = config.basketixApiHost + endpoint;

    return new Promise(async (resolve, reject) => {
      const token = await AsyncStorage.getItem('userToken');
      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
      };
      if (token !== null) { headers.Authorization = token }
      if (I18n.locale) { headers['Btx-Language'] = I18n.locale.split('-')[0] }
      if (customHeaders) { headers = { ...headers, ...customHeaders } }

      fetch(url, {
        method: method.toUpperCase() ,
        headers: headers,
        body: JSON.stringify(body),
      }).then((response) => {
        if (!response.ok) {
          throw response;
        }
        resolve(response.json());
      }).catch((error) => {
        error.json().then( errorMessage => {
          resolve({error: errorMessage});
          showMessage({
            message: errorMessage.message,
            type: 'danger',
            icon: 'danger',
          });
        })
      })
    })
};
