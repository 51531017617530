import React from 'react';
import { StyleSheet, View } from 'react-native';
import { silver } from '../Styles/colors';

export function Row({children, lastRow}) {
    let lastRowStyle = {};
    if (lastRow) {
        lastRowStyle = {
            borderBottomWidth: 0,
        };
    }

    return (
        <View style={[styles.row, lastRowStyle]}>
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        paddingBottom: 4,
        marginBottom: 4,
        borderBottomWidth: 1,
        borderBottomColor: silver,
        // backgroundColor: 'grey', //tmp
    },
});
