export function quantity(numberMin, numberMax) {
    const selectObject = []
    for (let i = numberMin; i <= numberMax; i++) {
        selectObject.push({ label: i.toString(), value: i , key:i})
    }
    return selectObject;
};

export function indexObjectValue(value, list) {
    let i = 0;
    let index = 0;
    for (let obj in list) {
        if (list[i].value==value) {
            index = i;
        }
        i += 1;
    }
    return index;
};
