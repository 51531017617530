import React from 'react';
import { StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { red } from '../Styles/colors';
import { sizeXXL } from '../Styles/text';
import { View } from 'react-native-web';


export function InjuryIcon({ visible }) {
    if (!visible) {
        return (<></>)
    }

    return (
        <View style={styles.icon}>
            <MaterialIcons name='local-hospital' size={sizeXXL} color={red} />
        </View>
    )
}

const styles = StyleSheet.create({
    icon: {
        marginHorizontal: 2
    }
})
