import { actions } from "../actions";

const initialState = {
    choices: [],
    freeAgents: [],
    isLoadingFreeAgents: false,
    capSpace: undefined,
    isLoadingCapSpace: false,
};

function draft(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case 'UPDATE_DRAFT_CHOICES':
            nextState = {...state};
            nextState.choices = action.value
            return nextState || state;
        case 'INIT_DRAFT_CHOICES':
            nextState = {...state};
            nextState.choices = action.value;
            return nextState || state;
        case actions.LOAD_DRAFT_FREE_AGENTS:
            nextState = {
                ...state,
                isLoadingFreeAgents: true,
            };
            return nextState || state;
        case actions.LOAD_DRAFT_FREE_AGENTS_SUCCESS:
            nextState = {
                ...state,
                isLoadingFreeAgents: false,
                freeAgents: action.payload,
            };
            return nextState || state;
        case actions.LOAD_DRAFT_FREE_AGENTS_FAILURE:
                nextState = {
                    ...state,
                    isLoadingFreeAgents: false,
                };
                return nextState || state;
        case actions.LOAD_CAP_SPACE:
            nextState = {
                ...state,
                isLoadingCapSpace: true,
            };
            return nextState || state;
        case actions.LOAD_CAP_SPACE_SUCCESS:
            nextState = {
                ...state,
                isLoadingCapSpace: false,
                capSpace: action.payload,
            };
            return nextState || state;
        case actions.LOAD_CAP_SPACE_FAILURE:
                nextState = {
                    ...state,
                    isLoadingCapSpace: false,
                };
                return nextState || state;
        default:
            return state;
    }
}

export default draft;
