import basketixApiCall from './BasketixApi';

const cutPlayer = async(data) => {
    const endpoint = '/basketix/season/market/cut';
    const {seasonId, playerId} = data;
    const body = { seasonId, playerId };

    const isCut = await basketixApiCall(endpoint, body, 'POST');

    return isCut;
};

const bid = async(data) => {
    const endpoint = '/basketix/season/market/freeagent/bid';
    const {seasonId, playerId, cost} = data;
    const body = { seasonId, playerId, cost };

    const bid = await basketixApiCall(endpoint, body, 'POST');

    return bid;
};

const withdraw = async(data) => {
    const endpoint = '/basketix/season/market/freeagent/withdraw';
    const {seasonId, playerId} = data;
    const body = { seasonId, playerId };

    const withdraw = await basketixApiCall(endpoint, body, 'POST');

    return withdraw;
};


export const MarketAPI = {
    cutPlayer,
    bid,
    withdraw,
};
