import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import LeagueLoading from '../Screens/LeagueLoading';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import { strings } from '../locales/i18n';

const Stack = createStackNavigator();

export default function LeagueLoadingStack({ route }) {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.LeagueLoading")} component={LeagueLoading} initialParams={{ ...route.params }}/>
    </Stack.Navigator>
  );
};
