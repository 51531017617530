import React from 'react';
import { StyleSheet, TextInput, View} from 'react-native';
import { strings } from '../locales/i18n';
import { sizeS } from '../Styles/text';
import { black, primaryColor, red } from '../Styles/colors';
import { Paragraph } from './Texts';

export function RequiredTextInput(props) {
    let newProps = {...props};
    newProps.text = props.text || strings('globals.required');
    return (<TextInputWithText {...newProps}/>)
}
export function TextInputWithText(props) {
    const textStyle = Array.isArray(props.style) ? props.style.reduce((a, b) => ({color: b.color})) : {color : props.style?.color};
    const borderColor = Array.isArray(props.style) ? props.style.reduce((a, b) => ({borderColor: b.borderColor})).borderColor : props.style?.borderColor;

    const textInputStyle = [styles.requiredTextInput, textStyle];
    const textMessageStyle = [styles.requiredText, {borderColor}];

    const text = props.text;
    const textInputProps = {...props};

    let stateStyle = {};
    let textStateStyle = {};
    if (props.hasError) {
        stateStyle = {
            ...stateStyle,
            borderColor: red,
            borderWidth: 2,
        };
        textStateStyle = {
            ...textStateStyle,
            color: red,
        };
    }

    delete textInputProps.style;
    delete textInputProps.text;


    return (
        <View style={ [styles.requiredInput, props.style, stateStyle] }>
            <View style={ styles.textInput }>
                <TextInput style={ [textInputStyle, textStateStyle] } { ...textInputProps } />
            </View>
            <View style={ styles.required }>
                <Paragraph style={ [textMessageStyle, textStateStyle] } numberOfLines={ 1 }>{ text }</Paragraph>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    requiredInput: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 4,
        marginBottom: 2,
        borderWidth: 1,
        borderColor: primaryColor,
        padding: 4,
        borderRadius: 8,
        color: black,
        height: 40,
    },
    textInput: {
        flex: 4,
    },
    requiredTextInput: {
        borderWidth: 0,
        color: black,
    },
    required: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginHorizontal: 4,
    },
    requiredText: {
        textTransform: 'lowercase',
        fontSize: sizeS,
        fontWeight: 'bold',
        color: primaryColor,
    },
});
