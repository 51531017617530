import basketixApiCall from './BasketixApi';

const createLeague = async(data) => {
    const endpoint = '/basketix/league/create';
    const body = {
        name: data.leagueName,
        creator: {
            name: data.creatorTeamName,
            surname: data.creatorTeamSurname,
        }
    };

    if (data.leagueDescription !== undefined || data.leagueDescription !== '') {
        body.description = data.leagueDescription
    }

    const league = await basketixApiCall(endpoint, body, 'POST');

    return league;
};

const joinLeague = async(newUser) => {
    const endpoint = '/basketix/league/join';
    const body = {
        leagueId: newUser.leagueId,
        teamName: newUser.teamName,
        teamSurname: newUser.teamName,
    };
    const league = await basketixApiCall(endpoint, body, 'POST');

    return league;
};

const getLeague = async(leagueId) => {
    const endpoint = '/basketix/league/get';
    const body = {leagueId};
    const league = await basketixApiCall(endpoint, body, 'POST');

    return league;
};

export const LeagueAPI = {
    createLeague,
    joinLeague,
    getLeague,
};
