import React from 'react';
import { StyleSheet, View} from 'react-native';
import { grey } from '../Styles/colors';
import { Paragraph } from './Texts';

export default function RegisteredTeam({team, playerName}) {
    const teamNameDisplay = () => {
        if (team) {
            return (<Paragraph style={styles.playerNameText}>{ team }</Paragraph>);
        }
    };
    const nameDisplay = () => {
            return (<Paragraph style={styles.basketixText}>{playerName}</Paragraph>);
    };

    return (
        <View style={styles.registedTeam}>
            <View style={styles.middle}>
                <View style={styles.playerName}>
                    { teamNameDisplay() }
                </View>
                <View style={styles.basketix}>
                    { nameDisplay() }
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    registedTeam: {
        flexDirection: 'row',
        marginBottom:10,
    },
    middle: {
        flex: 5,
        justifyContent: 'center',
    },
    playerName: {
        flexShrink: 1,
    },
    playerNameText: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    basketix: {},
    basketixText: {
        color: grey,
        fontSize: 10,
        textTransform: 'uppercase',
    }
});
