import React, { useState } from 'react';
import { StyleSheet, Text, ScrollView, View } from 'react-native';
import { useDispatch } from 'react-redux';
import {Formik} from 'formik';
import * as yup from 'yup';
import { LeagueAPI } from '../API/League';
import { globalStyles } from '../Styles/globals';
import BtxButton from '../Components/BtxButton';
import { strings } from '../locales/i18n';
import { RequiredTextInput } from '../Components/RequiredTextInput';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph, TextError, Title1 } from '../Components/Texts';

export default function LeagueCreate ({ navigation }) {
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState('');

    const initialFormValues = {
        leagueName: '',
        leagueDescription: '',
        teamName: '',
        teamSurname: ''
    };

    const reviewSchema = yup.object({
        leagueName : yup.string().required(strings('leagueCreate.nameLeagueError')),
        leagueDescription : yup.string(),
        teamName : yup.string().required(strings('leagueCreate.teamNameError')),
        teamSurname: yup.string().required(strings('leagueCreate.teamSurnameError')),
    })

    const handleSubmit = (formValues) => {
        if (formValues) {
            const createLeagueBody = {
                leagueName: formValues.leagueName,
                leagueDescription: formValues.leagueDescription,
                creatorTeamName: formValues.teamName,
                creatorTeamSurname: formValues.teamSurname,
            };

            LeagueAPI.createLeague(createLeagueBody).then(data => {
                if (! data.error) {
                    const action = { type: 'UPDATE_APP_LOADER', value: { leagueIdToLoad: data.leagueId, loadingId: Date.now() } };
                    dispatch(action);
                    navigation.goBack();
                    navigation.navigate('LeagueLoadingStack');
                } else {
                    console.log(data.error);
                    setErrorMsg(data.error.message);
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }

    return (
        <ScrollViewMainContainer>
            <Formik
                initialValues={initialFormValues}
                validationSchema={reviewSchema}
                onSubmit={(values)=> {handleSubmit(values)}}
            >
            {(formikprops) => (
                <View>
                    <Title1>{ strings('leagueCreate.leagueParamsTitle') }</Title1>

                    <Paragraph style ={globalStyles.textInputLabel}>{ strings('leagueCreate.nameLeagueLabel') }</Paragraph>
                    <RequiredTextInput
                        style={ globalStyles.textInput }
                        onChangeText={ formikprops.handleChange('leagueName') }
                        value={ formikprops.values.leagueName }
                    />
                    <TextError>{ formikprops.touched.leagueName && formikprops.errors.leagueName }</TextError>

                    <Paragraph style ={globalStyles.textInputLabel}>{ strings('leagueCreate.leagueDescriptionLabel') }</Paragraph>
                    <RequiredTextInput
                        style={ globalStyles.textInput }
                        onChangeText={ formikprops.handleChange('leagueDescription') }
                        value={ formikprops.values.leagueDescription }
                        text={''}
                    />

                    <Title1>{ strings('leagueCreate.myTeamTitle') }</Title1>

                    <Paragraph style ={globalStyles.textInputLabel}>{ strings('leagueCreate.teamNameLabel') }</Paragraph>
                    <RequiredTextInput
                        style={ globalStyles.textInput }
                        onChangeText={ formikprops.handleChange('teamName') }
                        value={ formikprops.values.teamName }
                    />
                    <TextError>{ formikprops.touched.teamName && formikprops.errors.teamName }</TextError>

                    <Paragraph style ={globalStyles.textInputLabel}>{ strings('leagueCreate.teamSurnameLabel') }</Paragraph>
                    <RequiredTextInput
                        style={ globalStyles.textInput }
                        onChangeText={ formikprops.handleChange('teamSurname') }
                        value={ formikprops.values.teamSurname }
                    />
                    <TextError>{ formikprops.touched.teamSurname && formikprops.errors.teamSurname }</TextError>
                    <TextError>{ errorMsg }</TextError>

                    <BtxButton title={ strings('leagueCreate.createButton') } onPress={ formikprops.handleSubmit } height={50}/>
                </View>
                )}
            </Formik>
        </ScrollViewMainContainer>
    )
}
