import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import LeagueSettings from '../Screens/LeagueSettings';
import { strings } from '../locales/i18n';

const Stack = createStackNavigator();

export default function LeagueSettingsStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.LeagueSettings")} component={LeagueSettings} />
    </Stack.Navigator>
  );
};
