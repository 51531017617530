import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Bold } from './Texts';

export function Column({children, size}) {
    size = size ?? 1;
    return (
        <View style={[styles.column, {flex: size}]}>
            <Bold style={styles.columnText} numberOfLines={2}>{children}</Bold>
        </View>
    )
}

const styles = StyleSheet.create({
    column: {
        textAlign: 'center',
        justifyContent: 'flex-end',
        alignItems: 'center',
        // padding: 2,
        marginHorizontal: 2,
        // backgroundColor: 'red', //tmp
    },
    columnText: {
        marginVertical: 4,
        // backgroundColor: 'green', //tmp
    },
});
