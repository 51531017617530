import basketixApiCall from './BasketixApi';

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
};

export const lastStats = async() => {
    const endpoint = '/nba/stats/last';
    const stats = await basketixApiCall(endpoint, undefined, 'GET');

    return stats;
};

export const allPlayers = async() => {
    const endpoint = '/nba/player/list';
    const nbaPlayers = await basketixApiCall(endpoint, undefined, 'GET');

    return nbaPlayers;
};

export const NbaPlayersAPI = {
  lastStats,
  allPlayers
}
