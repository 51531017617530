import React from 'react';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph } from '../Components/Texts';

export default function LeagueRanking() {

    return (
        <ScrollViewMainContainer>
            <Paragraph>L'écran des paramétres de la ligue arrive bientôt</Paragraph>
        </ScrollViewMainContainer>
    )
};
