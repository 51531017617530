import React from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDrawerStatus } from '@react-navigation/drawer';
import { MaterialIcons } from '@expo/vector-icons';
import { white } from '../Styles/colors';


const iconNameWhenDrawerOpen = 'close';
const iconNameWhenDrawerClose = 'menu';

export default function DrawerButton() {
    const navigation = useNavigation();
    const isDrawerOpen = useDrawerStatus() === 'open';

    const onPress = () => {
        navigation.toggleDrawer();
    };

    const iconName = () => {
        return isDrawerOpen ? iconNameWhenDrawerOpen : iconNameWhenDrawerClose
    };

    return (
        <MaterialIcons name={iconName()} size={28} onPress={onPress} style={styles.icon} />
    )
}

const styles = StyleSheet.create({
    icon: {
        color: white,
    },
});
