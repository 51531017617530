import React from 'react';
import { View, StyleSheet } from 'react-native';
import { secondaryColor, white } from '../Styles/colors';
import { Small } from './Texts';

export function Pill({ color, backgroundColor, children }) {
    let pillStyle = {};
    if (backgroundColor) {
        pillStyle = {
            ...pillStyle,
            backgroundColor,
        };
    }

    let pillTextStyle = {};
    if (color) {
        pillTextStyle = {
            ...pillTextStyle,
            color,
        };
    }

    return (
        <View style={[styles.pill, pillStyle]}>
            <Small style={[styles.pillText, pillTextStyle]}>{children}</Small>
        </View>
    );
}

const styles = StyleSheet.create({
    pill: {
        textAlign: 'center',
        paddingHorizontal: 8,
        paddingVertical: 1,
        borderRadius: 6,
        maxWidth: '100%',
        backgroundColor: secondaryColor,
    },
    pillText: {
        height: 15,
        lineHeight: 15,
        color: white,
    }
});
