import { createStore, combineReducers} from 'redux';

import league from './Reducers/leagueReducer';
import draft from './Reducers/draftReducer';
import authentication from './Reducers/authenticationReducer';
import players from './Reducers/playersReducer';
import teams from './Reducers/teamsReducer';
import appLoader from './Reducers/appLoaderReducer';
import user from './Reducers/userReducer';
import { market } from './Reducers/marketReducer';
import { seasonSettingsReducer } from './season-settings/reducer';
import basketBallCourtColor from './Reducers/basketBallCourtReducer';


const reducer = combineReducers({
    authentication,
    league,
    draft,
    players,
    teams,
    appLoader,
    user,
    market,
    basketBallCourtColor,
    seasonSettings: seasonSettingsReducer,
});

export default createStore(reducer);
