import React, { useState, useEffect } from 'react';
import { StyleSheet, View, FlatList, Image } from 'react-native';
import { NBAGamesAPI } from '../API/NbaGames';
import { primaryColor, black, alphaColor, transparent, lightGrey, grey } from '../Styles/colors';
import I18n, { strings, toLocaleDate, timezone } from '../locales/i18n';
import { sizeL, sizeM, sizeS, sizeXS } from '../Styles/text';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Title1, Paragraph, Title2, Title4} from '../Components/Texts';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { capitalizeFirstLetter } from '../Service/jsUtils';
import { POSTPONED } from '../Shared/Constants';
import BtxButton from '../Components/BtxButton';
import {yesterday, nextDay, previousDay, convertToTimeZone, toHourFormat} from '../Service/dateUtils';


const SPOILER_BLURS = [
    {level: 1, label: strings("NbaGames.level", {level: 1}), description: strings("NbaGames.descriptionLevel1"), ranked: ["-hotnessScore", "startTimeUTC"], hiddenResult: false, hiddenHotnessScore: false},
    {level: 2, label: strings("NbaGames.level", {level: 2}), description: strings("NbaGames.descriptionLevel2"), ranked: ["-hotnessScore", "startTimeUTC"], hiddenResult: true, hiddenHotnessScore: false},
    {level: 3, label: strings("NbaGames.level", {level: 3}), description: strings("NbaGames.descriptionLevel3"), ranked: ["-hotnessScore", "startTimeUTC"], hiddenResult: true, hiddenHotnessScore: true},
    {level: 4, label: strings("NbaGames.level", {level: 4}), description: strings("NbaGames.descriptionLevel4"), ranked: ["startTimeUTC"], hiddenResult: true, hiddenHotnessScore: true},
]

export function GamesScreen() {
    const [games, setGames] = useState([]);
    const [sortedGames, setSortedGames] = useState([]);
    const [gamesDate, setGamesDate] = useState(undefined);
    const [isNbaGamesLoading, setIsNbaGamesLoading] = useState(false);
    const [spoilerBlurLevel, setSpoilerBlurLevel] = useState(SPOILER_BLURS[SPOILER_BLURS.length - 1].level);

    const spoilerBlur = SPOILER_BLURS.find(spoilerLevel => spoilerLevel.level === spoilerBlurLevel);

    useEffect (() => {
        if (gamesDate) {
            setIsNbaGamesLoading(true);
            NBAGamesAPI.from(gamesDate).then( (value) => {
                setGames(value.games);
            }).catch((error) => {
                console.error(error);
            }).finally( () => {
                setIsNbaGamesLoading(false);
            })
        }

    }, [gamesDate]);

    useEffect (() => {
        if (spoilerBlur) {
            const gamesCopy = [...games];
            spoilerBlur.ranked.toReversed().forEach((key) => {
                gamesCopy.sort((a, b) => {
                    const isDesc = key.startsWith("-");
                    const sortKey = isDesc ? key.substring(1) : key;
                    const desc = isDesc ? -1 : 1;
                    return a[sortKey] > b[sortKey] ? desc * 1 : desc * -1;
                });
            });
            setSortedGames(gamesCopy);
        }
    }, [spoilerBlurLevel, games]);

    useEffect (() => {
        setGamesDate(yesterday());
    }, []);

    const spoilMeMore = () => {
        const levelIndex = SPOILER_BLURS.findIndex(level => level.level === spoilerBlurLevel);
        console.log("levelIndex", levelIndex);
        if (levelIndex !== -1 && levelIndex - 1 >= 0) {
            setSpoilerBlurLevel(SPOILER_BLURS[levelIndex - 1].level);
        } else {
            setSpoilerBlurLevel(SPOILER_BLURS[SPOILER_BLURS.length - 1].level);
        }
    }

    const displayLegend = () => {
        const shouldShowLegend = games.filter(game => game.extendedGameStatus === POSTPONED).length > 0;
        if (shouldShowLegend) {
            return (
            <View style={styles.legend}>
                <FontAwesome name='calendar-times-o' size={14} color={black} />
                <Paragraph>{strings('home.postponedLegend')}</Paragraph>
            </View>);
        }
    }


    const displayNbaGamesList = () => {
        if (isNbaGamesLoading) {
            return (
                <BtxActivityIndicator size='small' />
            )
        } else if (games.length === 0) {
            return (
                <Paragraph>{strings('home.noMatch')}</Paragraph>
            )
        }
        return (
            <View>
                <FlatList
                    style={ styles.list }
                    data={ sortedGames }
                    keyExtractor={ (item) => item.gameId }
                    contentContainerStyle={{ gap: 12 }}
                    renderItem={({item, index}) =>  {
                        console.log(index, item);
                        return (
                            <Game
                                homeText={item.teams[0].teamName}
                                homeId={item.teams[0].teamId}
                                homeScore={item.teams[0].score}
                                isHomeWinner={item.teams[0].winner}
                                awayText={item.teams[1].teamName}
                                awayId={item.teams[1].teamId}
                                awayScore={item.teams[1].score}
                                isAwayWinner={item.teams[1].winner}
                                hotnessScore={item.hotnessScore ?? 0}
                                hiddenResult={spoilerBlur.hiddenResult}
                                hiddenHotnessScore={spoilerBlur.hiddenHotnessScore}
                                startTimeUTC={item.startTimeUTC}
                                gameStatus={item.gameStatus}
                            />
                        )
                    }}
                />
                { displayLegend() }
            </View>
        )
    };

    let dateJSX = (<></>);
    if (gamesDate) {
        dateJSX = (
            <View style={[styles.dateContainer]}>
                <BtxButton title={strings("NbaGames.previous")} backgroundColor={transparent} onPress={() => setGamesDate(previousDay(gamesDate))} />
                <Title2>{ capitalizeFirstLetter(toLocaleDate(gamesDate), I18n.locale) }</Title2>
                <BtxButton title={strings("NbaGames.next")} backgroundColor={transparent} onPress={() => setGamesDate(nextDay(gamesDate))} />
            </View>
        )
    }

    return (
        <ScrollViewMainContainer>
            <View style={ styles.div }>
                <View>
                    { dateJSX }
                </View>
                <View style={ styles.divHeader }>
                    <View style={ styles.divTitle}>
                        <Title1>{spoilerBlur.label}</Title1>
                        <Paragraph>{spoilerBlur.description}</Paragraph>
                    </View>
                    <View style={styles.divNoSpoile}>
                        <BtxButton
                            title={strings("NbaGames.spoilButton")}
                            icon={<MaterialCommunityIcons name='eye-plus' size={sizeL} />}
                            onPress={spoilMeMore}
                            padding={10}
                            fontSize={ sizeM }
                        />
                    </View>
                </View>
                <View>
                    { displayNbaGamesList() }
                </View>
            </View>
        </ScrollViewMainContainer>
    );
}

function Game(props) {
    const {
        homeText,
        homeId,
        awayText,
        awayId,
    } = props;
    const homeLogoUri = `https://cdn.nba.com/logos/nba/${homeId}/global/L/logo.svg`;
    const awayLogoUri = `https://cdn.nba.com/logos/nba/${awayId}/global/L/logo.svg`;
    return (
        <View style={gameStyles.container}>
            <View style={gameStyles.header}>
                {/* <Paragraph>Header</Paragraph> */}
            </View>
            <View style={gameStyles.body}>
                <View style={[gameStyles.column, gameStyles.teamContainer]}>
                    <Image style={{width: 50, height: 50}} source={{uri: homeLogoUri}} />
                    <Paragraph>{homeText}</Paragraph>
                </View>
                <View style={[gameStyles.column, gameStyles.detailsContainer]}>
                    <GameDetails {...props} />
                </View>
                <View style={[gameStyles.column, gameStyles.teamContainer]}>
                    <Image style={{width: 50, height: 50}} source={{uri: awayLogoUri}} />
                    <Paragraph>{awayText}</Paragraph>
                </View>
            </View>
            <View style={gameStyles.footer}>
                {/* <Paragraph>Footer</Paragraph> */}
            </View>
        </View>
    )
}

function GameDetails(props) {
    const {
        homeScore,
        isHomeWinner,
        awayScore,
        isAwayWinner,
        hotnessScore,
        hiddenResult,
        hiddenHotnessScore,
        startTimeUTC,
        gameStatus,
    } = props;
    const gameStatusText = gameStatus;

    if (hiddenResult && hiddenHotnessScore && gameStatus === 'unstarted') {
        const parisDatetime = convertToTimeZone(startTimeUTC, 'utc', timezone());
        const parisTimeText = toHourFormat(parisDatetime);
        return (
            <View>
                <Title1>{parisTimeText}</Title1>
            </View>
        )
    }

    if (hiddenResult && hiddenHotnessScore) {
        return (
            <View>
                <Title4>{gameStatusText}</Title4>
            </View>
        )
    }
    if (hiddenResult && !hiddenHotnessScore) {
        return (
            <View style={gameDetailsStyles.hotnessScoreContainer}>
                <Title1>{hotnessScore}</Title1>
            </View>
        )
    }
    if (!hiddenResult && !hiddenHotnessScore) {

        const homeScoreStyle = isHomeWinner ? [] : [gameDetailsStyles.looserText];
        const awayScoreStyle = isAwayWinner ? [] : [gameDetailsStyles.looserText];

        return (
            <View style={gameDetailsStyles.scoreContainer}>
                <Title1 style={homeScoreStyle}>{homeScore}</Title1>
                <Title4>{gameStatusText}</Title4>
                <Title1 style={awayScoreStyle}>{awayScore}</Title1>
            </View>
        )
    }
    return (
        <View>error</View>
    )
}

const gameStyles = StyleSheet.create({
    container: {
        backgroundColor: lightGrey,
        display: 'flex',
        borderRadius: 8,
    },
    header: {
        // backgroundColor: '#FFE500', //debug helper
        flex: 1,
        alignItems: 'center',
    },
    body: {
        // backgroundColor: 'red', //debug helper
        display: 'flex',
        flexDirection: 'row',
    },
    footer: {
        // backgroundColor: '#96DA8B', //debug helper
        flex: 1,
        alignItems: 'center',
    },
    column: {
        justifyContent: 'center',
        paddingVertical: 4,
    },
    teamContainer: {
        // backgroundColor: '#FFD8C1', //debug helper
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    detailsContainer: {
        // backgroundColor: '#FFEEC1', //debug helper
        flex: 3,
        flexDirection: 'column',
        alignItems: 'center',
        textTransform: 'uppercase',
    },
});

const gameDetailsStyles = StyleSheet.create({
    scoreContainer: {
        // backgroundColor: 'yellow', //debug helper
        flexDirection: 'row',
        alignItems: 'center',
        gap: 24,
    },
    hotnessScoreContainer: {
        // backgroundColor: 'grey', //debug helper
        paddingVertical: 4,
        paddingHorizontal: 16,
        borderRadius: 4,
    },
    looserText: {
        color: grey,
    },
});

const styles = StyleSheet.create({
    div: {
        flexDirection: 'column',
        marginTop: 16,
        marginBottom: 28,
    },
    legend: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        marginTop:8
    },
    divHeader: {
        flexDirection: 'row',
        marginBottom: 12,
        alignItems: 'center',
    },
    divTitle: {
        flex: 3,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    divButtons: {
        flex: 1,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    leagueItem: {
        height: 200,
        width: 150,
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 40,
        marginBottom: 16,
        marginRight: 12,
        borderRadius: 8,
        alignItems: 'center',
        backgroundColor: primaryColor,
    },
    favoriteLeague: {
        borderColor: black,
        borderWidth: 2,
    },
    leagueText: {
        fontWeight: 'bold',
        fontSize: sizeM,
        textAlign: 'center',
    },
    leagueStatusText: {
        position: 'absolute',
        bottom: 8,
        fontWeight: 'bold',
        fontSize: sizeXS,
        backgroundColor: black,
        color: primaryColor,
        textTransform: 'uppercase',
        padding: 4,
        borderRadius: 4,
    },
    performanceItem: {
        flexDirection: 'column',
        backgroundColor: primaryColor,
        marginHorizontal: 4,
        marginBottom: 8,
        padding: 8,
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderRadius: 8,
        width: 140,
        height: 80,
    },
    performanceRanking: {
        position: 'absolute',
        left: 0,
    },
    performanceRankingText: {
        fontWeight: 'bold',
        fontSize: 60,
        color: alphaColor(black, 20),
    },
    performanceName: {
        marginBottom: 6,
    },
    performanceNameText: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: sizeM,
    },
    performanceScore: {
        backgroundColor: black,
        padding: 8,
        borderRadius: 8,
        marginTop: 2,
        width: 64,
    },
    performanceScoreText: {
        fontWeight: 'bold',
        color: primaryColor,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: sizeS,
    },
    divNoSpoile: {
        flex: 3,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    dateContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        borderColor: lightGrey,
        borderBottomWidth: 1,
        paddingVertical: 8,
        marginVertical: 10,
        marginBottom: 22,
    },
});
