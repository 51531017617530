import React from 'react';
import { ActivityIndicator } from 'react-native';
import { secondaryColor } from '../Styles/colors';

export function BtxActivityIndicator({ size, color, props }) {
    const finalColor = color || secondaryColor;
    const finalSize = size || 'small';

    return (<ActivityIndicator size={ finalSize } color={ finalColor }/>);
};
