import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import { strings } from '../locales/i18n';
import LeagueFinished from '../Screens/LeagueFinished';

const Stack = createStackNavigator();

export default function LeagueFinishedStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.LeagueFinished")} component={LeagueFinished} />
    </Stack.Navigator>
  );
};
