export const sizeXS = 8;
export const sizeS = 10;
export const sizeM = 12;
export const sizeL = 14;
export const sizeXL = 16;
export const sizeXXL = 20;
export const sizeXXXL = 28;

export const bold = '500';

