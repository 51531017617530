import React, {useEffect, useState} from 'react';
import {TouchableOpacity, StyleSheet} from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { MainContainer, ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { BasketballCourt } from '../Components/BasketballCourt';
import { black, primaryColor, red, secondaryColor} from '../Styles/colors';
import { strings } from '../locales/i18n';
import { showMessage } from "react-native-flash-message";
//import ColorPicker from 'react-native-wheel-color-picker'
import BtxButton from '../Components/BtxButton';
import { View } from 'react-native-web';
import { Paragraph, Title4 } from '../Components/Texts';
import { globalStyles } from '../Styles/globals';
import { SeasonAPI } from '../API/Season';
import { useDispatch } from 'react-redux';


const Stack = createStackNavigator();

export default function TmpStack() {
    return (
        <Stack.Navigator>
            <Stack.Screen name={'tmp'} component={Tmp} />
        </Stack.Navigator>
    );
};

export function Tmp() {
    const dispatch = useDispatch();
    const basketBallCourtColor = useSelector(state => state.basketBallCourtColor);
    const backgroundColor = basketBallCourtColor.backgroundColor
    const paintColor = basketBallCourtColor.paintColor
    const linesColor = basketBallCourtColor.linesColor
    const threePointsColor = basketBallCourtColor.threePointsColor
    const courtColor = basketBallCourtColor.courtColor
    const sidePaintColor = basketBallCourtColor.sidePaintColor
    const linesWidth = basketBallCourtColor.linesWidth

    const [courtPart, setCourtPart] = useState(undefined)
    const [tmpColor, setTmpColor] = useState(undefined)


    function getOppositeColor(hex) {
        var red = parseInt(hex[1]+hex[2],16);
        var green = parseInt(hex[3]+hex[4],16);
        var blue = parseInt(hex[5]+hex[6],16);
        //If is this operation be <= 100 return white, others else return black
        return ((0.3 * red) + (0.59 * green) + (0.11 * blue) <= 100) ? '#FFF' : '#000';
    }
    useEffect(() => {
        SeasonAPI.getCourt({/*seasonId, basketixIds: [selectedBasketixId]*/}).then(data => {
            if (!data.error) {
                /*setBackgroundColor(data.court.backgroundColor)
                setPaintColor(data.court.paintColor)
                setLinesColor(data.court.linesColor)
                setThreePointsColor(data.court.threePointsColor)
                setCourtColor(data.court.courtColor)
                setSidePaintColor(data.court.sidePaintColor)*/
                dispatch({ type: 'SET_BASKETCOURTCOLOR', value: data });
            } else {
                console.error(data.error);
            }
        }).catch(err => {
            console.error(err);
        })
    }, [])

    const styleText = {
        textAlign: 'center',
        flex:1
    };

    const onColorChange = (c, terrain) => {
        setTmpColor(c)
        setCourtPart(terrain)
        console.log(c)
    };

    const onColorChangeComplete = (c) => {
        setTmpColor(c)
        if (courtPart =="backgroundColor")
        {
            setBackgroundColor(c);
        }
        if (courtPart =="paintColor")
        {
            setPaintColor(c)
        }
        if (courtPart =="linesColor")
        {
            setLinesColor(c)
        }
        if (courtPart =="courtColor")
        {
            setCourtColor(c)
        }
        if (courtPart =="threePointsColor")
        {
            setThreePointsColor(c)
        }
        if (courtPart =="sidePaintColor")
        {
            setSidePaintColor(c)
        }
    };

    const onPressSave = () => {
        setTmpColor(undefined);
        const color={
            backgroundColor: backgroundColor,
            courtColor: courtColor,
            paintColor: paintColor,
            threePointsColor: threePointsColor,
            linesColor: linesColor,
            sidePaintColor: sidePaintColor,
        }
        SeasonAPI.setCourt(color).then(data => {
            if (! data.error) {
                showMessage({
                    message: strings('editCourt.saved'),
                    type: 'success',
                    icon: 'success',
                  });
            } else {
                console.log(data.error);
                showMessage({
                    message: data.error,
                    type: 'danger',
                    icon: 'danger',
                  });            }
        }).catch(err => {
            console.log(err);
        })
    };

    const colorPicker = tmpColor ? (
        <View style={[globalStyles.flex, globalStyles.alignCenter]}>
            <View style={styles.colorPicker}>
                <ColorPicker
                    color={tmpColor}
                    swatchesOnly={false}
                    // onColorChange={onColorChange}
                    autoResetSlider={true}
                    onColorChangeComplete={onColorChangeComplete}
                    thumbSize={20}
                    sliderSize={40}
                    noSnap={false}
                    row={false}
                    swatchesLast={true}
                    swatches={false}
                    discrete={true}
                    discreteLength={10}
                    gapSize={4}
                />
            </View>
            <View style={[styles.colorPickerButtons, globalStyles.flexRow]}>
                <BtxButton title='Enregistrer' onPress={onPressSave} />
                <BtxButton title='Annuler' onPress={() => setTmpColor(undefined)} />
            </View>
        </View>) : (<></>)

    return (
        <ScrollViewMainContainer noPadding={true}>
            <MainContainer>
                <Paragraph style={{color: tmpColor}}>Tmp color : {tmpColor}</Paragraph>
            </MainContainer>
            <BasketballCourt
                teamNameColor={black}
                backgroundColor={backgroundColor}
                courtColor={courtColor}
                paintColor={paintColor}
                threePointsColor={threePointsColor}
                linesColor={linesColor}
                sidePaintColor={sidePaintColor}
                linesWidth={linesWidth}
            ></BasketballCourt>
            <MainContainer>
                <View style={[globalStyles.flexRow]}>
                    <View style={[globalStyles.flex]}>
                        {colorPicker}
                        <View style={[{flexDirection: "column"}]}>
                            <View style={[{flexDirection: "row", flex:1}]}>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: backgroundColor}, globalStyles.shadow]} onPress={() => onColorChange(backgroundColor, "backgroundColor")}>
                                    <Title4 style={[styleText, {color:getOppositeColor(backgroundColor)}]}>Bord de terrain</Title4>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: courtColor}, globalStyles.shadow]} onPress={() => onColorChange(courtColor,"courtColor")}>
                                    <Title4 style={[styleText, {color:getOppositeColor(courtColor)}]}>Terrain</Title4>
                                </TouchableOpacity>
                            </View>
                            <View style={[{flexDirection: "row", flex:1}]}>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: paintColor}, globalStyles.shadow]} onPress={() => onColorChange(paintColor, "paintColor")}>
                                    <Title4 style={[styleText, {color:getOppositeColor(paintColor)}]}>Raquette</Title4>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: linesColor}, globalStyles.shadow]} onPress={() => onColorChange(linesColor, "linesColor")}>
                                    <Title4 style={[styleText, {color:getOppositeColor(linesColor)}]}>Ligne</Title4>
                                </TouchableOpacity>
                            </View>
                            <View style={[{flexDirection: "row", flex:1}]}>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: threePointsColor}, globalStyles.shadow]} onPress={() => onColorChange(threePointsColor, "threePointsColor")}>
                                    <Title4 style={[styleText, {color:getOppositeColor(threePointsColor)}]}>Mi-distance</Title4>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.colorSelector, {backgroundColor: sidePaintColor}, globalStyles.shadow]} onPress={() => onColorChange(sidePaintColor, "sidePaintColor")}>
                                    <Title4 style={[styleText, {color:getOppositeColor(sidePaintColor)}]}>Bord de la raquette</Title4>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </MainContainer>
        </ScrollViewMainContainer>
    );
}

const styles = StyleSheet.create({
    colorSelector: {
        justifyContent: 'center',
        //alignItems: 'flex-start',
        borderRadius: 4,
        borderColor: black,
        borderWidth: 1,
        padding: 8,
        marginVertical: 8,
        flex:1,
        marginHorizontal: 8
    },
    colorPickerContainer: {
        flex: 1,
    },
    colorPickerButtons: {
        marginVertical: 24,
        justifyContent: 'center',
    },
    colorPicker: {
        width: '66%',
    },
    textColorSelector: {
        textAlign: 'center',
        flex:1,
    }
});
