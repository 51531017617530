import basketixApiCall from './BasketixApi';

const create = async(usersInformation) => {
    const endpoint = '/basketix/user/create';
    const body = {
        email: usersInformation.email,
        name: usersInformation.name,
        password: usersInformation.password,
        verifyPassword: usersInformation.verifyPassword,
        cgu: usersInformation.cgu,
    };
    const user = await basketixApiCall(endpoint, body, 'POST');
    return user;
};

const login = async(usersInformation) => {
    const body = {
        email: usersInformation.email,
        password: usersInformation.password,
    };
    const endpoint = '/login';
    const token = await basketixApiCall(endpoint, body, 'POST');

    return token;
};

const verify = async(usersInformation) => {
    const endpoint = `/basketix/user/verify?email=${usersInformation.email}&confirmationCode=${usersInformation.confirmationCode}`;
    const user = await basketixApiCall(endpoint, undefined, 'GET');

    return user;
};

const get = async() => {
    const endpoint = '/basketix/user';
    const user = await basketixApiCall(endpoint, undefined, 'GET');

    return user;
};

const sendMaiForgotPassword = async(values) => {
    //const endpoint = '/basketix/user';
    //const user = await basketixApiCall(endpoint, undefined, 'GET');

    if (values.email == "erreur@gmail.com")
    {
        const emailSentKO = false
        return emailSentKO
    }

    else
    {
        const emailSentOK = true
        return emailSentOK
    }
};

const resetPassword = async(values) => {
    const endpoint = '/basketix/user/update/password';
    const user = await basketixApiCall(endpoint, values, 'POST');
    return user
};

const update = async(values) => {
    const endpoint = '/basketix/user/update';
    const user = await basketixApiCall(endpoint, values, 'POST');

    return user
};


export const UsersAPI = {
    create,
    login,
    verify,
    get,
    sendMaiForgotPassword,
    resetPassword,
    update,
}
