import basketixApiCall from './BasketixApi';

const getFreeAgents = async(data) => {
    const endpoint = '/basketix/season/draft/v2/freeagents';
    const body = { seasonId: data.seasonId };

    const freeAgents = await basketixApiCall(endpoint, body, 'POST');

    return freeAgents;
};

const setPreDraft = async(data) => {
    const endpoint = '/basketix/season/draft/predraft/set';
    const body = {
        seasonId: data.seasonId,
        pickNumber: data.pickNumber,
        playerIds: data.playerIds,
        isAuto: data.isAuto,
    };

    const result = await basketixApiCall(endpoint, body, 'POST');

    return result;
};

const pick = async(data) => {
    const endpoint = '/basketix/season/draft/pick';
    const body = {
        seasonId: data.seasonId,
        pickNumber: data.pickNumber,
        playerId: data.playerId,
    };

    const result = await basketixApiCall(endpoint, body, 'POST');

    return result;
};

const skip = async(data) => {
    const endpoint = '/basketix/season/draft/pick';
    const body = {
        seasonId: data.seasonId,
        pickNumber: data.pickNumber,
    };

    const result = await basketixApiCall(endpoint, body, 'POST');

    return result;
};

export const DraftAPI = {
    getFreeAgents,
    setPreDraft,
    pick,
    skip
}
