import React, { useState } from 'react';
import { StyleSheet, View} from 'react-native';
import { black, lightGrey } from '../Styles/colors';
import { globalStyles } from '../Styles/globals';
import BtxButton from './BtxButton';
import { Paragraph } from './Texts';

export function Evaluations({ playerId, evaluations }) {
    const [selectedEvaluation, setSelectedEvaluation] = useState(undefined);

    if (selectedEvaluation) {
        return (
            <View style={[globalStyles.flexRow]}>
                <Paragraph>{`${toLocaleDate(selectedEvaluation.gameDate)} : ${selectedEvaluation.evaluation}`}</Paragraph>
                <BtxButton
                    title={'fermer'}
                    onPress={() => setSelectedEvaluation(undefined)}
                />
            </View>
        )
    }

    return (
        <View style={[[globalStyles.flexRow, globalStyles.flexWrap, globalStyles.justifyCenter]]}>
            {
                evaluations.map(evaluation => (
                    <BtxButton
                        key={`${playerId}-${evaluation.gameId}`}
                        title={evaluation.evaluation}
                        disabled={true}
                        onPress={() => setSelectedEvaluation(evaluation)}
                        backgroundColor={lightGrey}
                        color={black}
                        margin={2}
                        minWidth={26}
                    />
                ))
            }
        </View>
    )
}

const styles = StyleSheet.create({});
