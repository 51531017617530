import React, { useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { PlayerSeasonDetails } from './PlayerSeasonDetails';
import { globalStyles } from '../Styles/globals';
import { BidForm } from './BidForm';
import { CutForm } from './CutForm';
import { TouchableOpacity } from 'react-native-web';
import { black, grey, lightGrey, primaryColor, red } from '../Styles/colors';
import { sizeM } from '../Styles/text';
import { MainKpiBar } from './MainKpiBar';
import { MainKpi } from './MainKpi';
import { strings } from '../locales/i18n';

export function PlayerDetails({
    playerId,

    // Season details part
    displaySeason,
    mean,
    nGames,
    lastEvaluations,
    injury,
    displayWeekDetails,
    team,

    // Bid part
    displayBid,
    minBid,
    previousBid,

    // Cut part
    displayCut,
}) {
    const [gameDisplayed, setGameDisplayed] = useState(undefined);

    const gamesItems = team?.games.map(game => {
        const prefix = game.isHome ? ' ' : game.isAway ? '@' : ' ';
        const isGameFinished = game.gameStatus === 'finished';
        const gameItemStyles = isGameFinished ? [styles.gameItem, styles.gameItem_Finished] : [styles.gameItem];
        const gameItemTextStyles = isGameFinished ? [styles.gameItemText, styles.gameItemText_Finished] : [styles.gameItemText];
        const isDisplayedGame = game.gameId === gameDisplayed?.gameId;
        return (
                <TouchableOpacity
                    key={game.gameId}
                    style={[gameItemStyles, isDisplayedGame ? styles.gameItem_Displayed : undefined]}
                    disabled={!isGameFinished}
                    onPress={() => setGameDisplayed(game)}
                >
                    <Text style={gameItemTextStyles}>{ prefix + game.opponent.tricode + ' ' }</Text>
                </TouchableOpacity>
        );
    })

    const games = (<View style={globalStyles.flexRow}>{gamesItems}</View>);

    const coachingModalDetails = () =>{
        if (displayWeekDetails){
            return (
                <View style={[globalStyles.flex]}>
                    <MainKpiBar style={globalStyles.bgTransparent}>
                        <MainKpi title={strings('playerCard.adversaries')} renderValue={games} style={styles.mainKpi} />
                    </MainKpiBar>
                </View>
            )
        }
    }
    const playerSeasonDetails = () => {
        if (displaySeason) {
            return (
                <PlayerSeasonDetails
                    playerId={ playerId }
                    count={ nGames }
                    mean={ mean }
                    lastEvaluations={ lastEvaluations }
                    injury={ injury }
                />
            );
        }

        return (<></>);
    };

    const bidForm = () => {
        if (displayBid) {
            return (
                <View style={[globalStyles.flexColumn, styles.formContainer]}>
                    <BidForm
                        playerId={ playerId }
                        minBid={ minBid }
                        previousBid={ previousBid }
                    />
                </View>
            );
        }

        return (<></>);
    };

    const cutForm = () => {
        if (displayCut) {
            return (
                <View style={[globalStyles.flexColumn, styles.formContainer]}>
                    <CutForm playerId={ playerId } />
                </View>
            );
        }

        return (<></>);
    };

    return (
        <View style={[globalStyles.flex, globalStyles.flexColumn]}>
            { coachingModalDetails() }
            { playerSeasonDetails() }
            { bidForm() }
            { cutForm() }
        </View>
    )
}

const styles = StyleSheet.create({
    formContainer: {
        marginTop: 24,
    }, 
    gameItemText: {
        color: grey,
        fontWeight: 'bold',
        fontSize: sizeM,
        flex: 1,
    },
    gameItem: {
        paddingHorizontal: 3,
        paddingVertical: 1,
        marginRight: 4,
        borderColor: grey,
        borderWidth: 1,
        borderRadius: 6,
        flexDirection: 'row',
    },
    gameItem_Finished: {
        borderColor: black,
    },
    gameItem_Displayed: {
        backgroundColor: lightGrey,
    },
    mainKpi: {
        borderRightWidth: 0, 
    },
});
