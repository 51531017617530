import React from 'react';
import { StyleSheet, View} from 'react-native';
import { black, white } from '../Styles/colors';
import { Paragraph } from './Texts';

export default function Circle({ text, size, shapeStyle, textStyle }) {
    const sizeStyle = size ? {
        width: size,
        height: size,
        borderRadius: size / 2,
    } : undefined;

    const finalStyle = Array.isArray(shapeStyle) ? [styles.circleShape, ...shapeStyle, sizeStyle] : [styles.circleShape, shapeStyle, sizeStyle];

    return (
        <View style={finalStyle}>
            <Paragraph style={ [styles.text, textStyle] }>{ text }</Paragraph>
        </View>
    )
}

const styles = StyleSheet.create({
    circleShape: {
        justifyContent: 'center',
        backgroundColor: black,
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
    },
    text: {
        fontWeight: 'bold',
        textAlign: 'center',
        color: white,
    },
});
