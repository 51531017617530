import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { globalStyles } from '../Styles/globals';
import { MaterialIcons } from '@expo/vector-icons';
import { black } from '../Styles/colors';
import { bold, sizeM, sizeS, sizeXS } from '../Styles/text';

/*
Example of use:
<Paragraph style={{ color: 'red', fontSize: 16 }}>Hello world!</Paragraph>
<Paragraph style={[ { color: 'red' }, { fontSize: 16 } ]}>Hello world!</Paragraph>
*/

export function Paragraph(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [{ fontSize: sizeM }, componentProps.style];

    return (
        <Text {...componentProps}>{ props.children }</Text>
    )
}

export function Bold(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [{ fontSize: sizeM, fontWeight: bold}, componentProps.style];

    return (
        <Text {...componentProps}>{ props.children }</Text>
    )
}

export function Small(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [{ fontSize: sizeS}, componentProps.style];

    return (
        <Text {...componentProps}>{ props.children }</Text>
    )
}

export function Title1(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [globalStyles.titleText1, componentProps.style];

    return (
        <Text {...componentProps}>{ props.children }</Text>
    )
}

export function Title2(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [globalStyles.titleText2, componentProps.style];

    return (
        <Text {...componentProps}>{ props.children }</Text>
    )
}

export function Title3(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [globalStyles.titleText3, componentProps.style];

    return (
        <Text {...componentProps}>{ props.children }</Text>
    )
}

export function Title4(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [globalStyles.titleText4, componentProps.style];

    return (
        <Text {...componentProps}>{ props.children }</Text>
    )
}

export function Title5(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [globalStyles.titleText5, componentProps.style];

    return (
        <Text {...componentProps}>{ props.children }</Text>
    )
}

export function Title6(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [globalStyles.titleText6, componentProps.style];

    return (
        <Text {...componentProps}>{ props.children }</Text>
    )
}

export function TextError(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [globalStyles.errorText, componentProps.style];

    return (
        <Text {...componentProps}>{ props.children }</Text>
    )
}

export function ListElement(props) {
    const componentProps = { ...props };
    delete componentProps.children;

    componentProps.style = [{ fontSize: globalStyles.sizeM }, componentProps.style];

    return (
        <View style={[{marginLeft:15}, styles.container]}>
            <MaterialIcons name='brightness-1' size={sizeXS} color={black} style={{marginRight:4}}/>
            <Text {...componentProps}>{" "}{ props.children }</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 4,
    }
});
