import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { grey, black, backgroundColor, primaryColor, transparent } from '../Styles/colors';
import { sizeM, sizeS } from '../Styles/text';
import { PlayerCardExtended } from './PlayerCardExtended';
import { PlayerDetails } from './PlayerDetails';

export default function DraftChoice({
        choice,
        onPress,
        onPressDelete,
        onLongPress,
        isActive,
        isEditMode,
        isFirst,
        isLast,
        onPressUp,
        onPressDown,
    }) {
    let choiceStyle = undefined;
    if (isActive) {
        choiceStyle = { borderColor: primaryColor, borderWidth: 1 };
    }

    const displayDrag = () => {
        if (isEditMode) {
            return (
                <View style={ styles.editPositionContainer }>
                    <TouchableOpacity style={[styles.positionMoveContainer, {alignItems: 'flex-end', marginBottom:4}]} onPress={onPressUp} disabled={isFirst}>
                        <Feather name='arrow-up' size={22} color={isFirst ? transparent : black} />
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.positionMoveContainer, {alignItems: 'flex-start', marginTop:4}]} onPress={onPressDown} disabled={isLast}>
                        <Feather name='arrow-down' size={22} color={isLast ? transparent : black} />
                    </TouchableOpacity>
                </View>
            );
        }
    };
    
    const displayPlayerChosen = () => {
        return (
            <View style={ styles.playerInfo }>
                <PlayerCardExtended
                    style={{ maxWidth: 1000}}
                    name={choice.fullName}
                    subtitle={choice.team.fullName}
                    positions={choice.positions}
                    meta={`${choice.cost} M$`}
                    injury={choice.injury}
                    details={
                        <PlayerDetails
                            playerId={choice.playerId}
                            displaySeason={true}
                            nGames={choice.stats?.n}
                            mean={choice.stats?.evaluationMean}
                            lastEvaluations={choice.stats?.lastEvaluations}
                            injury={choice.injury}
                            displayBid={false}
                            displayCut={false}
                        />
                    }
                />
            </View>
        )
    };

    const displayDelete = () => {
        if (isEditMode) {
            return (
                <TouchableOpacity onPress={ onPressDelete } style={ styles.right }>
                    <View style={ styles.trash }>
                        <Feather name="trash-2" size={18} color={ grey } />
                    </View>
                </TouchableOpacity>
            );
        }
        return (<View style={ styles.right }></View>);
    };

    return (
        <View style={ [styles.choiceCard, choiceStyle] }>
            { displayDrag() }
            { displayPlayerChosen() }
            { displayDelete() }
        </View>
    );
};

const styles = StyleSheet.create({
    choiceCard: {
        flexDirection: 'row',
        marginBottom: 10,
        flex: 1,
        borderRadius: 6,
        backgroundColor: backgroundColor,
        maxWidth: 1200,
    },
    left: {
        flex: 9,
        flexDirection: 'row',
    },
    right: {
        flex: 1,
        alignContent: 'center',
    },
    editPositionContainer: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent : 'center',
    },
    positionMoveContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    playerInfo: {
        flex: 15,
        paddingHorizontal: 4,
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    playerName: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    playerTeam: {
        fontSize: sizeS,
        color: grey,
        textTransform: 'uppercase',
    },
    meta: {
        flex: 5,
        paddingHorizontal: 4,
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    playerPositions: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
    },
    playerCostContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    playerCost: {
        fontSize: sizeM,
        fontWeight: 'bold',
    },
    trash: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
