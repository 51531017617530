import React, { useState } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import { LeagueAPI } from '../API/League';
import { globalStyles } from '../Styles/globals';
import BtxButton from '../Components/BtxButton';
import {Formik} from 'formik';
import * as yup from 'yup';
import { strings } from '../locales/i18n';
import { RequiredTextInput } from '../Components/RequiredTextInput';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph, TextError } from '../Components/Texts';

export default function LeagueJoin({ navigation}) {
    const dispatch = useDispatch();

    const [errorMsg, setErrorMsg] = useState('');

    const reviewSchema = yup.object({
        LeagueId : yup.string()
            .required( strings('leagueJoin.requiredError')),
        TeamName : yup.string()
            .required(strings('leagueJoin.requiredError')),
        TeamSurname: yup.string()
            .required(strings('leagueJoin.requiredError')),
    })

    const initialFormValues = {
        LeagueId: '',
        TeamName: '',
        TeamSurname: '',
    };

    const handleSubmit = (values) => {
        if (values) {
            const joinLeagueBody = {
                leagueId: values.LeagueId,
                teamName: values.TeamName,
                teamSurname: values.TeamSurname,
            };

            LeagueAPI.joinLeague(joinLeagueBody).then(data => {
                if (! data.error) {
                    const action = { type: 'UPDATE_APP_LOADER', value: { leagueIdToLoad: data.leagueId, loadingId: Date.now() } };
                    dispatch(action);
                    navigation.goBack();
                    navigation.navigate('LeagueLoadingStack');
                } else {
                    console.log(data.error);
                    setErrorMsg(data.error.message);
                }
            })
        }
    }

    return (
        <ScrollViewMainContainer>
            <View>
                <Formik
                    initialValues= { initialFormValues }
                    validationSchema= { reviewSchema }
                    onSubmit= {(values) => { handleSubmit(values) } }
                >
                {(formikprops) => (
                    <View>
                        <Paragraph style={globalStyles.textInputLabel}>{ strings('leagueJoin.idLeagueLabel') }</Paragraph>
                        <RequiredTextInput
                            // placeholder={ strings('leagueJoin.idLeaguePlaceholder') }
                            style={ globalStyles.textInput }
                            onChangeText={ formikprops.handleChange('LeagueId') }
                            value={ formikprops.values.LeagueId}
                            onBlur={ formikprops.handleBlur('LeagueId') }
                        />
                        <TextError>{ formikprops.touched.LeagueId && formikprops.errors.LeagueId }</TextError>
                        <Paragraph style={globalStyles.textInputLabel}>{ strings('leagueJoin.teamNameLabel') }</Paragraph>
                        <RequiredTextInput
                            // placeholder={ strings('leagueJoin.teamNamePlaceholder') }
                            style={ globalStyles.textInput }
                            onChangeText={ formikprops.handleChange('TeamName') }
                            value={ formikprops.values.TeamName}
                            onBlur={ formikprops.handleBlur('TeamName') }
                        />
                        <TextError>{ formikprops.touched.TeamName && formikprops.errors.TeamName }</TextError>
                        <Paragraph style={globalStyles.textInputLabel}>{ strings('leagueJoin.teamSurnameLabel') }</Paragraph>
                        <RequiredTextInput
                            // placeholder={ strings('leagueJoin.teamSurnamePlaceholder') }
                            style={ globalStyles.textInput }
                            onChangeText={ formikprops.handleChange('TeamSurname') }
                            value={ formikprops.values.TeamSurname}
                            onBlur={ formikprops.handleBlur('TeamSurname') }
                        />
                        <TextError>{ formikprops.touched.TeamSurname && formikprops.errors.TeamSurname }</TextError>
                        <TextError>{ errorMsg } </TextError>
                        <BtxButton title={ strings('leagueJoin.joinButtonLabel') } onPress={ formikprops.handleSubmit } height={ 50 }/>
                    </View>

                )}
                </Formik>
            </View>
        </ScrollViewMainContainer>
    )
}
