import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { grey, silver } from '../Styles/colors';
import { sizeS, sizeXXL } from '../Styles/text';
import { Paragraph } from './Texts';

export function MainKpi({ title, value, style, onPress, titleStyle, valueStyle, renderValue, isLast, hasSeparator }) {
    const valueJSX = renderValue || <Paragraph style={[styles.valueTextStyle, valueStyle]}>{value}</Paragraph>;

    let separatorStyle = {
        borderRightColor: silver,
        borderRightWidth: 2,
    };
    if (isLast || (hasSeparator !== undefined & !hasSeparator)) {
        separatorStyle.borderRightWidth = 0;
    }

    return (
        <TouchableOpacity
            style={[styles.kpiContainer, style, separatorStyle]}
            disabled={!onPress}
            onPress={onPress}
        >
            <View style={[styles.titleContainer]}>
                <Paragraph style={[styles.titleTextStyle, titleStyle]}>{title}</Paragraph>
            </View>
            <View style={[styles.valueContainer]}>
                { valueJSX }
            </View>
        </TouchableOpacity>
    )
}
const styles = StyleSheet.create({
    kpiContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    titleContainer: {
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 6,
    },
    valueContainer: {
        flex: 1,
        flexGrow: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleTextStyle: {
        textTransform: 'uppercase',
        fontSize: sizeS,
        color: grey,
    },
    valueTextStyle: {
        fontSize: sizeXXL,
        fontWeight: 'bold',
    }
});
