import React from 'react';
import { StyleSheet, View} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { primaryColor, white, black, getPaletteColor } from '../Styles/colors';
import { Paragraph } from './Texts';
import { globalStyles } from '../Styles/globals';
import { POSTPONED } from '../Shared/Constants'

export function GamesResult({ hiddenResult, item, index  }) {

    const styleScoreTeam = (teamResult) => {
        return teamResult.winner ? [styles.scoreTeam, styles.scoreTeamWinner] : styles.scoreTeam;
    };

    const styleScoreTeamText = (teamResult, index) => {
        const color = getPaletteColor(index, 3);
        return teamResult.winner ? [styles.scoreTeamText, styles.scoreTeamTextWinner, {color}] : styles.scoreTeamText;
    };

    const styleScoreText = (teamResult, index) => {
        const color = getPaletteColor(index, 3);
        return teamResult.winner ? [styles.scoreText, styles.scoreTextWinner, {color}] : styles.scoreText;
    };

    const styleScoreFlexRow = () => {
        return (hiddenResult || item.extendedGameStatus === POSTPONED) ? [styles.scoreItem, globalStyles.flex, globalStyles.flexRow]: globalStyles.flexRow ;
    };

    const styleScoreFlex = () => {
        return (hiddenResult || item.extendedGameStatus === POSTPONED) ? [globalStyles.flex] : styles.scoreItem ;
    };

    const styleScoreParagraph = (num) => {
        return (hiddenResult || item.extendedGameStatus === POSTPONED) ? [styles.colorWhite] : styleScoreTeamText(item.teams[num], index) ;
    };

    const displayScore = (num) => {        
        if (hiddenResult || item.extendedGameStatus === POSTPONED) {      
        }
        else {
            return(
                <Paragraph style={ styleScoreText(item.teams[num], index) }>{ item.teams[num].score }</Paragraph>
            )  
        }
    }

    const displayIcon = () => {
        
        if (hiddenResult) {
            return(
                <View style={styles.noResulGameContainer}>
                    <FontAwesome name='question-circle' size={14} color={ getPaletteColor(index, 1) } />
                </View>
            )
        }
        else if (item.extendedGameStatus === POSTPONED){
            return(
                <View style={styles.noResulGameContainer}>
                    <FontAwesome name='calendar-times-o' size={14} color={white} />
                </View>
            )
        }
    }


    return (
        <View style={styleScoreFlexRow()}>
            <View style={styleScoreFlex()}>
                <View style={ styleScoreTeam(item.teams[0]) }>
                    <Paragraph style={ styleScoreParagraph(0) }>{ item.teams[0].tricode }</Paragraph>
                    {displayScore(0)}
                </View>
                <View style={ styleScoreTeam(item.teams[1]) }>
                    <Paragraph style={ styleScoreParagraph(1) }>{ item.teams[1].tricode }</Paragraph>
                    {displayScore(1)}
                </View>
            </View>
                {displayIcon()}
        </View>
    )
}

const styles = StyleSheet.create({
    scoreItem: {
        backgroundColor: black,
        flexDirection: 'column',
        marginHorizontal: 4,
        marginBottom: 8,
        padding: 8,
        borderRadius: 8,
        marginTop: 2,
        width: 90,
    },
    scoreTeam: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        borderRadius: 4,
        marginVertical: 1,
    },
    scoreTeamWinner: {},
    scoreTeamText: {
        color: white,
    },
    scoreTeamTextWinner: {
        fontWeight: 'bold',
    },
    scoreText: {
        color: white,
        alignItems: 'flex-end',
    },
    scoreTextWinner: {
        fontWeight: 'bold',
        color: primaryColor,
    },
    noResulGameContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    divNoSpoile: {
        flex: 3,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    colorWhite: {
        color: white
    },
});
