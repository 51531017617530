export function isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}

export function mergeArrays(arr1, arr2, key) {
    const map = new Map();
    arr1.forEach(item => map.set(item[key], item));
    arr2.forEach(item => map.set(item[key], {...map.get(item[key]), ...item}));
    return Array.from(map.values());
}

export function capitalizeFirstLetter([ first, ...rest ], locale) {
    return first.toLocaleUpperCase(locale) + rest.join('');
}

export function getOppositeColor(hex) {
    var red = parseInt(hex[1]+hex[2],16);
    var green = parseInt(hex[3]+hex[4],16);
    var blue = parseInt(hex[5]+hex[6],16);
    //If is this operation be <= 100 return white, others else return black
    return ((0.3 * red) + (0.59 * green) + (0.11 * blue) <= 100) ? '#FFF' : '#000';
}

export const formatPercentage = (value, options) => {
    const opt = {
        fractionDigits: 2,
        locale: "en-GB",
        ...options
    };
    const formatted = Intl.NumberFormat(opt.locale, {
        style: "percent",
        minimumFractionDigits: opt.fractionDigits,
        maximumFractionDigits: opt.fractionDigits,
    }).format(value);
    return formatted;
};
