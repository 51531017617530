import React, { useState, useEffect } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { FreeAgencyApi } from '../../API/FreeAgency';
import { BtxActivityIndicator } from '../../Components/BtxActivityIndicator';
import BtxButton from '../../Components/BtxButton';
import { FreeAgencyHistoryDetails } from '../../Components/FreeAgencyHistoryDetails';
import { Gap } from '../../Components/Gap';
import { PlayerCardExtended } from '../../Components/PlayerCardExtended';
import { PlayerDetails } from '../../Components/PlayerDetails';
import { ScrollViewMainContainer } from '../../Components/ScrollViewMainContainer';
import { SwitchBasketix}  from '../../Components/SwitchBasketix';
import { Paragraph, Title1 } from '../../Components/Texts';
import { strings } from '../../locales/i18n';
import { getBidsSubtitle } from '../../Service/bidService';
import { marketActions } from '../../Store/Reducers/marketReducer';
import { grey, primaryColor } from '../../Styles/colors';
import { sizeS } from '../../Styles/text';

export function LeagueFreeAgencyHome({ navigation }) {
    const dispatch = useDispatch();

    const seasonId = useSelector(state => state.league.season.seasonId);
    const seasonStatus = useSelector(state => state.league.season.seasonStatusId);
    const lastMarketActionTime = useSelector(state => state.market.lastMarketActionTime);
    const isLoading = useSelector(state => state.market.freeAgency.isLoading);
    const freeAgencyData = useSelector(state => state.market.freeAgency.data);
    const leagueMembers = useSelector(state => state.league.members);
    const basketixId = useSelector(state => state.authentication.basketixId);

    const [showUserFreeAgency, setShowUserFreeAgency] = useState(false);

    const history = freeAgencyData?.history || [];
    const inProgress = freeAgencyData?.inProgress || [];
    const canSearchFreeAgents = seasonStatus === 'REGULARSEASON';

    const displayedData = (data) => {
        return showUserFreeAgency ? data.filter(fa => fa.hasUserBid) : data;
    }

    useEffect(() => {
        dispatch({type: marketActions.LOAD_FREE_AGENCY});
        FreeAgencyApi.getFreeAgency({ seasonId }).then(data => {
            if (!data.error) {
                dispatch({type: marketActions.FREE_AGENCY_SUCCESS, value: data});
            } else {
                console.error(data.error);
                dispatch({type: marketActions.FREE_AGENCY_ERROR, value: data.error});
            }
        }).catch(err => {
            console.error(err);
            dispatch({type: marketActions.FREE_AGENCY_ERROR, value: err});
        })
    }, [seasonId, basketixId, lastMarketActionTime]);

    const goToFreeAgentsSearchScreen = () => {
        navigation.navigate(strings('navigation.LeagueFreeAgentsSearch'));
    };

    return (
        <ScrollViewMainContainer>
            <View style={styles.switchContainer}>
            <SwitchBasketix
                    label={strings('freeAgencyHome.onlyMyBidding')}
                    labelHighlighted={ true }
                    value={ showUserFreeAgency }
                    onValueChange={() => setShowUserFreeAgency(!showUserFreeAgency)}
            />
            </View>
            <Gap/>
            <View style={styles.divHeader}>
                <View style={ styles.divTitle}>
                    <Title1>{strings('freeAgencyHome.biddingInProgress')}</Title1>
                </View>
                <View style={ styles.divButtons}>
                    <GoToFreeAgentsButton
                        visible={canSearchFreeAgents}
                        onPress={goToFreeAgentsSearchScreen}
                    />
                </View>
            </View>
            <FreeAgencyInProgress data={ displayedData(inProgress) } isLoading={ isLoading }/>
            <Gap/>
            <Title1>{strings('freeAgencyHome.history')}</Title1>
            <FreeAgencyHistory data={ displayedData(history) } isLoading={ isLoading } leagueMembers={leagueMembers} basketixId={basketixId}/>
        </ScrollViewMainContainer>
    );
}


function FreeAgencyInProgress({ data, isLoading }) {
    if (isLoading) {
        return (<BtxActivityIndicator />);
    }

    if (data?.length === 0) {
        return (
            <Paragraph>{strings('freeAgencyHome.noBiddingInProgress')}</Paragraph>
        );
    }

    return (
        <FlatList
            data={data}
            keyExtractor={ (item) => item.player.playerId }
            renderItem={ ({ item }) => {
                return (
                    <PlayerCardExtended
                        key={item.player.playerId}
                        name={item.player.fullName}
                        subtitle={getBidsSubtitle(item.hasUserBid, item.totalBids)}
                        team={item.team?.tricode}
                        positions={item.positions}
                        meta={`${item.minCost} M$`}
                        injury={item.player.injury}
                        isHighlight={item.hasUserBid}
                        details={
                            <PlayerDetails
                                playerId={item.player.playerId}

                                displaySeason={true}
                                nGames={item.player.stats?.n}
                                mean={item.player.stats?.evaluationMean}
                                lastEvaluations={item.player.stats?.lastEvaluations}
                                injury={item.player.injury}

                                displayBid={true}
                                minBid={item.currentBid?.cost + 1 || item.minCost}
                                previousBid={item.currentBid?.cost}

                                displayCut={false}
                            />
                        }
                    />
                );
            }}
            showsVerticalScrollIndicator={false}
        />
    );
}

function FreeAgencyHistory({ data, isLoading, leagueMembers, basketixId }) {
    if (isLoading) {
        return(<BtxActivityIndicator />);
    }

    if (data?.length === 0) {
        return (
            <Paragraph>{strings('freeAgencyHome.noBiddingYet')}</Paragraph>
        );
    }

    return (
        <FlatList
            data={data}
            keyExtractor={ (item) => item.player.playerId }
            renderItem={ ({ item }) => {
                const isBasketixWin = item.winner.basketix.basketixId === basketixId;
                const winnerName = isBasketixWin ? strings('globals.you') : leagueMembers[item.winner.basketix.basketixId].name;

                return (
                    <PlayerCardExtended
                        key={item.player.playerId}
                        name={item.player.fullName}
                        subtitle={`${leagueMembers[item.winner.basketix.basketixId].teamName} (${winnerName})`}
                        team={item.team?.tricode}
                        positions={item.positions}
                        meta={`${item.winner?.cost || item.minCost} M$`}
                        injury={item.player.injury}
                        isHighlight={item.hasUserBid}
                        details={<FreeAgencyHistoryDetails
                            bids={item.bids}
                            minCost={item.minCost}
                        />}
                    />
                );
            }}
            showsVerticalScrollIndicator={false}
        />
    );
}

function GoToFreeAgentsButton({ visible, onPress }) {
    if (!visible) {
        return(<></>);
    }

    return (
        <BtxButton
            title={strings('freeAgencyHome.seeFreeAgent')}
            onPress={onPress}
        />
    );

}

const styles = StyleSheet.create({
    divHeader: {
        flexDirection: 'row',
        marginBottom: 12,
        alignItems: 'center',
    },
    divTitle: {
        flex: 3,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    divButtons: {
        flex: 1,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    switch: {},
});
