import React from 'react';
import {View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {Formik} from 'formik';
import * as yup from 'yup';
import { globalStyles } from '../Styles/globals';
import BtxButton from '../Components/BtxButton';
import { strings } from '../locales/i18n';
import { RequiredTextInput } from '../Components/RequiredTextInput';
import RNPickerSelect from 'react-native-picker-select';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph, TextError, Title1 } from '../Components/Texts';
import { showMessage } from "react-native-flash-message";
import { UsersAPI } from '../API/Users';
import { grey, secondaryColor } from '../Styles/colors';
import { actions } from '../Store/actions';
import { InputGroup } from '../Components/InputGroup';
import { SetPasswordForm } from '../Components/SetPasswordForm';
import { checkPassword } from '../Service/authenticationService';

export default function Profile () {
    const dispatch = useDispatch();
    const basketixUser = useSelector(state => state.user);
    const isLoadingBasketixInfo = useSelector(state => state.user.isLoadingBasketixInfo);

    const initialFormValue = {
        email: basketixUser.email,
        userName: basketixUser.name,
        language:  '',
    };

    const initialResetPasswordFormValue = {
        password1: '',
        password2: '',
        actualPassword: '',
    };

    const languageList = [
        {label: strings('language.french'), value: 'fr' , key: 1},
        {label: strings('language.english'), value: 'en' , key: 2},
    ];

    const reviewSchema = yup.object({
        userName : yup.string().required(strings('leagueCreate.nameLeagueError')),
    });

    const resetPasswordReviewSchema = yup.object({
        actualPassword : yup.string()
            .required(strings('resetPassword.passwordRequired'))
            .test('', strings('resetPassword.passwordType'), function(value) {
                return checkPassword(value)
            }),
        password1 : yup.string()
            .required(strings('resetPassword.passwordRequired'))
            .test('', strings('resetPassword.passwordType'), function(value) {
                return checkPassword(value)
            }),
        password2 : yup.string()
            .required(strings('resetPassword.verifyPasswordRequired'))
            .test('', strings('resetPassword.verifyPasswordType'), function(value) {
            return this.parent.password1 === value;
        }),
    })

    const handleSubmit = (formValues) => {
        if (formValues) {
            const updateData = {
                userName: formValues.userName,
                language: formValues.language,
            };
            dispatch({type : actions.SET_USER_IS_LOADING, value: true});
            UsersAPI.update(updateData).then(data => {
                if (!data.error) {
                    UsersAPI.get().then(data => {
                        if (! data.error) {
                            dispatch({ type: actions.SET_USER_INFOS, value: {
                                leagues: data.leagues,
                                email: data.email,
                                name: data.userName,
                            }})
                            dispatch({type : actions.SET_USER_IS_LOADING, value: false})
                            showMessage({
                                message: strings('profile.successfullySaved'),
                                type: 'success',
                                icon: 'success',
                            });
                        } else {
                            console.log(data.error);
                            dispatch({type : actions.SET_USER_IS_LOADING, value: false})
                        }
                    });
                } else {
                    console.log(data.error);
                    dispatch({type : actions.SET_USER_IS_LOADING, value: false});
                };
            })
        }
    };

    const handleSubmitResetPassword = (formValues) => {
        if (formValues) {
            console.log('Handle submit reset password. With values :', formValues);
            UsersAPI.resetPassword(formValues).then(data => {
                if (data == null) {
                    showMessage({
                        message: strings('profile.successResetPassword'),
                        type: 'success',
                        icon: 'success',
                    });
                } else {
                    console.log(data.error);
                    dispatch({type : actions.SET_USER_IS_LOADING, value: false});
                };
            })
        }
    };

    return (
        <ScrollViewMainContainer>
            <Formik initialValues={ initialFormValue }
                    validationSchema={ reviewSchema }
                    onSubmit={(values)=> { handleSubmit(values) }}
                    key={'userInfo'}
                    enableReinitialize
            >{(formikprops) => (
                <View key='userInfoForm'>
                    <Title1>{strings('profile.generalInformation')}</Title1>

                    <InputGroup key='email'>
                        <Paragraph style ={globalStyles.textInputLabel}>{strings('profile.email')}</Paragraph>
                        <Paragraph style={ styles.emailText }>{formikprops.values.email}</Paragraph>
                    </InputGroup>

                    <InputGroup key='pseudo'>
                        <Paragraph style ={globalStyles.textInputLabel}>{strings('profile.pseudo')}</Paragraph>
                        <RequiredTextInput
                            style={globalStyles.textInput}
                            onChangeText={formikprops.handleChange('userName')}
                            value={formikprops.values.userName}
                            onBlur={formikprops.handleBlur('userName')}
                            hasError={formikprops.touched.userName && formikprops.errors.userName}
                        />
                        <TextError>{ formikprops.touched.userName && formikprops.errors.userName }</TextError>
                    </InputGroup>

                    <InputGroup key='language'>
                        <Paragraph style ={globalStyles.textInputLabel}>{strings('profile.favoriteLanguage')}</Paragraph>
                        <RNPickerSelect
                            placeholder= {{}}
                            onValueChange={(value) => formikprops.values.language = value}
                            items={languageList}
                        />
                    </InputGroup>

                    <BtxButton
                        marginTop={15}
                        title={strings('profile.save')}
                        onPress={ formikprops.handleSubmit }
                        height={30}
                        loading={isLoadingBasketixInfo}
                    />
                </View>
                )}</Formik>

                <View key='formSeparator' style={styles.formSeparator}/>
                <Formik initialValues={ initialResetPasswordFormValue }
                        validationSchema={ resetPasswordReviewSchema }
                        onSubmit={(values)=> { handleSubmitResetPassword(values) }}
                        key={'password'}
                >{(formikprops) => (
                    <View key='passwordForm'>
                        <Title1>{strings('profile.updatePassword')}</Title1>
                        <InputGroup key='current'>
                            <Paragraph style={globalStyles.textInputLabel}>{strings('profile.currentPassword')}</Paragraph>
                            <RequiredTextInput
                                style={globalStyles.textInput}
                                onChangeText={formikprops.handleChange('actualPassword')}
                                value={formikprops.values.actualPassword}
                                onBlur={formikprops.handleBlur('actualPassword')}
                                autoCompleteType={ 'actualPassword' }
                                keyboardType={ 'actualPassword' }
                                textContentType={ 'actualPassword' }
                                secureTextEntry={true}
                                hasError={formikprops.touched.actualPassword && formikprops.errors.actualPassword}
                            />
                            <TextError>{ formikprops.touched.lastPassword && formikprops.errors.lastPassword }</TextError>
                        </InputGroup>
                        <SetPasswordForm
                            onPasswordChange = {formikprops.handleChange('password1')}
                            onConfirmedPasswordChange = {formikprops.handleChange('password2')}
                            onPasswordValue = {formikprops.values.password1}
                            onConfirmedPassWordValue = {formikprops.values.password2}
                            onBlurPassword = {formikprops.handleBlur('password1')}
                            onBlurConfirmedPassword = {formikprops.handleBlur('password2')}
                            btnBackgroundColor={secondaryColor}
                            btnHeight={30}
                            formikprops = {formikprops}
                        />
                    </View>
                )}
                </Formik>
        </ScrollViewMainContainer>
    )
}

const styles = StyleSheet.create({
    emailText: {
        color: grey,
        fontWeight: 'bold',
    },
    marginTop: {
        marginTop: 20,
    },
    formSeparator: {
        height: 50,
    }
})
