import React from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { getPaddingHorizontal, getScreenBorderWidth, getScreenWidth } from '../Service/mainContainer';
import { black, grey, lightGrey, silver, white } from '../Styles/colors';
import { globalStyles } from '../Styles/globals';

export function MainContainer({ children, style }) {
    return (
        <View style={[globalStyles.mainContainer, style]}>
            { children }
        </View>
    )
}

export function AllScreenContainer({ children, style }) {
    return (
        <View style={[styles.allScreenContainer, style]}>
            { children }
        </View>
    )
}

export function ScreenContainer({ children, style, noPadding }) {
    const { width } = useWindowDimensions();

    const customStyle = {
        width: getScreenWidth(width),
        borderLeftWidth: getScreenBorderWidth(width),
        borderRightWidth: getScreenBorderWidth(width),
    }
    const paddingStyle = noPadding ? globalStyles.screenContainerNoPadding : globalStyles.screenContainerWithPadding

    return (
        <View style={[styles.screenContainer, style, customStyle, paddingStyle]}>
            { children }
        </View>
    )
}

export function ScrollViewMainContainer({ children, backgroundColor, noPadding }) {
    const backgroundStyle = backgroundColor ? {
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
    } : {};

    return(
        <ScrollView contentContainerStyle={styles.contentContainerStyle} style={[styles.scrollViewContainer, backgroundStyle]}>
            <AllScreenContainer style={backgroundStyle}>
                <ScreenContainer noPadding={noPadding} style={backgroundStyle}>
                    {children}
                </ScreenContainer>
            </AllScreenContainer>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    scrollViewContainer: {
        backgroundColor: lightGrey,
    },
    contentContainerStyle: {
        flexGrow: 1,
    },
    allScreenContainer: {
        flex: 1,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: lightGrey,
    },
    screenContainer: {
        flex: 1,
        width: '100%',
        height: '100%',
        paddingHorizontal: 12,
        backgroundColor: white,
        borderColor: silver,
    }
})
