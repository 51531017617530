export const backgroundColor = 'white';

export const primaryColor = '#01B2A9';
export const secondaryColor = '#EF426F';
export const thirdColor = '#FF8201';
const palette = [
    primaryColor,
    secondaryColor,
    thirdColor,
];

export const white = '#FFFFFF';
export const black = '#000000';
export const grey = '#a7a7a7';
export const silver = '#e1e1e1';
export const lightGrey = '#EEEEEE';
export const red = '#c92816';

export const transparent = 'rgba(1, 1, 1, 0.0)';

/*
@color: string color
@alpha: number between 0 and 99
*/
export const alphaColor = (color, alpha) => {
    return `${color}${alpha}`;
};

export const getPaletteColor = (index, offset) => {
    offset = offset ? offset : 0;
    return palette[(index + offset) % palette.length];
};
