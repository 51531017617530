import {DateTime} from "luxon"

export const today = () => {
    let now = new Date()
    const today =  now.toISOString().split('T')[0]
    console.log("today", today);
    return today;
}

// @date: YYYY-MM-DD string date (or other ISO datetime format)
const readDate = (dateStr) => {
    return new Date(dateStr);
}

// @nDays: int
const daysToMilliseconds = (nDays) => {
    return nDays * 24 * 60 * 60 * 1000;
}

// @date: Date()
const toISODate = (date) => {
    return date.toISOString().split('T')[0];
}

// @date: Date()
// @nDays: int
const add = (date, nDays) => {
    return new Date(date.getTime() + daysToMilliseconds(nDays));
}

// @date: YYYY-MM-DD string date
export const nextDay = (dateStr) => {
    const date = readDate(dateStr);
    const nextDate = add(date, 1);
    return toISODate(nextDate);
}

// @date: YYYY-MM-DD string date
export const previousDay = (dateStr) => {
    const date = readDate(dateStr);
    const nextDate = add(date, -1);
    return toISODate(nextDate);
}

export const yesterday = () => {
    const todayStr = today();
    const todayDate = readDate(todayStr);
    return previousDay(todayDate);
}

// @strDate: string date
// @initialTimeZone: zone name (ex: 'utc')
// @targetTimeZone: zone name (ex: 'Europe/Paris')
export const convertToTimeZone = (strDate, initialTimeZone, targetTimeZone) => {
    const jsDate = readDate(strDate)
    return DateTime.fromJSDate(jsDate, { zone: initialTimeZone })
        .setZone(targetTimeZone)
        .toJSDate();
}

// @date: Date()
// @nDays: int
export const toHourFormat = (date) => {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}
