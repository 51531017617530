import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { black, grey, primaryColor, silver, white } from '../Styles/colors';
import { Feather } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';

export function Checkbox({value, onPress}) {
    if (value) {
        return (
            <TouchableOpacity style={[styles.container, styles.selectedContainer]} onPress={onPress} >
                <Entypo name='check' size={16} color={white} />
            </TouchableOpacity>
        )
    }


    return (
        <TouchableOpacity style={[styles.container, styles.notSelectedContainer]} onPress={onPress} />
    )
}

const styles = StyleSheet.create({
    container: {
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
    },
    notSelectedContainer: {
        borderColor: silver,
    },
    selectedContainer: {
        borderColor: black,
        backgroundColor: black,
    }
});
