import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwt_decode from 'jwt-decode';
import { strings } from '../locales/i18n';
import { NbaPlayersAPI } from '../API/NbaPlayers';
import { black, primaryColor, secondaryColor, thirdColor, white } from '../Styles/colors';
import { NBATeamsAPI } from '../API/NbaTeams';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { Paragraph, Title1, Title4 } from '../Components/Texts';

export default function AppLoader() {
    const dispatch = useDispatch();

    useEffect(() => {
        AsyncStorage.getItem('userToken').then(token => {
            const decoded = jwt_decode(token);
            const basketixId = decoded.basketixId;
            const action = { type: 'SET_BASKETIX_ID', value: basketixId };
            dispatch(action);
        });

        const nbaPlayersLoadPromise = NbaPlayersAPI.allPlayers().then(players => {
            const action = { type: 'UPDATE_PLAYERS', value: players };
            dispatch(action);
            return new Promise(resolve => resolve());
        });

        const nbaTeamsLoadPromise = NBATeamsAPI.getNbaTeamsGlobalInfos().then(teams => {
            const action = { type: 'UPDATE_TEAMS', value: teams };
            dispatch(action);
            return new Promise(resolve => resolve());
        });

        Promise.all([nbaPlayersLoadPromise, nbaTeamsLoadPromise]).then(() => {
            dispatch({
                type: 'UPDATE_APP_LOADER',
                value: {
                    isLoad: true,
                }
            });
        });
    }, []);

    return (
        <ScrollViewMainContainer backgroundColor={black}>
            <View style={ styles.basketix }>
                <Title1 style={ styles.title }>{ strings('login.basketixLabel') }</Title1>
                <View style={ styles.bottomBanner }>
                    <View style={ styles.boxColorOne } />
                    <View style={ styles.boxColorTwo } />
                    <View style={ styles.boxColorThree } />
                </View>
                <Paragraph style={ styles.text }>{ strings('login.thisIsWhatLabel') }</Paragraph>
            </View>
            <View style={ styles.basketixFooter }>
                <BtxActivityIndicator size='large' style={ styles.activityIndicator }/>
                <Title4 style={ styles.colorWhite }>Loading data...</Title4>
            </View>
        </ScrollViewMainContainer>
    )
};

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        backgroundColor: black,
    },
    activityIndicator: {
        padding: 24,
    },
    title: {
        color: white,
        textAlign: 'center',
        fontSize: 48,
    },
    text: {
        color: white,
        textAlign: 'center',
    },
    basketix: {
        flex: 1,
        justifyContent: 'center',
        marginHorizontal: 42,
        marginBottom: 16,
    },
    basketixFooter: {
        flex: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    bottomBanner: {
        height: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        marginHorizontal: 42,
        marginVertical: 8,
    },
    boxColorOne: {
        backgroundColor: secondaryColor,
        flex: 1,
    },
    boxColorTwo: {
        backgroundColor: primaryColor,
        flex: 1,
    },
    boxColorThree: {
        backgroundColor: thirdColor,
        flex: 1,
    },
    colorWhite: {
        color: white
    },
});
