import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FreeAgencyApi } from '../../API/FreeAgency';
import { ScrollViewMainContainer } from '../../Components/ScrollViewMainContainer';
import { Title1 } from '../../Components/Texts';
import FreeAgentChoicesModal from '../../Components/FreeAgentChoicesModal';


export function LeagueFreeAgentsSearch({ navigation }) {
    const seasonId = useSelector(state => state.league.season.seasonId);
    const leagueStatusId = useSelector(state => state.league.season.seasonStatusId)
    const basketixId = useSelector(state => state.authentication.basketixId);
    const freeAgencyInProgress = useSelector(state => state.market.freeAgency.data.inProgress);

    const [freeAgents, setFreeAgents] = useState([]);
    const [filteredFreeAgents, setFilteredFreeAgents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const storedTeams = useSelector(state => state.teams);
    const teams = Object.entries(storedTeams).map(([teamId, team]) => ({ ...team, teamId}));

    useEffect(() => {
        setIsLoading(true);
        FreeAgencyApi.getFreeAgentPlayers({ seasonId }).then(data => {
            if (!data.error) {
                setFreeAgents(data);
                setFilteredFreeAgents(data);
                setIsLoading(false);
            } else {
                console.error(data.error);
                setIsLoading(false);
            }
        }).catch(err => {
            console.error(err);
            setIsLoading(false);
        })
    }, [seasonId, basketixId, freeAgencyInProgress])


    const initFilters = {
        positions: {
            available: ['PG', 'SG', 'SF', 'PF', 'C'],
            selected: ['PG', 'SG', 'SF', 'PF', 'C'],
        },
        teams: {
            available: teams.map(team => ({label: team.fullName, value: team.fullName})),
            selected: undefined,
        },
        cost: {
            min: 0,
            max: 50,
            step: 1,
            value: 50,
            showValue: 50,
        },
        name: '',
    };
    const [filters] = useState(initFilters);


    const filterFreeAgents = () => {
        return filteredFreeAgents.filter(freeAgent => {
            return (freeAgent.cost <= filters.cost.value
                && (freeAgent.fullName.toLowerCase().includes(filters.name.toLowerCase()))
                && freeAgent.positions.filter(position => filters.positions.selected.includes(position)).length > 0
                && (filters.teams.selected === undefined || freeAgent.teamId === filters.teams.selected)
            )
        })
    }


    const goBack = () => {
        navigation.goBack();
    }


    return (
        <ScrollViewMainContainer>
            <Title1>Free agents</Title1>
            <FreeAgentChoicesModal
                isLoadingFreeAgents={isLoading}
                freeAgents={ filterFreeAgents()}
                onPressClose={goBack}
                onPressChoose={goBack}
                leagueStatusId={leagueStatusId}
            />
        </ScrollViewMainContainer>
    );
}
