const initialState = {
    leagueId: undefined,
    leagueName: undefined,
    creatorId: undefined,
    settings: {},
    members: {},
    seasonIds: [],
    statusId: undefined,
    status: undefined,
    season: {},
    draft: [],
    freeAgentsDraft: [],
    shouldLoadDraft: true,
};

function league(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case 'SET_LEAGUE':
            nextState = {
                ...initialState,
                ...action.value,
            }
            return nextState || state;
        case 'RESET_LEAGUE':
            nextState = {
                ...initialState,
            }
            return nextState || state;
        case 'UPDATE_LEAGUE':
            nextState = {
                ...state,
                ...action.value,
            }
            return nextState || state;
        case 'DELETE_LEAGUE':
            return initialState;
        case 'SET_DRAFT':
            nextState = {
                ...state,
                draft: action.value,
            };
            return nextState || state;
        case 'UPDATE_FREE_AGENTS_DRAFT':
            nextState = {
                ...state,
                freeAgentsDraft: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}

export default league
