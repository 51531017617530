import React, { useState, useEffect } from 'react';
import { View, StyleSheet, FlatList, TouchableOpacity, Platform } from 'react-native';
import Slider from '@react-native-community/slider';
import {Picker} from '@react-native-picker/picker';
import { Ionicons } from '@expo/vector-icons';
import { useSelector, useDispatch } from 'react-redux'
import { TextInput } from 'react-native-gesture-handler';
import { globalStyles } from '../Styles/globals';
import { white, primaryColor, black, transparent, secondaryColor, grey } from '../Styles/colors';
import BtxButton from '../Components/BtxButton';
import { sizeM } from '../Styles/text';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { strings } from '../locales/i18n';
import { PlayerCardExtended } from './PlayerCardExtended';
import { PlayerDetails } from '../Components/PlayerDetails';
import { getBidsSubtitle } from '../Service/bidService';
import { Paragraph, Title2, Title5 } from './Texts';



export default function FreeAgentChoicesModal(props) {
    const {
        isLoadingFreeAgents,
        freeAgents,
        onPressClose,
        onPressChoose,
        leagueStatusId,
    } = props;

    const dispatch = useDispatch();

    const isWeb = Platform.OS === 'web';
    const [pagination, setPagination] = useState(1);
    const [numberPlayer, setNumberPlayer] = useState(freeAgents.length)
    const choices = useSelector(state => state.draft.choices);
    const storedTeams = useSelector(state => state.teams);
    const basketixId = useSelector(state => state.authentication.basketixId);
    const teams = Object.entries(storedTeams).map(([teamId, team]) => ({ ...team, teamId}));
    const freeAgencyInProgress = useSelector(state => state.market?.freeAgency?.data?.inProgress);
    const freeAgencyInProgressObj = freeAgencyInProgress?.reduce((obj, freeAgent) => ({...obj, [freeAgent.player.playerId]: freeAgent}), {});
    const numberOfItems = 20;
    const canShowMoreFreeAgents = pagination * numberOfItems < numberPlayer;

    const addMoreFreeAgents = () => {
        if (canShowMoreFreeAgents) {
            setPagination(pagination + 1);
        }
    };

    function AddFreeAgents({ visible, onPress }) {
        if (!visible) {
            return (<></>);
        }

        return (
            <BtxButton
                title={'Show more free agent'}
                onPress={onPress}
                alignSelf={'center'}
                backgroundColor={transparent}
                color={secondaryColor}
                icon={<Ionicons name='md-add-circle-outline' size={24} color={ secondaryColor } />}
            />
        );
    }

    const initFilters = {
        positions: {
            available: ['PG', 'SG', 'SF', 'PF', 'C'],
            selected: ['PG', 'SG', 'SF', 'PF', 'C'],
        },
        teams: {
            available: teams.map(team => ({label: team.fullName, value: team.fullName})),
            selected: undefined,
        },
        cost: {
            min: 0,
            max: 50,
            step: 1,
            value: 50,
            showValue: 50,
        },
        name: '',
    };

    const [filters, setFilters] = useState(initFilters);
    const [tmpChoices, setTmpChoices] = useState([...choices]);

    const canChoose = tmpChoices.length > 0;

    useEffect(() => {
        setFilters(initFilters);
    }, []);

    const onPressFreeAgentInner = (freeAgent) => {
        if(tmpChoices.map(c => c.playerId).indexOf(freeAgent.playerId) === -1) {
            setTmpChoices([...tmpChoices, freeAgent]);
        } else {
            setTmpChoices(tmpChoices.filter(c => c.playerId !== freeAgent.playerId));
        }
    };

    const onPressChooseInner = () => {
        dispatch({type: 'UPDATE_DRAFT_CHOICES', value: tmpChoices});
        setTmpChoices([]);
        onPressChoose();
    };

    const onPressCloseInner = () => {
        setTmpChoices([]);
        onPressClose();
    };

    const filterFreeAgents = () => {
        const result = freeAgents.filter(freeAgent => {
            return (freeAgent.cost <= filters.cost.value
                && (freeAgent.fullName.toLowerCase().includes(filters.name.toLowerCase()))
                && freeAgent.positions.filter(position => filters.positions.selected.includes(position)).length > 0
                && (filters.teams.selected === undefined || freeAgent.teamId === filters.teams.selected)
            )}
        );
        if(numberPlayer!=result.length)
        {
            setNumberPlayer(result.length)
        }
        return result
    };

    const onChangeName = (value) => {
        let newFilters = {...filters};
        newFilters.name = value;
        setFilters(newFilters);
    };

    const onChangeTeam = (value) => {
        let newFilters = {...filters};
        newFilters.teams.selected = value;
        setFilters(newFilters);
    };

    const onChangeCost = (value) => {
        let newFilters = {...filters};
        newFilters.cost.showValue = value;
        setFilters(newFilters);
    };

    const onSlidingCompleteCost = (value) => {
        let newFilters = {...filters};
        newFilters.cost.value = value;
        newFilters.cost.showValue = value;
        setFilters(newFilters);
    };

    const isPositionSelected = (position) => {
        return filters.positions.selected.indexOf(position) !== -1;
    };

    const togglePositionFilter = (position) => {
        let selectedPositions = [...filters.positions.selected];

        if (isPositionSelected(position)) {
            selectedPositions = selectedPositions.filter(pos => pos !== position);
        } else {
            selectedPositions.push(position);
        }

        let newFilters = {...filters};
        newFilters.positions.selected = selectedPositions;
        setFilters(newFilters);
    };

    const isFreeAgentSelected = (freeAgent) => {
        return tmpChoices.map(fa => fa.playerId).indexOf(freeAgent.playerId) !== -1;
    };



    function getBidsSubtitleBasketixId(bidInProgress, data) {
        const basketixIdBids = bidInProgress[data.playerId]?.currentBid?.basketix?.basketixId;
        if (basketixIdBids === basketixId) {
            return(getBidsSubtitle(bidInProgress[data.playerId].hasUserBid, bidInProgress[data.playerId].totalBids));
        }

        return (
            strings('freeAgentChoicesModal.playerWithoutBids')
        );

    }

    function FreeAgent({ data, bidInProgress }) {
        const previousBid = bidInProgress[data.playerId]?.currentBid?.cost;
        return (
            <PlayerCardExtended
                key={data.playerId}
                name={data.fullName}
                subtitle={getBidsSubtitleBasketixId(bidInProgress, data)}
                team={data.team?.tricode}
                positions={data.positions}
                meta={`${data.cost} M$`}
                injury={data.injury}
                isHighlight={bidInProgress[data.playerId]?.hasUserBid}
                details={
                    <PlayerDetails
                        playerId={data.playerId}

                        displaySeason={true}
                        nGames={data.stats?.n}
                        mean={data.stats?.evaluationMean}
                        lastEvaluations={data.stats?.lastEvaluations}
                        injury={data.injury}

                        displayBid={true}
                        minBid={data.cost}
                        previousBid={previousBid}

                        displayCut={false}
                    />
                }
            />
        );
    }

    const displaySelectedPlayer =() => {
        if (leagueStatusId === 'REGULARSEASON'){
            return (<></>);
        }
        return(
            <View style={[styles.filterBottomContainer]}>
                <View style={ styles.selectedPlayersInfo }>
                    <Title2>{ tmpChoices.length } { selectedPlayersText }</Title2>
                    <Title5 style={styles.choiceText} numberOfLines={ 1 }>
                        { tmpChoices ? tmpChoices.map(freeAgent => (freeAgent.fullName)).join(', ') : ' '}
                    </Title5>
                </View>
                <View style={styles.buttonsContainer}>
                    <View style={styles.chooseContainer}>
                        <BtxButton
                            title={strings('freeAgentChoicesModal.choose')}
                            onPress={onPressChooseInner}
                            backgroundColor={canChoose ? secondaryColor : grey}
                            disabled={!canChoose}
                        />
                    </View>
                    <View style={styles.closeContainer}>
                        <BtxButton
                            title={strings('freeAgentChoicesModal.cancel')}
                            onPress={ onPressCloseInner }
                            backgroundColor={ transparent }
                        />
                    </View>
                </View>
            </View>
        )
    }

    const displayFreeAgents = () => {
        if (isLoadingFreeAgents) {
            return (<BtxActivityIndicator size='small' />)
        }
        else{
            if (leagueStatusId === 'REGULARSEASON') {
                return(
                    <View style={globalStyles.flex}>
                        <FlatList
                            data={ filterFreeAgents().slice(0, pagination * numberOfItems) }
                            keyExtractor={ (item, index) => item.playerId + index.toString() }
                            renderItem={ ({ item }) => {
                                return (<FreeAgent data={item} bidInProgress={freeAgencyInProgressObj}/>)
                            }}
                            horizontal={false}
                            showsHorizontalScrollIndicator={false}
                            showsVerticalScrollIndicator={true}
                            ListFooterComponent={<AddFreeAgents visible={canShowMoreFreeAgents} onPress={addMoreFreeAgents}/>}
                        />
                </View>
                )
            }
            else {
                return (
                    <View style={globalStyles.flex}>
                        <FlatList
                            data={ filterFreeAgents().slice(0, pagination * numberOfItems)  }
                            keyExtractor={ (item) => item.playerId }
                            ListFooterComponent={<AddFreeAgents visible={canShowMoreFreeAgents} onPress={addMoreFreeAgents}/>}
                            renderItem={ ({item}) => {
                                const isSelected = isFreeAgentSelected(item);
                                return (
                                    <PlayerCardExtended
                                        key={item.playerId}
                                        name={item.fullName}
                                        subtitle={item.team?.fullName}
                                        positions={item.positions}
                                        meta={`${item.cost} M$`}
                                        injury={item.injury}
                                        selectable={true}
                                        isSelected={isSelected}
                                        onSelect={() => onPressFreeAgentInner(item)}
                                        selectDisable={false}
                                        details={
                                            <PlayerDetails
                                                playerId={item.playerId}

                                                displaySeason={true}
                                                nGames={item.stats?.n}
                                                mean={item.stats?.evaluationMean}
                                                lastEvaluations={item.stats?.lastEvaluations}
                                                injury={item.injury}

                                                displayBid={false}
                                                displayCut={false}
                                            />
                                        }
                                    />
                                )
                            }
                            }
                            showsVerticalScrollIndicator={isWeb}
                        />
                    </View>
                )
            }
        }
    };




    const selectedPlayersText = tmpChoices.length > 1 ? strings('freeAgentChoicesModal.playersSelected') : strings('freeAgentChoicesModal.playerSelected');
    return (
        <View style={styles.modalContent}>
            <View style={styles.headerContainer}>
                <View  style={ styles.filters }>
                    <View  style={ styles.styleRow }>
                        <View style={ styles.formItem }>
                            <Paragraph style={ globalStyles.textInputLabel }>{strings('freeAgentChoicesModal.name')}</Paragraph>
                            <TextInput style={ globalStyles.textInput} onChangeText={ onChangeName }/>
                        </View>
                        <View style={ styles.formItem }>
                            <Paragraph style={ globalStyles.textInputLabel }>{strings('freeAgentChoicesModal.team')}</Paragraph>
                            <Picker
                                selectedValue={filters.teams.selected}
                                itemStyle={globalStyles.pickerItemStyle}
                                style={globalStyles.pickerInput}
                                onValueChange={(itemValue, itemIndex) => onChangeTeam(itemValue)}
                            >
                                <Picker.Item label='' value='' key='' />
                                { teams.map(team => (<Picker.Item label={team.fullName} value={team.teamId} key={team.teamId} />)) }
                            </Picker>
                        </View>
                    </View>
                    <View style={ styles.styleRow }>
                        <View  style={ styles.formItem }>
                            <Paragraph style={ globalStyles.textInputLabel }>{strings('freeAgentChoicesModal.position')}</Paragraph>
                            <View style={ styles.formPositions }>
                                {
                                    filters.positions.available.map(position => {
                                        const isSelected = isPositionSelected(position);
                                        const backgroundColor = isSelected ? black : white;
                                        const color = isSelected ? white : black;
                                        return (<BtxButton
                                            key={ position }
                                            title={ position }
                                            height={ 24 }
                                            backgroundColor={ backgroundColor }
                                            color={ color }
                                            borderColor={ black }
                                            borderWidth={ 1 }
                                            onPress={ () => togglePositionFilter(position) }
                                        />)
                                    })
                                }
                            </View>
                        </View>
                        <View style={ styles.formItem }>
                            <Paragraph style={ globalStyles.textInputLabel }>{strings('freeAgentChoicesModal.maxCost', {maxCost:filters.cost.showValue})}</Paragraph>
                            <Slider
                                minimumValue={filters.cost.min}
                                maximumValue={filters.cost.max}
                                minimumTrackTintColor={primaryColor}
                                maximumTractTintColor={secondaryColor}
                                step={filters.cost.step}
                                value={filters.cost.value}
                                onSlidingComplete={value => onSlidingCompleteCost(value)}
                                onValueChange={value => onChangeCost(value)}
                                style={styles.slider}
                                thumbTintColor={primaryColor}
                            />
                        </View>
                    </View>
                </View>
                {displaySelectedPlayer()}
            </View>
            <View style={ styles.freeAgents }>
                { displayFreeAgents() }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    modalToggle: {
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10,
        borderWidth: 1,
        borderColor: '#f2f2f2',
        padding: 10,
        borderRadius: 10,
        alignSelf: 'center',
    },
    modalClose: {
        marginTop: 20,
        marginBottom: 0,
    },
    slider:{},
    modalContent: {
        flex: 1,
        flexDirection: 'column',
        padding: 8,
        borderRadius: 4,
        backgroundColor: transparent,
    },
    filters: {
        flexDirection: 'column',
    },
    styleRow: {
        flexDirection: 'row',
    },
    formItem: {
        flex: 1,
        padding: 4,
        minHeight: 40,
    },
    formPositions: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    filterBottomContainer: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginVertical: 12,
    },
    buttonsContainer: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
    },
    selectedPlayersInfo: {
        flex: 2,
        width: '100%',
    },
    choiceText: {
        width: '100%',
        minHeight: 20,
    },
    freeAgents: {
        flex: 3,
    },
    freeAgentText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: sizeM,
    },
    freeAgentText_Selected: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: sizeM,
    },
    freeAgentInfoText: {
        textTransform: 'uppercase',
        fontSize: sizeM,
    },
    freeAgentInfoText_Selected: {
        textTransform: 'uppercase',
        fontSize: sizeM,
    },
    freeAgentContainer: {
        padding: 4,
        marginVertical: 4,
        borderRadius: 4,
    },
    freeAgentContainer_Selected: {
        backgroundColor: primaryColor,
        padding: 4,
        marginVertical: 4,
        borderRadius: 4,
    },
    headerContainer: {
        borderBottomColor: primaryColor,
        borderBottomWidth: 1,
    },
    chooseContainer: {
        flex: 3,
    },
    closeContainer: {
        flex: 1,
    },
});
