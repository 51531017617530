import React from 'react';
import { StyleSheet, View } from 'react-native';

export function Rows({ children }) {
    return (
        <View style={[styles.rows]}>
            {children}
        </View>
    );
}

const styles = StyleSheet.create({
    rows: {
        // backgroundColor: 'red', //tmp
    },
});
