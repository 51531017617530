import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MyHeader from '../Shared/myHeader';
import DrawerButton from '../Shared/drawerButton';
import LeagueRanking from '../Screens/LeagueRanking';
import { strings } from '../locales/i18n';
import LeagueTeam from '../Screens/LeagueTeam';

const Stack = createStackNavigator();

export default function LeagueRankingStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => (<MyHeader drawerButton={ <DrawerButton /> } />),
      }}
    >
      <Stack.Screen name={strings("navigation.LeagueRanking")} component={LeagueRanking} />
      <Stack.Screen name={strings("navigation.LeagueTeam")} component={LeagueTeam} />

    </Stack.Navigator>
  );
};
