import React from 'react';
import { StyleSheet, View } from 'react-native';
import { grey } from '../Styles/colors';
import { globalStyles } from '../Styles/globals';
import { Paragraph } from './Texts';

export function CardDetailsCell({ upperValue, lowerValue, style }) {
    return (
        <View style={[globalStyles.flex, globalStyles.flexColumn, styles.cell, style]}>
            <View style={[styles.upperRow]}>
                <Paragraph style={[styles.upperRowText]}>{ upperValue }</Paragraph>
            </View>
            <View style={[styles.lowerRow]}>
                <Paragraph style={[styles.lowerRowText]}>{ lowerValue }</Paragraph>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    cell: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    upperRow: {},
    lowerRow: {},
    upperRowText: {
        // textTransform: 'uppercase',
    },
    lowerRowText: {
        color: grey,
        // textTransform: 'uppercase',
    },
});
