import React, { useState, useEffect }  from 'react';
import { View, StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-web';
import { useDispatch, useSelector } from 'react-redux'
import { globalStyles } from '../Styles/globals';
import { SeasonAPI } from '../API/Season';
import { strings, toLocaleDate } from '../locales/i18n';
import { MainKpi } from '../Components/MainKpi';
import { MainKpiBar } from '../Components/MainKpiBar';
import WeekPicker from '../Components/WeekPicker';
import { black } from '../Styles/colors';
import { MainContainer, ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { BasketballCourt } from '../Components/BasketballCourt';
import { Center, PointGuard, PowerForward, ShootingGuard, SmallForward } from '../Components/Player';
import { PlayerGamesDetails } from '../Components/PlayerGamesDetails';
import { Title1, Title2, Title3, Title5 } from '../Components/Texts';
import { isEmptyObject, getOppositeColor } from '../Service/jsUtils';
import { sizeXXL } from '../Styles/text';
import { Live } from '../Components/Live';
import { PlayerCardExtended } from '../Components/PlayerCardExtended';
import { actions } from '../Store/actions';
import { selectors } from '../Store/selectors';


export default function LeagueTeam({ route }) {
    const { basketixId, weekId } = route.params;
    const dispatch = useDispatch();
    const league = useSelector(state => state.league);
    const weeks = league.season.regularSeason.weeks.filter(week => week.isStarted);
    const basketix = league.members[basketixId];
    const seasonId = league.season.seasonId;
    const teamName = basketix.teamName
    const basketBallCourtColor = useSelector(selectors.getBasketballCourt(seasonId, basketixId));

    const backgroundColor = basketBallCourtColor.backgroundColor
    const paintColor = basketBallCourtColor.paintColor
    const linesColor = basketBallCourtColor.linesColor
    const threePointsColor = basketBallCourtColor.threePointsColor
    const courtColor = basketBallCourtColor.courtColor
    const sidePaintColor = basketBallCourtColor.sidePaintColor
    const linesWidth = basketBallCourtColor.linesWidth

    const [teamPlayers, setTeamPlayers] = useState({
        PG: { id: 'PG', name: strings('globals.PG'), player: {}, evaluation: 0, games: [] },
        SG: { id: 'SG', name: strings('globals.SG'), player: {}, evaluation: 0, games: [] },
        SF: { id: 'SF', name: strings('globals.SF'), player: {}, evaluation: 0, games: [] },
        PF: { id: 'PF', name: strings('globals.PF'), player: {}, evaluation: 0, games: [] },
        C:  { id: 'C' , name: strings('globals.C') , player: {}, evaluation: 0, games: [] },
    });
    const [isTeamLoading, setIsTeamLoading] = useState(false);
    const [weekEvaluation, setWeekEvaluation] = useState(undefined);
    const [rank, setRank] = useState(undefined);
    const [points, setPoints] = useState(undefined);
    const [displayedPlayer, setDisplayedPlayer] = useState(undefined);
    const [selectedWeek, setSelectedWeek] = useState(league.season.regularSeason.weeks.filter(week => week.weekId === weekId)[0]);


    useEffect(() => {
        setIsTeamLoading(true);
        const getTeamBody = {
            basketixId: basketixId,
            weekId: selectedWeek.weekId,
            seasonId: seasonId,
        };
        SeasonAPI.getTeam(getTeamBody).then( async (data) => {
            if (!data.error) {
                const players = data.players;
                const starters = data.starters;

                let newTeamPlayers = {...teamPlayers};

                newTeamPlayers.PG.player = starters.PG ? players[starters.PG] : {};
                newTeamPlayers.SG.player = starters.SG ? players[starters.SG] : {};
                newTeamPlayers.SF.player = starters.SF ? players[starters.SF] : {};
                newTeamPlayers.PF.player = starters.PF ? players[starters.PF] : {};
                newTeamPlayers.C.player = starters.C ? players[starters.C] : {};

                newTeamPlayers.PG.evaluation = starters.PG ? players[starters.PG].evaluation : 0;
                newTeamPlayers.SG.evaluation = starters.SG ? players[starters.SG].evaluation : 0;
                newTeamPlayers.SF.evaluation = starters.SF ? players[starters.SF].evaluation : 0;
                newTeamPlayers.PF.evaluation = starters.PF ? players[starters.PF].evaluation : 0;
                newTeamPlayers.C.evaluation = starters.C ? players[starters.C].evaluation : 0;

                newTeamPlayers.PG.games = starters.PG ? players[starters.PG].games : [];
                newTeamPlayers.SG.games = starters.SG ? players[starters.SG].games : [];
                newTeamPlayers.SF.games = starters.SF ? players[starters.SF].games : [];
                newTeamPlayers.PF.games = starters.PF ? players[starters.PF].games : [];
                newTeamPlayers.C.games = starters.C ? players[starters.C].games : [];

                setTeamPlayers(newTeamPlayers);
                setWeekEvaluation(data.evaluation);
                setRank(data.rank);
                setPoints(data.points);
                setIsTeamLoading(false);
            } else {
                console.log(data.error);
                setIsTeamLoading(false);
            }
        }).catch((error) => {
            console.log(error);
            setIsTeamLoading(false);
        });

    }, [selectedWeek]);

    useEffect(() => {
        SeasonAPI.getCourt({basketixId, seasonId}).then(data => {
            if (!data.error) {
                dispatch({ type: actions.SET_BASKETBALL_COURT_COLOR, value: {...data, basketixId, seasonId} });
            } else {
                console.error(data.error);
            }
        }).catch(err => {
            console.error(err);
        })
    }, [])

    const displayWeekContext = () => {
        return (
            <View style={[styles.weekContext]}>
                <View style={styles.weekDates}>
                    <Title5>{
                        strings('leagueTeam.weekDates', {
                            date1: toLocaleDate(selectedWeek.dates[0]),
                            date2: toLocaleDate(selectedWeek.dates[selectedWeek.dates.length-1])
                        })
                    }</Title5>
                </View>
                <View style={styles.weekLiveContainer}>
                    <Live isActive={ !isTeamLoading && selectedWeek.isLive }/>
                </View>
            </View>
        )
    }

    let kpi = [];
    if (weekEvaluation !== undefined) {
        kpi.push({key: 'evaluation', title: strings('leagueTeam.evaluation'), value: isTeamLoading ? '-' : weekEvaluation});
    }

    if (rank !== undefined) {
        kpi.push({key: 'rank', title: strings('leagueTeam.rank'), value: isTeamLoading ? '-' : rank});

    }

    if (points !== undefined) {
        kpi.push({key: 'points', title: strings('leagueTeam.points'), value: isTeamLoading ? '-' : points});

    }
    if (kpi.length > 0) {
        kpi[kpi.length - 1].isLast = true;
    }
    let kpiJSX = kpi.map(kpi => (<MainKpi title={kpi.title} value={kpi.value} key={kpi.key} isLast={kpi.isLast}/>));

    const playerDetails = (
        <MainContainer>
            <View style={styles.pickerHeader}>
                <Title2>{strings('leagueTeam.playerDetails')}</Title2>
                <View style={styles.pickerClose}>
                    <TouchableOpacity onPress={() => {setDisplayedPlayer(undefined)}}>
                        <AntDesign name='closecircleo' size={16} color={black} />
                    </TouchableOpacity>
                </View>
            </View>
            <PlayerCardExtended
                key={displayedPlayer?.player.playerId}
                name={displayedPlayer?.player.fullName}
                subtitle={displayedPlayer?.player?.team?.fullName}
                positions={[displayedPlayer?.id]}
                meta={`${displayedPlayer?.evaluation} PTS`}
                injury={displayedPlayer?.player.injury}
                open={true}
                hiddenDetails={true}
                details={<PlayerGamesDetails games={displayedPlayer?.games || []}/>}
            />
        </MainContainer>
    );

    const basketballCourt = (
        <BasketballCourt
            teamName={teamName}
            teamNameColor={getOppositeColor(backgroundColor)}
            backgroundColor={backgroundColor}
            courtColor={courtColor}
            paintColor={paintColor}
            threePointsColor={threePointsColor}
            linesColor={linesColor}
            sidePaintColor={sidePaintColor}
            linesWidth= {linesWidth}
        >
            <PointGuard
                name={teamPlayers.PG.player?.fullName}
                games={teamPlayers.PG.games}
                evaluation={teamPlayers.PG.evaluation}
                onPress={!isEmptyObject(teamPlayers.PG.player) ? () => {setDisplayedPlayer(teamPlayers.PG)} : undefined}
                isEmpty={isEmptyObject(teamPlayers.PG.player)}
                isSelectable={false}
                isLoading={isTeamLoading}
            />
            <ShootingGuard
                name={teamPlayers.SG.player?.fullName}
                games={teamPlayers.SG.games}
                evaluation={teamPlayers.SG.evaluation}
                onPress={!isEmptyObject(teamPlayers.SG.player) ? () => {setDisplayedPlayer(teamPlayers.SG)} : undefined}
                isEmpty={isEmptyObject(teamPlayers.SG.player)}
                isSelectable={false}
                isLoading={isTeamLoading}
            />
            <SmallForward
                name={teamPlayers.SF.player?.fullName}
                games={teamPlayers.SF.games}
                evaluation={teamPlayers.SF.evaluation}
                onPress={!isEmptyObject(teamPlayers.SF.player) ? () => {setDisplayedPlayer(teamPlayers.SF)} : undefined}
                isEmpty={isEmptyObject(teamPlayers.SF.player)}
                isSelectable={false}
                isLoading={isTeamLoading}
            />
            <PowerForward
                name={teamPlayers.PF.player?.fullName}
                games={teamPlayers.PF.games}
                evaluation={teamPlayers.PF.evaluation}
                onPress={!isEmptyObject(teamPlayers.PF.player) ? () => {setDisplayedPlayer(teamPlayers.PF)} : undefined}
                isEmpty={isEmptyObject(teamPlayers.PF.player)}
                isSelectable={false}
                isLoading={isTeamLoading}
            />
            <Center
                name={teamPlayers.C.player?.fullName}
                games={teamPlayers.C.games}
                evaluation={teamPlayers.C.evaluation}
                onPress={!isEmptyObject(teamPlayers.C.player) ? () => {setDisplayedPlayer(teamPlayers.C)} : undefined}
                isEmpty={isEmptyObject(teamPlayers.C.player)}
                isSelectable={false}
                isLoading={isTeamLoading}
            />
        </BasketballCourt>
    );

    const displayedMainComponent = displayedPlayer ? playerDetails : basketballCourt;

    return (
        <ScrollViewMainContainer noPadding={true}>
            <MainContainer>
                <View style={[styles.nameContainer]}>
                    <Title1 style={styles.teamNameText}>{teamName}</Title1>
                    <Title3>{basketix.name}</Title3>
                </View>

                <View style={[styles.alignWeek]}>
                    <WeekPicker
                        weeks={weeks}
                        selectedWeek={selectedWeek}
                        onPress={ setSelectedWeek }
                    />
                </View>

                {displayWeekContext()}

                <MainKpiBar style={[styles.mainKpiBar, globalStyles.shadow]}>
                    {kpiJSX}
                </MainKpiBar>
            </MainContainer>
            {displayedMainComponent}
        </ScrollViewMainContainer>
    )
};
const styles = StyleSheet.create({
    alignWeek: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    positionContainer: {
        marginBottom: 20,
    },
    centerText: {
        textAlign: 'center'
    },
    rightText: {
        textAlign: 'right'
    },
    nameContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 4,
        marginBottom: 4,
    },
    teamNameText: {
        fontSize: sizeXXL,
    },
    mainKpiBar: {
        marginVertical: 12,
    },
    weekContext: {
        flex: 1,
        flexDirection: 'row',
    },
    weekDates: {
        flex: 3,
    },
    weekLiveContainer: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    pickerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingVertical: 8,
        marginBottom: 16,
    },
    pickerClose: {
        alignItems: 'center',
        marginLeft: 12,
    },
});
