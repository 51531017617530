import React from 'react';
import { StyleSheet, View } from 'react-native';
import { secondaryColor } from '../Styles/colors';
import { bold } from '../Styles/text';
import { Cell } from './Cell';
import { Column } from './Column';
import { Columns } from './Columns';
import { Legend } from './Legend';
import { NamesCell } from './NamesCell';
import { RankCell } from './RankCell';
import { Row } from './Row';
import { Rows } from './Rows';
import { Table } from './Table';

export function RankingTable({columns, rows, onPress}) {

    const userIdKey = 'userId';
    const userNameKey = 'userName';
    const userTeamNameKey = 'userTeamName';
    const rankKey = 'rank';
    const pointsKey = 'points';

    const commonsColumns = [userIdKey, userNameKey, userTeamNameKey, rankKey, pointsKey];
    const lastWeeksColumns = columns.filter(c => !commonsColumns.includes(c.id)).slice(-3)

    const columnsById = columns.reduce((a, v) => ({ ...a, [v.id]: v}), {});

    const legendIds = [pointsKey];
    const displayedColumns = [
        (<Column size={1}></Column>),
        (<Column size={8}></Column>),
        (<Column size={4}>{columnsById[pointsKey].abbreviation}</Column>),
        ...lastWeeksColumns.map(c => (<Column size={2}>{c.abbreviation}</Column>)),
    ];

    const legendText = legendIds.map(id => (`${columnsById[id].abbreviation} = ${columnsById[id].label}`)).join('\n');

    return (
        <Table>
            <Columns>
                {displayedColumns}
            </Columns>
            <Rows>
                {
                    rows.map((row, index) => {
                        const cells = [
                            (<Cell size={1}>{row[rankKey]}</Cell>),
                            (<Cell size={8} align={'flex-start'}>
                                <NamesCell teamName={row[userTeamNameKey]} userName={row[userNameKey]}/>
                            </Cell>),
                            (<Cell size={4} align={'center'} fontWeight={bold}>{row[pointsKey]}</Cell>),
                            ...lastWeeksColumns.map(c => (<Cell size={2} align={'center'}>{row[c.id]}</Cell>)),
                        ];
                        const isLastRow = index === rows.length - 1;

                        return (<Row key={row[userIdKey]} lastRow={isLastRow}>{cells}</Row>);
                    })
                }
            </Rows>
            <Legend>{legendText}</Legend>
        </Table>
    )
}

const styles = StyleSheet.create({
});
