import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { LeagueAPI } from '../API/League';
import { BtxActivityIndicator } from '../Components/BtxActivityIndicator';
import { ScrollViewMainContainer } from '../Components/ScrollViewMainContainer';
import { strings } from '../locales/i18n';
import { actions } from '../Store/actions';

export default function LeagueLoading({ route, navigation }) {
    const dispatch = useDispatch();
    const leagueId = useSelector(state => state.league.leagueId);
    const leagueIdToLoad = useSelector(state => state.appLoader.leagueIdToLoad);
    const loadingId = useSelector(state => state.appLoader.loadingId);

    useEffect(() => {
        LeagueAPI.getLeague(leagueIdToLoad).then(data => {
            if (! data.error) {
                const action = { type: 'SET_LEAGUE', value: data };
                dispatch(action);

                const updateUserLeagueAction = {
                    type: actions.UPDATE_USER_LEAGUE,
                    value: {
                        leagueId: data.leagueId,
                        league: data,
                    }
                };
                dispatch(updateUserLeagueAction);

                navigation.navigate(strings('navigation.LeagueBottomTabBar'));
            } else {
                console.error(data.error);
                navigation.navigate('HomeStack');
            }
        }).catch(err => {
            console.log(err);
            navigation.navigate('HomeStack');
        });
    }, [loadingId, leagueId]);

    return (
        <ScrollViewMainContainer>
            <View style={styles.container}>
                <BtxActivityIndicator size='large' />
            </View>
        </ScrollViewMainContainer>
    );
};


const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

