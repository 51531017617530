const initialState = {
    freeAgency: {
        data: undefined,
        isLoading: false,
        error: undefined,
    },
    lastMarketActionTime: undefined,
};

export const marketActions = {
    LOAD_FREE_AGENCY: '[Market] Load free agency',
    FREE_AGENCY_SUCCESS: '[Market] Free agency success',
    FREE_AGENCY_ERROR: '[Market] Free agency error',
    NEW_MARKET_ACTION: '[Market] New action',
}

export function market(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case marketActions.LOAD_FREE_AGENCY:
            nextState = {
                ...state,
                freeAgency: {
                    ...state.freeAgency,
                    isLoading: true,
                },
            };
            return nextState || state;
        case marketActions.FREE_AGENCY_SUCCESS:
            nextState = {
                ...state,
                freeAgency: {
                    ...state.freeAgency,
                    data: action.value,
                    isLoading: false,
                    error: undefined,
                },
            };
            return nextState || state;
        case marketActions.FREE_AGENCY_ERROR:
            nextState = {
                ...state,
                freeAgency: {
                    ...state.freeAgency,
                    error: action.value,
                    isLoading: false,
                },
            };
            return nextState || state;
        case marketActions.NEW_MARKET_ACTION:
            nextState = {
                ...state,
                lastMarketActionTime: action.value,
            }
            return nextState || state;
        default:
            return state;
    }
}
