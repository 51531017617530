import { black } from "../../Styles/colors";
import { actions } from "../actions";

const defaultCourtColor = "#e6be91";

const initialState = {
    defaultBasketballCourt: {
        backgroundColor: defaultCourtColor,
        paintColor: defaultCourtColor,
        linesColor: black,
        threePointsColor: defaultCourtColor,
        courtColor: defaultCourtColor,
        sidePaintColor: defaultCourtColor,
        linesWidth: 3,
    },
    data: {}
};

function basketBallCourtColor(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case actions.SET_BASKETBALL_COURT_COLOR:
            nextState = { ...state };
            nextState.data[action.value.seasonId] = {
                ...nextState.data[action.value.seasonId],
                [action.value.basketixId]: {
                    backgroundColor: action.value.backgroundColor,
                    paintColor: action.value.paintColor,
                    linesColor: action.value.linesColor,
                    threePointsColor: action.value.threePointsColor,
                    courtColor: action.value.courtColor,
                    sidePaintColor: action.value.sidePaintColor,
                    linesWidth: action.value.linesWidth,
                    seasonId: action.value.seasonId,
                    basketixId: action.value.basketixId,
                },
            };
            return nextState || state;
        default:
            return state;
    }
}

export default basketBallCourtColor;
